import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import startCase from 'lodash/startCase';

interface HeaderRemoveButtonProps {
  index: number;
  fieldName: string;
  removeIndexField: (index: number, fieldName: string) => void;
}

const HeaderRemoveButton = ({
  index,
  fieldName,
  removeIndexField,
}: HeaderRemoveButtonProps): JSX.Element => {
  return (
    <>
      {index >= 1 && (
        <div className="Header__field--indexOnly">
          <h5>
            {startCase(fieldName)} #{index + 1}
          </h5>
          <span
            data-id={`trash-btn-${fieldName}s`}
            style={{ cursor: 'pointer' }}
            role="button"
            tabIndex={0}
            onKeyDown={() => removeIndexField(index, `${fieldName}s`)}
            onClick={() => {
              removeIndexField(index, `${fieldName}s`);
            }}
          >
            <FontAwesomeIcon icon={['far', 'trash']} /> <p>Remove</p>
          </span>
        </div>
      )}
    </>
  );
};

export default HeaderRemoveButton;
