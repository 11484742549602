/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

const yesNoOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

export const FormConfig: Record<string, any> = {
  1: {
    fullName: {
      type: 'text',
      label: 'Full Name',
      validations: Yup.string()
        .min(2, 'Full Name must be at least 2 characters')
        .required('Required'),
    },
    citizenship: {
      type: 'text',
      label: 'Citizenship',
      isGroupStart: true,
    },
    occupation: {
      type: 'text',
      label: 'Occupation',
      isGroupEnd: true,
    },
    address: {
      type: 'text',
      label: 'Address',
      isGroupStart: true,
    },
    province: {
      type: 'dropdown',
      label: 'Province',
      isGroupEnd: true,
      options: 'regionOptions',
      defaultValue: 'BC',
    },
    country: {
      type: 'dropdown',
      label: 'Country',
      defaultValue: 'CA',
      isGroupStart: true,
      options: 'countryOptions',
    },
    postal: {
      type: 'text',
      label: 'Postal code/zipcode',
      isGroupEnd: true,
    },
    phone: {
      type: 'phone',
      label: 'Phone number',
      required: true,
      isGroupStart: true,
    },
    email: {
      type: 'email',
      label: 'Email address',
      required: true,
      isGroupEnd: true,
    },
    dateOfBirth: {
      type: 'date',
      label: 'Date of birth',
      isGroupStart: true,
    },
    placeOfBirth: {
      type: 'text',
      label: 'Place of birth',
      isGroupEnd: true,
    },
    existingWill: {
      type: 'radio',
      label: 'Do you have an existing will?',
      options: yesNoOptions,
    },
    willDate: {
      type: 'date',
      label: 'Date of will',
    },
    marriageContract: {
      type: 'radio',
      label: 'Do you have a marriage contract?',
      options: yesNoOptions,
      isGroupStart: true,
    },
    marriedBefore: {
      type: 'radio',
      label: 'Have you been married before?',
      options: yesNoOptions,
      isGroupEnd: true,
    },

    // test: {
    //   type: 'dynamic',
    //   options: [
    //     {
    //       value: 'name',
    //       label: 'Test label',
    //       placeholder: 'Placeholder.',
    //     },
    //   ],
    //   buttonLabel: 'Add another',
    // },
  },

  2: {
    spouseFullName: {
      type: 'text',
      label: 'Full Name',
      validations: Yup.string()
        .min(2, 'Full Name must be at least 2 characters')
        .required('Required'),
    },
    spouseCitizenship: {
      type: 'text',
      label: 'Citizenship',
      isGroupStart: true,
    },
    spouseOccupation: {
      type: 'text',
      label: 'Occupation',
      isGroupEnd: true,
    },
    spousePhone: {
      type: 'phone',
      label: 'Phone number',
      required: true,
      isGroupStart: true,
    },
    spouseDateOfBirth: {
      type: 'date',
      label: 'Date of birth',
      isGroupEnd: true,
    },
    spousePlaceOfBirth: {
      type: 'text',
      label: 'Place of birth',
    },

    spouseExistingWill: {
      type: 'radio',
      label: 'Does your spouse have an existing will?',
      options: yesNoOptions,
    },
    spouseMarriedBefore: {
      type: 'radio',
      label: 'Has your spouse been married before?',
      options: yesNoOptions,
    },
    spouseCohabiting: {
      type: 'radio',
      label: 'Have you recently ceased cohabiting with someone?',
      options: yesNoOptions,
    },

    spouseCohabitingLength: {
      type: 'text',
      label: 'Length of cohabitation (If common-law spouse or partner)',
    },

    spouseMarriageContract: {
      type: 'radio',
      label:
        'Have you signed a marriage contract, separation agreement or other agreement which deals with maintenance or ownership of assets on death or separation?',
      options: yesNoOptions,
    },
  },
};

export const keysFormMigration = [
  'titleSearch',
  'taxSearch',
  'plan',
  'payoutOne',
  'payoutTwo',
  'mortgagePayoutOne',
  'fireInsurance',
  'strataForms',
  'titleInsurance',
  'vendorDocsSent',
  'vendorDocsReceived',
  'vendorDocs',
  'requestForFunds',
  'excessDeposit',
  'fiveDayReport',
  'finalReport',
  'releaseParticulars',
  'stc',
  'other',
  'hasLawyerOrNotary',
  'hasRealtor',
];

export const purchaseInternalNotesKeys = [
  'titleSearch',
  'taxSearch',
  'plan',
  'payoutOne',
  'payoutTwo',
  'fireInsurance',
  'strataForms',
  'titleInsurance',
  'vendorDocsSent',
  'vendorDocsReceived',
  'requestForFunds',
  'excessDeposit',
  'excessDepositDateReceived',
  'excessDepositAmount',
  'fiveDayReport',
  'finalReport',
  'stc',
  'other',
  'otherText',
  'names',
  'possessionDate',
  'adjustmentDate',
  'hasLawyerOrNotary',
  'lawyerCompany',
  'lawyerContactName',
  'lawyerPhone',
  'lawyerEmail',
  'hasRealtor',
  'realtorOfficeName',
  'realtorOfficePhone',
  'realtorOfficeEmail',
  'realtorOfficeFaxPhone',
  'notesRealtorName',
  'notesRealtorPhone',
  'notesRealtorEmail',
  'strataMaintenanceFee',
  'outstandingFees',
  'moveInOutFees',
  'transactionUndertakings',
  'transactionUndertakingsDetails',
  'holdbacks',
  'holdbacksDetails',
  'internalNotes',
];

export const saleInternalNotesKeys = [
  'titleSearch',
  'payoutOne',
  'payoutTwo',
  'vendorDocsSent',
  'vendorDocsReceived',
  'fiveDayReport',
  'finalReport',
  'releaseParticulars',
  'other',
  'otherText',
  'names',
  'possessionDate',
  'adjustmentDate',
  'hasLawyerOrNotary',
  'lawyerCompany',
  'lawyerContactName',
  'lawyerPhone',
  'lawyerEmail',
  'hasRealtor',
  'realtorOfficeName',
  'realtorOfficePhone',
  'realtorOfficeEmail',
  'realtorOfficeFaxPhone',
  'notesRealtorName',
  'notesRealtorPhone',
  'notesRealtorEmail',
  'internalNotes',
];

export const refinanceInternalNotesKeys = [
  'titleSearch',
  'taxSearch',
  'payoutOne',
  'payoutTwo',
  'fireInsurance',
  'strataForms',
  'titleInsurance',
  'requestForFunds',
  'finalReport',
  'releaseParticulars',
  'stc',
  'other',
  'otherText',
  'strataMaintenanceFee',
  'outstandingFees',
  'moveInOutFees',
  'transactionUndertakings',
  'transactionUndertakingsDetails',
  'holdbacks',
  'holdbacksDetails',
  'internalNotes',
];

export const transferInternalNotesKeys = [
  'titleSearch',
  'taxSearch',
  'payoutOne',
  'payoutTwo',
  'fireInsurance',
  'strataForms',
  'titleInsurance',
  'requestForFunds',
  'finalReport',
  'releaseParticulars',
  'stc',
  'other',
  'otherText',
  'strataMaintenanceFee',
  'outstandingFees',
  'moveInOutFees',
  'transactionUndertakings',
  'transactionUndertakingsDetails',
  'holdbacks',
  'holdbacksDetails',
  'internalNotes',
];

export const formFileTypes: Record<string, string> = {
  sale: 'Sale Transaction',
  refinance: 'Refinance Transaction',
  purchase: 'Purchase Transaction',
  transfer: 'Family Transfer Transaction',
};

export const houseTypeOptions = [
  { key: 'house', text: 'House', value: 'house' },
  { key: 'condoTownhouse', text: 'Condo / Townhouse', value: 'condoTownhouse' },
  { key: 'leasehold', text: 'Leasehold', value: 'leasehold' },
  { key: 'manufactured-home', text: 'Manufactured home', value: 'manufactured-home' },
  { key: 'vacant-land', text: 'Vacant Land', value: 'vacant-land' },
];
