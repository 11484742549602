/* eslint-disable import/no-cycle */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/rootReducer';

import { getStaff, postStaff, patchStaff } from '../../api/ReadyWhenAdminAPI';
import { StaffAddFormPayload, StaffEditFormPayload, Staff } from '../../api/interfaces';
import { ErrorType } from '../../app/interfaces';

const staffAdapter = createEntityAdapter<Staff>();

export const fetchStaff = createAsyncThunk('staff/fetchStaff', async (customerId: string) => {
  const response = await getStaff(customerId);
  return response;
});

const staffSlice = createSlice({
  name: 'staff',
  initialState: staffAdapter.getInitialState({ loading: false, error: false }),
  reducers: {
    staffStarted(state) {
      state.loading = true;
    },
    staffFailure(state) {
      state.error = true;
      state.loading = false;
    },
    staffAdded(state, action) {
      staffAdapter.addOne(state, action.payload);
      state.loading = false;
    },
    staffUpdateOne(state, { payload }) {
      staffAdapter.upsertOne(state, payload);
      state.loading = false;
    },
    staffSuccess(state, action) {
      staffAdapter.setAll(state, action.payload);
      state.loading = false;
    },
  },
  extraReducers: {
    [fetchStaff.pending as unknown as string]: (state) => {
      staffSlice.caseReducers.staffStarted(state);
    },
    [fetchStaff.fulfilled as unknown as string]: (state, action) => {
      staffSlice.caseReducers.staffSuccess(state, action);
    },
    [fetchStaff.rejected as unknown as string]: (state) => {
      staffSlice.caseReducers.staffFailure(state);
    },
  },
});

export const { staffStarted, staffFailure, staffAdded, staffUpdateOne, staffSuccess } =
  staffSlice.actions;

export const addStaff = createAsyncThunk(
  'staff/addedOne',
  async (formData: StaffAddFormPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      dispatch(staffStarted());
      const response = await postStaff(formData);
      dispatch(staffAdded(response));
      return response;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(staffFailure());
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const editStaff = createAsyncThunk(
  'staff/editOne',
  async (formData: StaffEditFormPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { id } = formData;
    try {
      dispatch(staffStarted());

      const staff = await patchStaff(id, formData);
      dispatch(staffUpdateOne(staff));
      return staff;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(staffFailure());
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const staffSelectors = staffAdapter.getSelectors<RootState>((state) => state.staff);

export default staffSlice.reducer;
