/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import includes from 'lodash/includes';
import { AppThunk } from '../../../app/store';
import { RootState } from '../../../app/rootReducer';
import { getForms, patchForm, processErrorMessage, getFormById } from '../../../api/ReadyWhenAdminAPI';
import { ErrorType } from '../../../app/interfaces';
import { keysFormMigration } from '../../../constants/forms';
import { Form, PatchFormPayload } from '../../../api/interfaces';

const formsAdapter = createEntityAdapter<Form>();

export const formsSlice = createSlice({
  name: 'forms',
  initialState: formsAdapter.getInitialState({
    loading: false,
    error: false,
  }),
  reducers: {
    addOneForm(state, { payload }) {
      formsAdapter.addOne(state, payload);
      state.loading = false;
      state.error = false;
    },
    updateOneForm(state, { payload }) {
      formsAdapter.upsertOne(state, payload);
      state.loading = false;
      state.error = false;
    },
    getFormsStart(state) {
      state.loading = true;
    },
    getFormsSuccess(state, { payload }) {
      formsAdapter.setAll(state, payload);
      state.loading = false;
      state.error = false;
    },
    getFormsFailure(state, { payload }) {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { addOneForm, updateOneForm, getFormsStart, getFormsSuccess, getFormsFailure } =
  formsSlice.actions;

export const fetchForms =
  (customerId: string, clientId: string, intakeFormId?: string): AppThunk =>
  async (dispatch) => {
    dispatch(getFormsStart());
    try {
      const forms: Form[] = intakeFormId
        ? await getFormById(customerId, intakeFormId)
        : await getForms(customerId, clientId);

      // migration old forms data that had checkboxes instead of radio buttons
      forms.forEach((form) => {
        if (form.metadata.version === undefined) {
          const { metadata } = form;
          for (const [key, value] of Object.entries(metadata)) {
            if (typeof value === 'boolean' && !includes(keysFormMigration, key)) {
              if (value) {
                form.metadata[key] = '1';
              } else {
                form.metadata[key] = '0';
              }
              form.metadata.version = '2.0';
            }
          }
        }
      });
      dispatch(getFormsSuccess(forms));
    } catch (err) {
      const error = err as ErrorType;
      dispatch(getFormsFailure(processErrorMessage(error)));
    }
  };

export const updateForm = createAsyncThunk(
  'forms/create',
  async (data: PatchFormPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
      dispatch(getFormsStart());
      const form = await patchForm(data);
      dispatch(addOneForm(form));
      return form;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(getFormsFailure(error.response.data.message));
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const formsSelectors = formsAdapter.getSelectors((state: RootState) => state.forms);

export default formsSlice.reducer;
