import { CheckboxProps, Divider, FormGroup } from 'semantic-ui-react';
// eslint-disable-next-line import/no-cycle
import {
  CheckboxInput,
  DateInput,
  EmailInput,
  PhoneInput,
  RadioGroupInput,
  TextAreaInput,
  TextInput,
} from 'readywhen-ui-components';

interface InternalNotesProps {
  formDisabled: boolean;
  formData: Record<string, unknown>;
  handleRadioCheckboxChange: (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => void;
}

export const InternalNotesSale = ({
  formDisabled,
  formData,
  handleRadioCheckboxChange,
}: InternalNotesProps): JSX.Element => {
  return (
    <div className="Form_internalNotes">
      <h3>Internal Notes</h3>
      <h4>Checklist</h4>
      <Divider />
      <CheckboxInput
        name="titleSearch"
        label="Title Search"
        checked={formData.titleSearch}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="payoutOne"
        label="Payout #1"
        checked={formData.payoutOne}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="payoutTwo"
        label="Payout #2"
        checked={formData.payoutTwo}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="vendorDocsReceived"
        label="Vendor Documents Received"
        checked={formData.vendorDocsReceived}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="vendorDocsSent"
        label="Vendor Documents Sent"
        checked={formData.vendorDocsSent}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="fiveDayReport"
        label="5 Day Report Received"
        checked={formData.fiveDayReport}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="finalReport"
        label="Final Report"
        checked={formData.finalReport}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="releaseParticulars"
        label="Release Particulars"
        checked={formData.releaseParticulars}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="other"
        label="Other"
        checked={formData.other}
        onChange={handleRadioCheckboxChange}
      />
      {formData.other && <TextInput name="otherText" label="" disabled={formDisabled} />}
      <Divider />
      <TextAreaInput name="names" label="Name of Buyer(s)" disabled={formDisabled} />
      <FormGroup widths="equal">
        <DateInput name="possessionDate" label="Possession Date" disabled={formDisabled} />
        <DateInput name="adjustmentDate" label="Adjustment Date" disabled={formDisabled} />
      </FormGroup>
      <Divider />
      <RadioGroupInput
        name="hasLawyerOrNotary"
        label="Buyer's Lawyer / Notary"
        value={formData.hasLawyerOrNotary}
        onChange={handleRadioCheckboxChange}
      />
      {formData.hasLawyerOrNotary === '1' && (
        <>
          <FormGroup widths="equal">
            <TextInput name="lawyerCompany" label="Name of Company" disabled={formDisabled} />
            <TextInput name="lawyerContactName" label="Contact Name" disabled={formDisabled} />
          </FormGroup>
          <FormGroup widths="equal">
            <PhoneInput name="lawyerPhone" label="Phone Number" disabled={formDisabled} />
            <EmailInput name="lawyerEmail" label="Email Address" disabled={formDisabled} />
          </FormGroup>
        </>
      )}
      <Divider />
      <RadioGroupInput
        name="hasRealtor"
        label="Buyer's Realtor"
        value={formData.hasRealtor}
        onChange={handleRadioCheckboxChange}
      />
      {formData.hasRealtor === '1' && (
        <>
          <TextInput
            name="realtorOfficeName"
            label="Name of Real Estate Office"
            disabled={formDisabled}
          />
          <FormGroup widths="equal">
            <PhoneInput
              name="realtorOfficePhone"
              label="Office Phone Number"
              disabled={formDisabled}
            />
            <EmailInput name="realtorOfficeEmail" label="Office Email" disabled={formDisabled} />
          </FormGroup>
          <FormGroup widths="equal">
            <PhoneInput
              name="realtorOfficeFaxPhone"
              label="Office Fax Number"
              disabled={formDisabled}
            />
            <TextInput name="notesRealtorName" label="Name of Realtor" disabled={formDisabled} />
          </FormGroup>
          <FormGroup widths="equal">
            <PhoneInput
              name="notesRealtorPhone"
              label="Realtor's Phone Number"
              disabled={formDisabled}
            />
            <EmailInput
              name="notesRealtorEmail"
              label="Realtor's Email Address"
              disabled={formDisabled}
            />
          </FormGroup>
        </>
      )}
      <Divider />
      <TextAreaInput
        rows={5}
        name="internalNotes"
        label="Additional Notes"
        disabled={formDisabled}
      />
    </div>
  );
};

export default InternalNotesSale;
