import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { FormGroup, Modal, Transition, Divider } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { clientsSelectors, updateOneClient } from '../clientsSlice';
import { selectCustomers } from '../../customers/customersSlice';
import { AppDispatch } from '../../../app/store';
import { ClientIdProps } from '../interfaces';

export default function EditClientModal({
  clientId,
  modalOpenProp,
  closeModalCallback,
}: ClientIdProps): JSX.Element {
  const [formDisabled, setFormDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState<number>(modalOpenProp);
  const dispatch: AppDispatch = useDispatch();
  const client = useSelector((state) => clientsSelectors.selectById(state, clientId));
  const { currentCustomerId } = useSelector(selectCustomers);

  const initialValues = {
    firstName: client ? client.firstName : '',
    lastName: client ? client.lastName : '',
    email: client ? client.email : '',
    phoneNumber: client ? client.phoneNumber : '',
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    phoneNumber: Yup.string().required('Required'),
  });
  const closeModal = () => {
    setFormDisabled(false);
    if (closeModalCallback) closeModalCallback();
  };

  function formErrorMessage(payload: string) {
    toast({
      type: 'error',
      icon: 'exclamation circle',
      title: 'Client Edit Error',
      description: payload,
      animation: 'drop',
      time: 10000,
    });
  }

  function formSuccessMessage(payload: string) {
    toast({
      type: 'success',
      icon: 'check circle',
      title: 'Success',
      description: payload,
      animation: 'drop',
      time: 10000,
    });
  }

  const handleSubmit = async (values: typeof initialValues) => {
    setFormDisabled(true);

    if (currentCustomerId && clientId) {
      const resultAction = await dispatch(
        updateOneClient({
          customerId: currentCustomerId,
          clientId,
          payload: values,
        })
      );

      if (updateOneClient.fulfilled.match(resultAction)) {
        closeModal();
        formSuccessMessage('Client Details Updated');
      }
      if (updateOneClient.rejected.match(resultAction)) {
        formErrorMessage(resultAction.payload as string);
        setFormDisabled(false);
      }
    }
  };

  return (
    <Transition visible={modalOpen === 1} animation="scale" duration={400}>
      <Modal
        closeIcon
        open={modalOpen === 1}
        onClose={() => closeModal()}
        onOpen={() => setModalOpen(1)}
        style={{ margin: '-50px 0 20px 30px' }}
      >
        <Modal.Content>
          <Modal.Description>
            <h1>Edit Client Information</h1>
            <Divider />
            <div id="Form_container">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={handleSubmit}
              >
                <Form size="large" noValidate>
                  <FormGroup widths="equal">
                    <Input
                      name="firstName"
                      label="First Name *"
                      disabled={formDisabled}
                      errorPrompt
                    />
                    <Input
                      name="lastName"
                      label="Last Name *"
                      disabled={formDisabled}
                      errorPrompt
                    />
                  </FormGroup>

                  <FormGroup widths="equal">
                    <Input
                      name="email"
                      label="Email *"
                      type="email"
                      disabled={clientId ? true : formDisabled}
                      errorPrompt
                    />
                    <Input
                      name="phoneNumber"
                      label="Phone Number *"
                      type="text"
                      disabled={clientId ? true : formDisabled}
                      errorPrompt
                    />
                  </FormGroup>
                  <SubmitButton
                    primary
                    className="Button__Add___centered"
                    style={{ margin: '30px 0 -50px' }}
                  >
                    Save
                  </SubmitButton>
                </Form>
              </Formik>
            </div>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </Transition>
  );
}

EditClientModal.defaultProps = {
  closeModalCallback: null,
};
