import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Grid } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReadyAdminTable } from 'readywhen-ui-components';
import { RootState } from '../../app/rootReducer';
import ReadyAdminSideNav from '../../components/ReadyAdminSideNav';
import ReadyAdminTopNav from '../../components/ReadyAdminTopNav';
import AddStaffPage from './AddStaffPage';
import { staffSelectors, fetchStaff } from './staffSlice';
import { selectCustomers } from '../customers/customersSlice';
import ReadyAdminEmptyPage from '../../components/ReadyAdminEmptyPage';
import { Client } from '../../api/interfaces';
import { AppDispatch } from '../../app/store';

const StaffPage = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const staff = useSelector(staffSelectors.selectAll);
  const { currentCustomerId } = useSelector(selectCustomers);
  const { loading } = useSelector((state: RootState) => state.staff);
  const [staffId, setStaffId] = useState<string | null>(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [addStaffModalOpen, setAddStaffModalOpen] = useState(false);

  const closeModal = () => {
    setStaffId(null);
    setAddStaffModalOpen(false);
  };

  useEffect(() => {
    if (currentCustomerId) {
      dispatch(fetchStaff(currentCustomerId));
      setDataFetched(true);
    }
  }, [currentCustomerId, dispatch]);

  const columns = [
    { field: 'id', headerName: 'Name', type: 'staff', sorted: 'ascending' },
    { field: 'email', headerName: 'Email', sorted: 'ascending' },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      children: (rowData: Client) => {
        const { id } = rowData;
        return (
          <FontAwesomeIcon
            id={id}
            style={{ cursor: 'pointer', marginLeft: '10px' }}
            icon={['far', 'edit']}
            onClick={(event) => {
              if (event.currentTarget.id) {
                setStaffId(event.currentTarget.id as string);
                setAddStaffModalOpen(true);
              }
            }}
          />
        );
      },
    },
  ];

  const AddStaffButton = (): JSX.Element => {
    return (
      <Button
        primary
        className={staff.length ? 'Button__Add___right' : 'Button__Add___centered'}
        onClick={() => setAddStaffModalOpen(true)}
      >
        Add Staff
      </Button>
    );
  };

  return (
    <>
      <ReadyAdminTopNav />
      <Grid divided>
        <Grid.Column width={3}>
          <ReadyAdminSideNav />
        </Grid.Column>

        <Grid.Column width={13} className="Page_background">
          <div className="Page_container">
            <Container style={{ marginTop: '20px' }}>
              {staff.length || loading ? (
                <>
                  <Grid>
                    <Grid.Column width={10}>
                      <h1>Your Staff</h1>
                      <p>Add staff to be part of your client’s profile.</p>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <AddStaffButton />
                    </Grid.Column>
                  </Grid>

                  <ReadyAdminTable
                    loading={loading}
                    columns={columns}
                    rows={staff}
                    pageName="Staff"
                  />
                </>
              ) : (
                <ReadyAdminEmptyPage
                  dataFetched={dataFetched}
                  icon={['far', 'briefcase']}
                  content="You don’t have any staff listed"
                  button={<AddStaffButton />}
                />
              )}
              {addStaffModalOpen ? (
                <AddStaffPage
                  id={staffId as string}
                  closeModalCallback={closeModal}
                  addStaffModalOpen={addStaffModalOpen}
                />
              ) : (
                <></>
              )}
            </Container>
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default StaffPage;
