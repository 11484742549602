import React, { useState, FormEvent } from 'react';
import { FormField, FormGroup, CheckboxProps } from 'semantic-ui-react';
import { Input, Radio } from 'formik-semantic-ui-react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { DateInput, IntakeFormTypeDropdown } from 'readywhen-ui-components';
import { Client } from '../../../api/interfaces';
import { clientsSelectors } from '../clientsSlice';
import { selectCustomers } from '../../customers/customersSlice';
import { IntakeFormFieldsProps } from '../interfaces';

const IntakeFormFields = ({ formDisabled, index, formType, isEdit }: IntakeFormFieldsProps) => {
  const { clientId } = useParams();
  const [intakeInputsDisplayed, setIntakeInputsDisplayed] = useState(false);
  const [isLinkIntakeDisplayed, setLinkIntakeDisplayed] = useState(true);
  const client = useSelector((state) => clientsSelectors.selectById(state, clientId as string));
  const { accountType } = (client as Client) ?? {};
  const { customersById, currentCustomerId } = useSelector(selectCustomers);
  const { values, setFieldValue, setErrors, errors } = useFormikContext() ?? {};

  const handleChangeFormLink = (e: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    setLinkIntakeDisplayed(!!data?.value);
    setErrors({
      ...errors,
      [`fileNumber-${index}`]: undefined,
      [`formType-${index}`]: undefined,
      [`completionDate-${index}`]: undefined,
    });
  };

  const handleChangeIntakeForm = (e: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    if (formType === 'property') {
      setIntakeInputsDisplayed(!!data?.value);
      setLinkIntakeDisplayed(!!data?.value);
      setFieldValue(`isLinkIntakeForm-${index}`, data?.value);

      setErrors({
        ...errors,
        [`fileNumber-${index}`]: undefined,
        [`formType-${index}`]: undefined,
        [`completionDate-${index}`]: undefined,
      });
    }

    if (formType === 'will') {
      const radioValue = data?.value;
      if ((values as Record<string, string>).isIntakeFormSent !== radioValue)
        setFieldValue(`isIntakeFormSent-${index}`, radioValue);
    }
  };

  return accountType === 'complete' ||
    (currentCustomerId && !customersById[currentCustomerId].hasForms) ? null : (
    <>
      <Input
        name={`fileNumber-${index}`}
        label={`File Number${isLinkIntakeDisplayed && intakeInputsDisplayed ? ' *' : ''}`}
        errorPrompt={isLinkIntakeDisplayed && intakeInputsDisplayed}
      />

      {!isEdit && (
        <>
          <FormField>
            <label htmlFor={`isIntakeFormSent-${index}`}>
              <strong>Send an Intake Form</strong>
            </label>
            <FormGroup inline>
              <Radio
                name={`isIntakeFormSent-${index}`}
                label="Yes"
                value={1}
                onChange={handleChangeIntakeForm}
              />
              <Radio
                name={`isIntakeFormSent-${index}`}
                label="No"
                value={0}
                onChange={handleChangeIntakeForm}
              />
            </FormGroup>
          </FormField>

          {intakeInputsDisplayed && (
            <FormField>
              <label htmlFor={`isLinkIntakeForm-${index}`}>
                <strong>Link to an Existing Intake Form</strong>
              </label>
              <FormGroup inline>
                <Radio
                  name={`isLinkIntakeForm-${index}`}
                  label="Yes"
                  value={1}
                  onChange={handleChangeFormLink}
                />
                <Radio
                  name={`isLinkIntakeForm-${index}`}
                  label="No"
                  value={0}
                  onChange={handleChangeFormLink}
                />
              </FormGroup>
            </FormField>
          )}

          {!isLinkIntakeDisplayed && intakeInputsDisplayed ? (
            <IntakeFormTypeDropdown disabled={formDisabled} name={`formType-${index}`} />
          ) : null}

          {intakeInputsDisplayed ? (
            <DateInput
              name={`completionDate-${index}`}
              label="Completion Date *"
              disabled={formDisabled}
              errorPrompt
            />
          ) : null}
        </>
      )}
    </>
  );
};

IntakeFormFields.defaultProps = {
  index: null,
  formType: null,
};

export default IntakeFormFields;
