/* eslint-disable import/no-cycle */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import { Form } from 'formik-semantic-ui-react';
import startCase from 'lodash/startCase';
import { SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-semantic-toasts';
import {
  ClientDeleteFilePayload,
  ClientFilePayload,
  ClientFilesPayload,
  FileInfo,
  HiddenInput,
  PropertyOrWillFileUploadField,
  S3,
  useQuery,
} from 'readywhen-ui-components';
import {
  Breadcrumb,
  Button,
  Container,
  Dimmer,
  Divider,
  Grid,
  Loader,
  Modal,
  Tab,
  Transition,
} from 'semantic-ui-react';
import ReadyAdminTopNav from '../../../components/ReadyAdminTopNav';
import {
  clientsSelectors,
  deleteClientFiles,
  fetchClients,
  fetchPropertyUploads,
  replaceClientFiles,
  selectClients,
  updateOneUpload,
  uploadClientFiles,
} from '../clientsSlice';

import { Client, Document, Form as FormInterface, Staff } from '../../../api/interfaces';
import { AppDispatch } from '../../../app/store';
import ReadyAdminDashboardSideNav from '../../../components/ReadyAdminDashboardSideNav';
import ReadyAdminStaffDropdown from '../../../components/ReadyAdminStaffDropdown';
import config from '../../../configs/config';
import { getObjectDiff } from '../../../utils/objUtils';
import { normalizeFieldName } from '../../../utils/stringUtils';
import { selectCustomers } from '../../customers/customersSlice';
import EditPropertyModalField from '../modals/EditPropertyModalField';
import IntakeMessages from './IntakeMessages';

function formErrorMessage(payload: string) {
  toast({
    type: 'error',
    icon: 'exclamation circle',
    title: 'Error',
    description: payload,
    animation: 'drop',
    time: 10000,
  });
}

function formSuccessMessage(payload: string) {
  toast({
    type: 'success',
    icon: 'check circle',
    title: 'Success',
    description: payload,
    animation: 'drop',
    time: 10000,
  });
}

const { downloadFile } = S3;

const PropertyOrWillEntryPage = (): JSX.Element => {
  const query = useQuery();
  const { clientId = '', id = '' } = useParams();
  const [modalOpen, setModalOpen] = useState<number>(0);
  const [formDisabled, setFormDisabled] = useState(false);
  const { loading, documents, files: clientFiles } = useSelector(selectClients);
  const { currentCustomerId } = useSelector(selectCustomers);

  const client = useSelector((state) => clientsSelectors.selectById(state, clientId as string));
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const formikRef = useRef(null);
  const toFromQuery = query.get('to') || window.sessionStorage.getItem('toFromQuery');

  const [tabIndex, setTabIndex] = useState<number>(toFromQuery === 'message' ? 1 : 0);

  useEffect(() => {
    if (currentCustomerId && clientId && !client) {
      dispatch(fetchClients({ customerId: currentCustomerId }));
    }
  }, [client, clientId, currentCustomerId, dispatch, id]);

  useEffect(() => {
    if (currentCustomerId && clientId && id) {
      dispatch(fetchPropertyUploads({ customerId: currentCustomerId, clientId, uploadId: id }));
    }
  }, [clientId, currentCustomerId, dispatch, id]);

  const displayedProperty: Document | Record<string, unknown> = useMemo(() => {
    return documents && documents.property
      ? documents.property.find((property: Document) => property.id === id) ||
          (documents.property.find((property: Document) => property.assetId === id) as Document)
      : {
          metadata: {
            address: '',
            completionDate: '',
            formType: '',
          },
        };
  }, [documents, id]);

  const displayedWill: Document | Record<string, unknown> =
    documents && documents.will
      ? (documents.will.find((will: Document) => will.id === id) as Document)
      : {
          metadata: {
            willRegistration: '',
            formType: '',
          },
        };

  const {
    type: typeWill,
    threads: threadsWill,
    userId: userIdWill,
  } = (displayedWill as Document) || {};

  const {
    type: typeProperty,
    createdAt,
    updatedAt,
    userId,
    threads: threadsProperty,
    metadata: { address, name, completionDate, formType },
  } = (displayedProperty as Document) || {
    metadata: {
      address: '',
      completionDate: '',
      formType: '',
    },
  };

  const formStatus =
    displayedProperty && (displayedProperty.forms as FormInterface[])?.length
      ? (displayedProperty.forms as FormInterface[])[0]?.status || ''
      : null;

  const fileNumber = useMemo(
    () => (displayedProperty.forms as FormInterface[])?.[0]?.metadata?.fileNumber,
    [displayedProperty]
  );

  const initialValues: Record<string, unknown> = {};

  const init = (): void => {
    if ((displayedProperty as Document) && displayedProperty.id) {
      initialValues[`propertyTitle-0`] = (displayedProperty as Document).metadata?.name;
      initialValues[`address-0`] = (displayedProperty as Document).metadata?.address;
      initialValues[`suite-0`] = (displayedProperty as Document).metadata?.suite;
      initialValues[`city-0`] = (displayedProperty as Document).metadata?.city;
      initialValues[`postcode-0`] = (displayedProperty as Document).metadata?.postcode;
      initialValues[`propertyCountry-0`] = (displayedProperty as Document).metadata?.country;
      initialValues[`propertyRegion-0`] = (displayedProperty as Document).metadata?.region;
      initialValues[`uploadId-0`] = (displayedProperty as Document).id;
      initialValues[`completionDate-0`] = (displayedProperty as Document).metadata?.completionDate;
      if (
        displayedProperty &&
        displayedProperty.staff &&
        ((displayedProperty as Document).staff as Staff[]).length
      ) {
        initialValues['staff-0'] = (displayedProperty.staff as Staff[]).map((s) => s.id);
      }
    } else {
      initialValues.willPhysicalLocation = displayedWill?.physicalLocation;
      initialValues.willRegistration =
        // (displayedWill?.willRegistration as any).will ||
        displayedWill && (displayedWill as Document).metadata?.willRegistration;
      initialValues.willUploadId = displayedWill && displayedWill.id;
      if ((displayedWill as Document).staff?.length)
        initialValues['staff-0'] = (displayedWill?.staff as Staff[]).map((s) => s.id);
    }
  };

  const closeModal = () => {
    setModalOpen(0);
  };

  if (modalOpen) {
    init();
  }

  const processChanges = async (
    changes: string[],
    values: Record<string, unknown>,
    uploadId: string,
    index?: number,
    fileKey?: string
  ) => {
    if (currentCustomerId) {
      let newValues: Record<string, unknown> = {};
      changes.forEach((key) => {
        const newKey = normalizeFieldName(key.split('-')[0]);
        const shouldBeRemoved = newKey.includes('Dirty') || newKey.includes('_ignore_');
        newValues = {
          ...newValues,
          ...{ [newKey]: values[key as keyof typeof values] },
        };

        if (shouldBeRemoved) delete newValues[newKey as keyof typeof newValues];
      });

      const { physicalLocation, staff, ...metaData } = newValues;

      let fileName;
      let contentType;
      let s3Key;

      if (fileKey && clientFiles[fileKey] && clientFiles[fileKey].length) {
        const file = clientFiles[fileKey][0];
        fileName = file.fileName;
        contentType = file.contentType;
        s3Key = file.key;
      }

      const resultAction = await dispatch(
        updateOneUpload({
          customerId: currentCustomerId,
          uploadId,
          payload: {
            fileName,
            contentType,
            s3Key,
            ...(fileKey && (physicalLocation as Record<string, unknown>) && { physicalLocation }),
            ...((staff as Record<string, unknown>) && { staff }),
            metadata: {
              ...metaData,
              ...(!fileKey &&
                (physicalLocation as Record<string, unknown>) && { physicalLocation }),
            },
          },
        })
      );

      if (updateOneUpload.fulfilled.match(resultAction)) {
        const { payload } = resultAction;

        if (payload)
          formSuccessMessage(
            `${startCase(payload.type)} ${
              payload.type === 'property' && payload.metadata.name
                ? `${payload.metadata.name} `
                : ''
            }is updated`
          );
      }
      if (updateOneUpload.rejected.match(resultAction)) {
        formErrorMessage(resultAction.payload as string);
      }
    }
  };

  const handleSubmit = async (values: Record<string, unknown>) => {
    setFormDisabled(true);

    if (currentCustomerId) {
      const changes = getObjectDiff(initialValues, values);

      if (changes.length) {
        const multipleChanges: Record<string, unknown> = {};
        const willChanges: string[] = [];

        changes.forEach((change: string): void => {
          if (displayedProperty && displayedProperty.id) {
            const index = change.split('-')[1] as unknown as number;
            if (index === undefined) return;

            if (multipleChanges[index]) {
              (multipleChanges[index] as string[]).push(change);
            } else {
              multipleChanges[index] = [change];
            }
          } else {
            willChanges.push(change);
          }
        });

        if (willChanges.length) {
          const { willUploadId } = values;
          await processChanges(willChanges, values, willUploadId as string, undefined, 'willFile');
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(multipleChanges)) {
            const uploadId = values[`uploadId-${key}`] as string;
            processChanges(value as unknown as string[], values, uploadId, parseInt(key, 10));
          }
        }
      }
      setFormDisabled(false);
      closeModal();
    }
  };

  const WillDetails = () => {
    return documents && Object.keys(documents).length && !loading ? (
      <>
        <section className="Card">
          <Grid divided="vertically">
            <Grid.Column width={11}>
              <h5 style={{ marginBottom: 10 }}>{startCase(typeWill)} </h5>
              <p style={{ marginBottom: 10 }}>
                {address !== undefined && startCase(name as string)}
              </p>
            </Grid.Column>
            <Grid.Column width={5}>
              <Button
                primary
                onClick={() => {
                  if (displayedWill) navigate(`/clients/${clientId}/${id}/${typeWill}-form`);
                }}
              >
                <FontAwesomeIcon icon={['far', 'file-alt']} /> Open {startCase(typeWill)} Intake
                Form
              </Button>
            </Grid.Column>
            <Grid.Row columns={3}>
              <Grid.Column>
                Created
                <br />
                <Moment style={{ fontWeight: 'bold' }} format="D MMM, YYYY">
                  {createdAt}
                </Moment>
              </Grid.Column>
              <Grid.Column>
                Last Edited
                <br />
                <Moment style={{ fontWeight: 'bold' }} format="D MMM, YYYY">
                  {updatedAt}
                </Moment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </section>

        <section className="Card">
          <Formik
            enableReinitialize
            initialValues={clientFiles}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={() => {}}
          >
            <Form size="large" noValidate>
              <HiddenInput name={`uploadId-${0}`} />
              <PropertyOrWillFileUploadField
                name={`willFiles-${0}`}
                label="Upload documents such as property deed, bill of sale, mortgage statement, or home insurance policy."
                required
                multiple={false}
                type="file"
                accept="application/pdf, image/*"
                uploadId={id}
                userId={userIdWill}
                formId={(displayedWill?.forms as FormInterface[])[0]?.id as string}
                files={clientFiles}
                currentCustomerId={currentCustomerId as string}
                replaceClientFiles={(payload: ClientFilePayload) => {
                  dispatch(replaceClientFiles(payload));
                }}
                downloadFile={(file: FileInfo) => {
                  downloadFile('', config.s3.BUCKET, file?.key || '', '', file?.fileName);
                }}
                deleteClientFiles={(payload: ClientDeleteFilePayload) => {
                  dispatch(deleteClientFiles(payload));
                }}
              />
            </Form>
          </Formik>
        </section>
      </>
    ) : (
      <Dimmer
        active
        inverted
        style={{
          marginTop: '14px',
        }}
      >
        <Loader inverted>Loading</Loader>
      </Dimmer>
    );
  };
  // TODO: move this to a separate file
  const PropertyDetails = () => {
    return documents && Object.keys(documents).length && !loading ? (
      <>
        <section className="Card">
          <Grid divided="vertically">
            <Grid.Column width={11}>
              <h5 style={{ marginBottom: 10 }}>
                {startCase(
                  typeProperty ? (address as string) || (name as string) : (typeWill as string)
                )}{' '}
                <FontAwesomeIcon
                  className="EditIcon_color___teal"
                  icon={['far', 'edit']}
                  onClick={() => {
                    setModalOpen(1);
                  }}
                />
              </h5>
              <p style={{ marginBottom: 10 }}>
                {address !== undefined && startCase(name as string)}
              </p>
              {formStatus && <p className="Status_blue">{startCase(formStatus)}</p>}
              {fileNumber && <div>#{fileNumber as string}</div>}
            </Grid.Column>
            <Grid.Column width={5}>
              <Button
                primary
                onClick={() => {
                  if (displayedProperty || displayedWill)
                    navigate(`/clients/${clientId}/${id}/${formType || typeWill}-form`);
                }}
              >
                <FontAwesomeIcon icon={['far', 'file-alt']} /> Open{' '}
                {startCase((formType as string) || (typeWill as string))} Intake Form
              </Button>
            </Grid.Column>
            <Grid.Row columns={3}>
              <Grid.Column>
                Created
                <br />
                <Moment style={{ fontWeight: 'bold' }} format="D MMM, YYYY">
                  {createdAt}
                </Moment>
              </Grid.Column>
              <Grid.Column>
                Last Edited
                <br />
                <Moment style={{ fontWeight: 'bold' }} format="D MMM, YYYY">
                  {updatedAt}
                </Moment>
              </Grid.Column>
              <Grid.Column>
                {typeProperty && (
                  <>
                    Completion
                    <br />
                    <Moment style={{ fontWeight: 'bold' }} format="D MMM, YYYY">
                      {completionDate as string}
                    </Moment>
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </section>

        <section className="Card">
          <Formik
            enableReinitialize
            initialValues={clientFiles}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={() => {}}
          >
            <Form size="large" noValidate>
              <HiddenInput name={`uploadId-${0}`} />
              <PropertyOrWillFileUploadField
                name={`propertyFiles-${0}`}
                label="Upload documents such as property deed, bill of sale, mortgage statement, or home insurance policy."
                required
                multiple
                type="file"
                accept="application/pdf, image/*"
                uploadId={id}
                userId={userId}
                formId={(displayedProperty?.forms as FormInterface[])?.[0]?.id as string}
                files={clientFiles}
                currentCustomerId={currentCustomerId as string}
                uploadClientFiles={(payload: ClientFilesPayload) => {
                  dispatch(uploadClientFiles(payload));
                }}
                downloadFile={(file: FileInfo) => {
                  downloadFile('', config.s3.BUCKET, file?.key || '', '', file?.fileName);
                }}
                deleteClientFiles={(payload: ClientDeleteFilePayload) => {
                  dispatch(deleteClientFiles(payload));
                }}
              />
            </Form>
          </Formik>
        </section>
      </>
    ) : (
      <Dimmer
        active
        inverted
        style={{
          marginTop: '14px',
        }}
      >
        <Loader inverted>Loading</Loader>
      </Dimmer>
    );
  };

  return (
    <>
      <ReadyAdminTopNav />
      <Grid>
        <Grid.Column width={12}>
          <div className="Page_mainContent___dense">
            <Container>
              <Breadcrumb>
                <Link to="/clients/">
                  <Breadcrumb.Section>Dashboard</Breadcrumb.Section>
                </Link>
                <Breadcrumb.Divider icon="right chevron" />
                <Link to={clientId && `/clients/${clientId}/`}>
                  <Breadcrumb.Section>
                    {client?.firstName} {client?.lastName}
                  </Breadcrumb.Section>
                </Link>
                <Breadcrumb.Divider icon="right chevron" />
                <Breadcrumb.Section>
                  <strong>
                    {startCase((address as string) || (name as string) || (typeWill as string))}
                  </strong>
                </Breadcrumb.Section>
              </Breadcrumb>
              <br />
              <br />
              <Tab
                activeIndex={tabIndex}
                menu={{ secondary: true, pointing: true }}
                panes={[
                  {
                    menuItem: 'Details',
                    pane: {
                      key: 'details',
                      content:
                        displayedProperty && displayedProperty.forms ? (
                          <PropertyDetails />
                        ) : (
                          <WillDetails />
                        ),
                    },
                  },
                  {
                    menuItem: 'Messages',
                    pane: {
                      key: 'messages',
                      content: (
                        <>
                          <IntakeMessages
                            uploadId={id}
                            userId={userId || userIdWill}
                            clientId={clientId}
                            threadId={
                              threadsProperty
                                ? threadsProperty[0]?.id
                                : ([threadsWill ? threadsWill[0].id : ''] as unknown as string)
                            }
                            displayedEntry={
                              displayedProperty?.forms
                                ? (displayedProperty as unknown as Document)
                                : (displayedWill as unknown as Document)
                            }
                            client={client as Client}
                          />
                        </>
                      ),
                    },
                  },
                ]}
                renderActiveOnly={false}
                onTabChange={(e: SyntheticEvent, data: Record<string, unknown>) => {
                  setTabIndex(data.activeIndex as number);
                  if (data.activeIndex === 0 && currentCustomerId && clientId && id) {
                    dispatch(
                      fetchPropertyUploads({
                        customerId: currentCustomerId,
                        clientId,
                        uploadId: id,
                      })
                    );
                  }
                }}
              />
            </Container>
          </div>
        </Grid.Column>
        <Grid.Column
          width={4}
          className="Sidebar Signup"
          style={{ borderLeft: '1px solid #d2d2d2' }}
        >
          <ReadyAdminDashboardSideNav clientId={clientId as string} />
        </Grid.Column>
      </Grid>

      <Transition visible={modalOpen === 1} animation="scale" duration={400}>
        <Modal
          closeIcon
          open={modalOpen === 1}
          onClose={() => closeModal()}
          onOpen={() => setModalOpen(1)}
        >
          <Modal.Content>
            <Modal.Description>
              <Formik<Record<string, unknown>>
                innerRef={formikRef}
                initialValues={initialValues}
                // validationSchema={validationSchema} TODO
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={handleSubmit}
              >
                <Form size="large" noValidate>
                  <Modal.Header style={{ marginBottom: '1em' }}>Edit Property</Modal.Header>
                  <Divider />

                  <EditPropertyModalField formDisabled={formDisabled} index={0} />

                  <ReadyAdminStaffDropdown name="staff-0" disabled={formDisabled} />
                  <Modal.Actions>
                    <Button content="Save" primary />
                  </Modal.Actions>
                </Form>
              </Formik>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </Transition>
    </>
  );
};

export default PropertyOrWillEntryPage;
