/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Container,
  Dimmer,
  Dropdown,
  Grid,
  Loader,
  Modal,
} from 'semantic-ui-react';
import { Form, Select, SubmitButton } from 'formik-semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';
import camelCase from 'lodash/camelCase';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import { formErrorMessage, formSuccessMessage, buildFilesSchema } from './helpers';

import ClientInsuranceField from './fields/ClientInsuranceField';
import ClientPropertyField from './fields/ClientPropertyField';
import ClientSpouseHealthFields from './fields/ClientSpouseHealthFields';
import ClientHealthFields from './fields/ClientHealthFields';
import ClientSpousePoaFields from './fields/ClientSpousePoaFields';
import ClientPoaFields from './fields/ClientPoaFields';
import ClientWillFields from './fields/ClientWillFields';

import { RootState } from '../../app/rootReducer';
import ReadyAdminTopNav from '../../components/ReadyAdminTopNav';
import ReadyAdminDashboardSideNav from '../../components/ReadyAdminDashboardSideNav';

import {
  updateOneClientFile,
  clientsSelectors,
  createOneUpload,
  fetchUploads,
  FileInfo,
  initClientFiles,
  selectClients,
  updateOneUpload,
  fetchClients,
} from './clientsSlice';
import { selectCustomers } from '../customers/customersSlice';
import { postFile } from '../../api/ReadyWhenAdminAPI';
import { Document, Staff } from '../../api/interfaces';
import { getObjectDiff } from '../../utils/objUtils';
import {
  clientFilesByType,
  normalizeFieldName,
  sectionByType,
  normalizeUploadTypes,
} from '../../utils/stringUtils';
import { CardPropertyOrWill, CardNotPropertyOrWill } from './details/DashboardCards';
import ReadyAdminEmptyPage from '../../components/ReadyAdminEmptyPage';
import ReadyAdminStaffDropdown from '../../components/ReadyAdminStaffDropdown';
import { AppDispatch } from '../../app/store';
import ClientSpouseWillFields from './fields/ClientSpouseWillFields';

const ClientEntriesPage = (): JSX.Element => {
  const { clientId = '' } = useParams();
  const formikRef = useRef<FormikProps<Record<string, unknown>>>(null);

  const dispatch: AppDispatch = useDispatch();
  const { loading, documents }: { loading: boolean; documents: Record<string, Document[]> } =
    useSelector((state: RootState) => state.clients);
  const { files: clientFiles, uploadingFiles } = useSelector(selectClients);
  const [beneficiaries, setBeneficiaries] = useState<Record<string, { name: string }[]>>({
    'insurance-0': [{ name: 'firstInsurance' }],
  });
  const [financialBeneficiaries, setFinancialBeneficiaries] = useState<
    Record<string, { name: string }[]>
  >({
    'financialAccount-0': [{ name: 'firstFinancialAccount' }],
  });
  const [jointOwners, setJointOwners] = useState<Record<string, { fullname: string }[]>>({
    'financialAccount-0': [{ fullname: 'firstFinancialAccount' }],
  });
  const [formDisabled, setFormDisabled] = useState(false);
  const [newFormDisabled, setNewFormDisabled] = useState(false);
  const [formType, setFormType] = useState('property');
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [modalType, setModalType] = useState('');
  const [modalId, setModalId] = useState('');
  const client = useSelector((state) => clientsSelectors.selectById(state, clientId as string));
  const { currentCustomerId } = useSelector(selectCustomers);

  useEffect(() => {
    if (currentCustomerId && clientId) {
      dispatch(fetchUploads({ customerId: currentCustomerId, clientId }));
      setDataFetched(true);
    }
  }, [clientId, currentCustomerId, dispatch]);

  useEffect(() => {
    if (currentCustomerId && clientId) {
      if (!client) dispatch(fetchClients({ customerId: currentCustomerId, page: 1, size: 25 }));
    }
  }, [client, clientId, currentCustomerId, dispatch]);

  useEffect(() => {
    if (addModalOpen) {
      dispatch(initClientFiles());
    }
  }, [addModalOpen, dispatch]);

  const changeModalType = useCallback((newModalType: string, id: string) => {
    setModalType(newModalType);
    setModalId(id);
  }, []);

  const initialValues: Record<string, unknown> = {};
  const initialNewValues: Record<string, unknown> = useMemo(() => {
    return {
      type: formType,
    };
  }, [formType]);

  const init = (docs: Record<string, Document[]>): void => {
    if (docs.will || docs['spouse-will']) {
      const will = docs.will ? docs.will[0] : null;
      const spouseWill = docs['spouse-will'] ? docs['spouse-will'][0] : null;

      if (!clientFiles.willFile && will && will.fileName) {
        const { s3Key: key } = will;
        dispatch(
          updateOneClientFile({
            name: 'willFile',
            file: {
              fileName: will.fileName,
              contentType: will.contentType,
              progress: 100,
              cannotDel: true,
              key,
            },
          })
        );
      }
      if (!clientFiles.spouseWillFile && spouseWill && spouseWill.fileName) {
        const { s3Key: key } = spouseWill;
        dispatch(
          updateOneClientFile({
            name: 'spouseWillFile',
            file: {
              fileName: spouseWill.fileName,
              contentType: spouseWill.contentType,
              progress: 100,
              cannotDel: true,
              key,
            },
          })
        );
      }

      if (will) {
        initialValues.willPhysicalLocation = will.physicalLocation;
        initialValues.willUploadId = will.id;
        initialValues.willRegistration = will.metadata?.willRegistration;
        if (will?.staff?.length)
          initialValues['staff-0'] = (will?.staff as Staff[]).map((s) => s.id);
      }

      if (spouseWill) {
        initialValues.spouseWillPhysicalLocation = spouseWill.physicalLocation;
        initialValues.spouseWillRegistration = spouseWill.metadata?.willRegistration;
        initialValues.spouseWillUploadId = spouseWill.id;
        if (spouseWill?.staff?.length)
          initialValues['staff-0'] = (spouseWill?.staff as Staff[]).map((s) => s.id);
      }
    }

    if (docs['power-of-attorney'] || docs['spouse-power-of-attorney']) {
      const poa = docs['power-of-attorney'] ? docs['power-of-attorney'][0] : null;
      const spousePoa = docs['spouse-power-of-attorney']
        ? docs['spouse-power-of-attorney'][0]
        : null;

      if (!clientFiles.poaFile && poa && poa.fileName) {
        const { s3Key: key } = poa;
        dispatch(
          updateOneClientFile({
            name: 'poaFile',
            file: {
              fileName: poa.fileName,
              contentType: poa.contentType,
              progress: 100,
              cannotDel: true,
              key,
            },
          })
        );
      }
      if (!clientFiles.spousePoaFile && spousePoa && spousePoa.fileName) {
        const { s3Key: key } = spousePoa;
        dispatch(
          updateOneClientFile({
            name: 'spousePoaFile',
            file: {
              fileName: spousePoa.fileName,
              contentType: spousePoa.contentType,
              progress: 100,
              cannotDel: true,
              key,
            },
          })
        );
      }

      if (poa) {
        initialValues.poaPhysicalLocation = poa.physicalLocation;
        initialValues.poaUploadId = poa.id;
        if (poa?.staff?.length) initialValues['staff-0'] = (poa?.staff as Staff[]).map((s) => s.id);
      }

      if (spousePoa) {
        initialValues.spousePoaPhysicalLocation = spousePoa.physicalLocation;
        initialValues.spousePoaUploadId = spousePoa.id;
        if (spousePoa?.staff?.length)
          initialValues['staff-0'] = (spousePoa?.staff as Staff[]).map((s) => s.id);
      }
    }

    if (docs['health-directive'] || docs['spouse-health-directive']) {
      const health = docs['health-directive'] ? docs['health-directive'][0] : null;
      const spouseHealth = docs['spouse-health-directive']
        ? docs['spouse-health-directive'][0]
        : null;

      if (!clientFiles.healthFile && health && health.fileName) {
        const { s3Key: key } = health;
        dispatch(
          updateOneClientFile({
            name: 'healthFile',
            file: {
              fileName: health.fileName,
              contentType: health.contentType,
              progress: 100,
              cannotDel: true,
              key,
            },
          })
        );
      }
      if (!clientFiles.spouseHealthFile && spouseHealth && spouseHealth.fileName) {
        const { s3Key: key } = spouseHealth;
        dispatch(
          updateOneClientFile({
            name: 'spouseHealthFile',
            file: {
              fileName: spouseHealth.fileName,
              contentType: spouseHealth.contentType,
              progress: 100,
              cannotDel: true,
              key,
            },
          })
        );
      }

      if (health) {
        initialValues.healthPhysicalLocation = health.physicalLocation;
        initialValues.healthUploadId = health.id;
        if (health?.staff?.length)
          initialValues['staff-0'] = (health?.staff as Staff[]).map((s) => s.id);
      }

      if (spouseHealth) {
        initialValues.spouseHealthPhysicalLocation = spouseHealth.physicalLocation;
        initialValues.spouseHealthUploadId = spouseHealth.id;
        if (spouseHealth?.staff?.length)
          initialValues['staff-0'] = (spouseHealth?.staff as Staff[]).map((s) => s.id);
      }
    }

    if (docs.financial) {
      docs.financial.forEach((d, index) => {
        initialValues[`financialAccountType-${index}`] = d.metadata.accountType;
        initialValues[`branch-${index}`] = d.metadata.branch;
        initialValues[`accountNumber-${index}`] = d.metadata.accountNumber;
        initialValues[`accountBalance-${index}`] = d.metadata.accountBalance;
        initialValues[`contactEmail-${index}`] = d.metadata.contactEmail;
        initialValues[`financialAccountAddress-${index}`] = d.metadata.address;
        initialValues[`financialAccountSuite-${index}`] = d.metadata.suite;
        initialValues[`financialAccountRegion-${index}`] = d.metadata.region;
        initialValues[`financialAccountCity-${index}`] = d.metadata.city;
        initialValues[`financialAccountPostcode-${index}`] = d.metadata.postcode;
        initialValues[`financialAccountNotes-${index}`] = d.metadata.notes;
        initialValues[`financialUploadId-${index}`] = d.id;

        if (typeof d.metadata.contactPhone === 'string') {
          initialValues[`contactPhone-${index}`] = {
            country_code: 'CA',
            value: d.metadata.contactPhone,
          };
          initialValues[`contactPhone-${index}_ignore_country_code`] = 'CA';
          initialValues[`contactPhone-${index}_ignore_phone`] = d.metadata.contactPhone;
        } else if (d.metadata.contactPhone) {
          initialValues[`contactPhone-${index}`] = d.metadata.contactPhone;
          initialValues[`contactPhone-${index}_ignore_country_code`] = (
            d.metadata.contactPhone as Record<string, unknown>
          ).country_code;
          initialValues[`contactPhone-${index}_ignore_phone`] = (
            d.metadata.contactPhone as Record<string, unknown>
          ).value;
        } else {
          initialValues[`contactPhone-${index}`] = {
            country_code: 'CA',
            value: '',
          };
          initialValues[`contactPhone-${index}_ignore_country_code`] = 'CA';
          initialValues[`contactPhone-${index}_ignore_phone`] = '';
        }

        const { shareJointOwnership: metaDataJointOwners } = d.metadata;

        if (
          !jointOwners[`financialAccount-${index}`] ||
          (metaDataJointOwners &&
            !isEqual(jointOwners[`financialAccount-${index}`], metaDataJointOwners) &&
            (metaDataJointOwners as []).length > jointOwners[`financialAccount-${index}`].length)
        ) {
          setJointOwners({
            ...jointOwners,
            [`financialAccount-${index}`]: (metaDataJointOwners as { fullname: string }[]) || [
              { fullname: 'firstFinancialAccount' },
            ],
          });
        }

        if (metaDataJointOwners) {
          (metaDataJointOwners as { fullname: string }[]).forEach(
            (o: { fullname: string }, oIndex: number) => {
              initialValues[`jointOwner-${index}-${oIndex}`] = o.fullname;
            }
          );
        }

        const { financialBeneficiaries: metaDataBeneficiaries } = d.metadata;

        if (
          !financialBeneficiaries[`financialAccount-${index}`] ||
          (metaDataBeneficiaries &&
            !isEqual(financialBeneficiaries[`financialAccount-${index}`], metaDataBeneficiaries) &&
            (metaDataBeneficiaries as []).length >
              financialBeneficiaries[`financialAccount-${index}`].length)
        ) {
          setFinancialBeneficiaries({
            ...financialBeneficiaries,
            [`financialAccount-${index}`]: (metaDataBeneficiaries as { name: string }[]) || [
              { name: 'firstFinancialAccount' },
            ],
          });
        }

        if (metaDataBeneficiaries) {
          (metaDataBeneficiaries as { name: string }[]).forEach(
            (b: { name: string }, bIndex: number) => {
              initialValues[`beneficiary-${index}-${bIndex}`] = b.name;
            }
          );
        }

        if (d?.staff?.length)
          initialValues[`staff-${index}`] = (d?.staff as Staff[]).map((s) => s.id);
      });
    }

    if (docs.property) {
      docs.property.forEach((p, index) => {
        initialValues[`propertyTitle-${index}`] = p.metadata.name;
        initialValues[`address-${index}`] = p.metadata.address;
        initialValues[`suite-${index}`] = p.metadata.suite;
        initialValues[`city-${index}`] = p.metadata.city;
        initialValues[`postcode-${index}`] = p.metadata.postcode;
        initialValues[`propertyCountry-${index}`] = p.metadata.country;
        initialValues[`propertyRegion-${index}`] = p.metadata.region;
        initialValues[`fileNumber-${index}`] = p.metadata.fileNumber;
        initialValues[`isIntakeFormSent-${index}`] = p.metadata.isIntakeFormSent ?? 0;
        initialValues[`uploadId-${index}`] = p.id;
        initialValues[`propertyUploadId-${index}`] = p.id;
        if (p?.staff?.length)
          initialValues[`staff-${index}`] = (p?.staff as Staff[]).map((s) => s.id);
      });
    }

    if (docs.insurance) {
      docs.insurance.forEach((p, index) => {
        initialValues[`insuranceTitle-${index}`] = p.metadata.title;
        initialValues[`insuranceName-${index}`] = p.metadata.name;
        initialValues[`insurancePolicy-${index}`] = p.metadata.policy;
        initialValues[`accountType-${index}`] = p.metadata.accountType;
        initialValues[`policyAmount-${index}`] = p.metadata.policyAmount;
        initialValues[`expirationDate-${index}`] = p.metadata.expirationDate;
        initialValues[`monthlyPayment-${index}`] = p.metadata.monthlyPayment;
        initialValues[`paidFrom-${index}`] = p.metadata.paidFrom;
        initialValues[`agentName-${index}`] = p.metadata.agentName;
        initialValues[`agentEmail-${index}`] = p.metadata.agentEmail;
        initialValues[`staff-${index}`] = (p?.staff as Staff[]).map((s) => s.id);

        if (typeof p.metadata.agentPhone === 'string') {
          initialValues[`agentPhone-${index}`] = {
            country_code: 'CA',
            value: p.metadata.agentPhone,
          };
          initialValues[`agentPhone-${index}_ignore_country_code`] = 'CA';
          initialValues[`agentPhone-${index}_ignore_phone`] = p.metadata.agentPhone;
        } else if (p.metadata.agentPhone) {
          initialValues[`agentPhone-${index}`] = p.metadata.agentPhone;
          initialValues[`agentPhone-${index}_ignore_country_code`] = (
            p.metadata.agentPhone as Record<string, unknown>
          ).country_code;
          initialValues[`agentPhone-${index}_ignore_phone`] = (
            p.metadata.agentPhone as Record<string, unknown>
          ).value;
        } else {
          initialValues[`agentPhone-${index}`] = {
            country_code: 'CA',
            value: '',
          };
          initialValues[`agentPhone-${index}_ignore_country_code`] = 'CA';
          initialValues[`agentPhone-${index}_ignore_phone`] = '';
        }

        initialValues[`lifeInsurancePhysicalLocation-${index}`] = p.metadata.physicalLocation;
        initialValues[`lifeInsuranceUploadId-${index}`] = p.id;

        const { beneficiaries: metaDataBeneficiaries } = p.metadata;

        if (
          !beneficiaries[`insurance-${index}`] ||
          (metaDataBeneficiaries &&
            !isEqual(beneficiaries[`insurance-${index}`], metaDataBeneficiaries) &&
            (metaDataBeneficiaries as []).length > beneficiaries[`insurance-${index}`].length)
        ) {
          setBeneficiaries({
            ...beneficiaries,
            [`insurance-${index}`]: (metaDataBeneficiaries as { name: string }[]) || [
              { name: 'firstInsurance' },
            ],
          });
        }

        if (metaDataBeneficiaries) {
          (metaDataBeneficiaries as { name: string }[]).forEach(
            (b: { name: string }, bIndex: number) => {
              initialValues[`beneficiary-${index}-${bIndex}`] = b.name;
            }
          );
        }
      });
    }
  };

  const editEntryValidationSchema = buildFilesSchema(clientFiles);

  const closeModal = () => {
    setAddModalOpen(false);
    setEditModalOpen(false);
    if (currentCustomerId && clientId) {
      dispatch(fetchUploads({ customerId: currentCustomerId, clientId }));
    }
  };

  useEffect(() => {
    initialNewValues['staff-0' || 'staff'] = '';
    if (
      sectionByType(formType) === 'legal' &&
      !initialNewValues[`${camelCase(formType)}PhysicalLocation`]
    ) {
      initialNewValues[`${camelCase(formType)}PhysicalLocation`] = '';
    }

    if (formType === 'property') {
      initialNewValues['propertyTitle-0'] = '';
      initialNewValues['address-0'] = '';
      initialNewValues['propertyCountry-0'] = 'CA';
      initialNewValues['propertyRegion-0'] = 'BC';

      if (client?.accountType !== 'complete') {
        initialNewValues['isIntakeFormSent-0'] = 0;
        initialNewValues['isLinkIntakeForm-0'] = 0;
      }
      initialNewValues['completionDate-0'] = '';
      initialNewValues['formType-0'] = '';
      initialNewValues['fileNumber-0'] = '';
    }
    if (formType === 'insurance') {
      initialNewValues['insuranceTitle-0'] = '';
      initialNewValues['insuranceName-0'] = '';
      initialNewValues['insurancePolicy-0'] = '';
      initialNewValues['lifeInsurancePhysicalLocation-0'] = '';
    }
  }, [client, formType, initialNewValues]);

  const processChanges = async (
    changes: string[],
    values: Record<string, unknown>,
    uploadId: string,
    index?: number,
    fileKey?: string
  ) => {
    if (currentCustomerId) {
      let newValues: Record<string, unknown> = {};
      const metaValues: Record<string, unknown> = {};
      const { 'staff-0': staff, ...rest } = values;
      changes.forEach((key) => {
        const newKey = normalizeFieldName(key.split('-')[0]);

        const shouldBeRemoved = newKey.includes('Dirty') || newKey.includes('_ignore_');

        newValues = {
          ...newValues,
          ...(includes(['beneficiary'], lowerCase(newKey))
            ? { beneficiaries: beneficiaries[`financialAccount-${index}`] }
            : { [newKey]: rest[key] }),
        };

        if (shouldBeRemoved) delete newValues[newKey];
      });

      const { physicalLocation, ...metaData } = newValues;

      Object.keys(metaData).forEach((r: string) => {
        if (r.split('-')[0] === 'beneficiary') {
          if (metaValues.beneficiaries) {
            (metaValues.beneficiaries as Record<string, unknown>[]).push({
              name: metaData[r],
            });
          } else {
            metaValues.beneficiaries = [{ name: metaData[r] }];
          }
        } else if (r.split('-')[0] === 'jointOwner') {
          if (metaValues.shareJointOwnership) {
            (metaValues.shareJointOwnership as Record<string, unknown>[]).push({
              fullname: metaData[r],
            });
          } else {
            metaValues.shareJointOwnership = [{ fullname: metaData[r] }];
          }
        } else if (r.includes('Notes')) {
          metaValues.notes = metaData[r];
        } else if (r.includes('financialAccount')) {
          if (!r.includes('financialAccountType')) {
            const field = lowerCase(r.split('-')[0].replace('financialAccount', ''));
            metaValues[field] = metaData[r];
          } else {
            metaValues.accountType = metaData[r];
          }
        } else {
          metaValues[normalizeFieldName(r.split('-')[0])] = metaData[r];
        }
      });

      let fileName;
      let contentType;
      let s3Key;

      if (fileKey && clientFiles[fileKey] && clientFiles[fileKey].length) {
        const file = clientFiles[fileKey][0];
        fileName = file.fileName;
        contentType = file.contentType;
        s3Key = file.key;
      }

      const resultAction = await dispatch(
        updateOneUpload({
          customerId: currentCustomerId,
          uploadId,
          payload: {
            fileName,
            contentType,
            s3Key,
            ...(fileKey && (physicalLocation as Record<string, unknown>) && { physicalLocation }),
            ...((staff as Record<string, unknown>) && { staff }),
            metadata: {
              ...metaValues,
              ...(!fileKey &&
                (physicalLocation as Record<string, unknown>) && { physicalLocation }),
            },
          },
        })
      );

      if (updateOneUpload.fulfilled.match(resultAction)) {
        const { payload } = resultAction;

        if (payload)
          formSuccessMessage(
            `${startCase(payload.type)} ${
              includes(['property', 'other-assets'], payload.type) && payload.metadata.name
                ? `${payload.metadata.name} `
                : ''
            }${
              includes(['insurance'], payload.type) && payload.metadata.title
                ? `${payload.metadata.title} `
                : ''
            }was updated`
          );
      }
      if (updateOneUpload.rejected.match(resultAction)) {
        formErrorMessage(resultAction.payload as string);
      }
    }
  };

  const handleEditEntrySubmit = async (values: Record<string, unknown>) => {
    setFormDisabled(true);

    if (currentCustomerId) {
      const changes = getObjectDiff(initialValues, values);

      if (changes.length) {
        const willChanges: string[] = [];
        const spouseWillChanges: string[] = [];
        const poaChanges: string[] = [];
        const spousePoaChanges: string[] = [];
        const healthChanges: string[] = [];
        const spouseHealthChanges: string[] = [];

        changes.forEach((change) => {
          if (change.indexOf('spouseWill') > -1) {
            spouseWillChanges.push(change);
          } else if (change.indexOf('will') > -1) {
            willChanges.push(change);
          } else if (change.indexOf('poa') > -1) {
            poaChanges.push(change);
          } else if (change.indexOf('spousePoa') > -1) {
            spousePoaChanges.push(change);
          } else if (change.indexOf('health') > -1) {
            healthChanges.push(change);
          } else if (change.indexOf('spouseHealth') > -1) {
            spouseHealthChanges.push(change);
          }
        });

        if (willChanges.length) {
          const { willUploadId } = values;
          await processChanges(willChanges, values, willUploadId as string, undefined, 'willFile');
        }

        if (spouseWillChanges.length) {
          const { spouseWillUploadId } = values;
          await processChanges(
            spouseWillChanges,
            values,
            spouseWillUploadId as string,
            undefined,
            'spouseWillFile'
          );
        }

        if (poaChanges.length) {
          const { poaUploadId } = values;
          await processChanges(poaChanges, values, poaUploadId as string, undefined, 'poaFile');
        }

        if (spousePoaChanges.length) {
          const { spousePoaUploadId } = values;
          await processChanges(
            spousePoaChanges,
            values,
            spousePoaUploadId as string,
            undefined,
            'spousePoaFile'
          );
        }

        if (healthChanges.length) {
          const { healthUploadId } = values;
          await processChanges(
            healthChanges,
            values,
            healthUploadId as string,
            undefined,
            'healthFile'
          );
        }

        if (spouseHealthChanges.length) {
          const { spouseHealthUploadId } = values;
          await processChanges(
            spouseHealthChanges,
            values,
            spouseHealthUploadId as string,
            undefined,
            'spouseHealthFile'
          );
        }

        const multipleChanges: Record<string, unknown> = {};

        changes.forEach((change: string) => {
          const index = change.split('-')[1] as unknown as number;
          if (index === undefined) return;

          if (multipleChanges[index]) {
            (multipleChanges[index] as string[]).push(change);
          } else {
            multipleChanges[index] = [change];
          }
        });

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(multipleChanges)) {
          let uploadId;
          if (modalType === 'insurance') {
            uploadId = values[`lifeInsuranceUploadId-${key}`];
          } else {
            uploadId = values[`${camelCase(modalType)}UploadId-${key}`];
          }

          processChanges(
            value as unknown as string[],
            values,
            uploadId as string,
            parseInt(key, 10)
          );
        }
      }
    }
    setFormDisabled(false);
    closeModal();
  };

  const handleAddEntrySubmit = async (
    values: Record<string, unknown>,
    formikHelpers: FormikHelpers<Record<string, unknown>>
  ) => {
    setNewFormDisabled(true);

    const { setFieldError } = formikHelpers;

    if (currentCustomerId && clientId && client) {
      const { type, 'staff-0': staff, ...rest } = values;
      const { userId } = client;
      const metaValues: Record<string, unknown> = {};
      const isLinkIntakeForm = values?.['isLinkIntakeForm-0'] === 1;
      let physicalLocation = '';
      let contentType = '';
      let fileName = '';
      let s3Key = '';

      if (sectionByType(type as string) === 'legal') {
        if (
          clientFiles[clientFilesByType(type as string)] &&
          clientFiles[clientFilesByType(type as string)].length
        ) {
          const file = clientFiles[clientFilesByType(type as string)][0];
          contentType = file.contentType;
          fileName = file.fileName;

          if (file.key) s3Key = file.key;
        }

        if (
          !values[`${camelCase(type as string)}PhysicalLocation`] &&
          includes([0, undefined], (values as Record<string, unknown>).isIntakeFormSent)
        ) {
          setFieldError(`${camelCase(type as string)}PhysicalLocation`, 'Required');
          setNewFormDisabled(false);
          return;
        }
      }

      if (type === 'financial') {
        let errors = false;
        if (!values['branch-0']) {
          setFieldError('branch-0', 'Required');
          errors = true;
        }
        if (!values['financialAccountType-0']) {
          setFieldError('financialAccountType-0', 'Required');
          errors = true;
        }
        if (!values['accountNumber-0']) {
          setFieldError('accountNumber-0', 'Required');
          errors = true;
        }
        if (errors) {
          setNewFormDisabled(false);
          return;
        }
      }

      if (type === 'property') {
        let errors = false;
        if (!values['propertyTitle-0']) {
          setFieldError('propertyTitle-0', 'Required');
          errors = true;
        }

        if (!values['address-0']) {
          setFieldError('address-0', 'Required');
          errors = true;
        }

        if (values?.['isIntakeFormSent-0'] === 1 && !values['completionDate-0']) {
          setFieldError('completionDate-0', 'Required');
          errors = true;
        }
        if (
          values?.['isIntakeFormSent-0'] === 1 &&
          !values?.['formType-0'] &&
          values?.['isLinkIntakeForm-0'] === 0
        ) {
          setFieldError('formType-0', 'Required');
          errors = true;
        }
        if (values?.['isIntakeFormSent-0'] === 1 && isLinkIntakeForm && !values?.['fileNumber-0']) {
          setFieldError('fileNumber-0', 'Required');
          errors = true;
        }
        if (errors) {
          setNewFormDisabled(false);
          return;
        }
      }

      if (formType === 'insurance') {
        let errors = false;

        if (!values['insuranceTitle-0']) {
          setFieldError('insuranceTitle-0', 'Required');
          errors = true;
        }
        if (!values['insuranceName-0']) {
          setFieldError('insuranceName-0', 'Required');
          errors = true;
        }
        if (!values['insurancePolicy-0']) {
          setFieldError('insurancePolicy-0', 'Required');
          errors = true;
        }
        if (!values['lifeInsurancePhysicalLocation-0']) {
          setFieldError('lifeInsurancePhysicalLocation-0', 'Required');
          errors = true;
        }

        if (errors) {
          setNewFormDisabled(false);
          return;
        }
      }

      Object.keys(rest).forEach((r: string) => {
        if (normalizeFieldName(r.split('-')[0]) === 'physicalLocation') {
          physicalLocation = rest[r] as string;
        } else if (r.split('-')[0] === 'beneficiary') {
          if (metaValues.beneficiaries) {
            (metaValues.beneficiaries as Record<string, unknown>[]).push({
              name: rest[r],
            });
          } else {
            metaValues.beneficiaries = [{ name: rest[r] }];
          }
        } else if (r.split('-')[0] === 'jointOwner') {
          if (metaValues.shareJointOwnership) {
            (metaValues.shareJointOwnership as Record<string, unknown>[]).push({
              fullname: rest[r],
            });
          } else {
            metaValues.shareJointOwnership = [{ fullname: rest[r] }];
          }
        } else if (r.includes('Notes')) {
          metaValues.notes = rest[r];
        } else if (r.includes('financialAccount')) {
          if (!r.includes('financialAccountType')) {
            const field = lowerCase(r.split('-')[0].replace('financialAccount', ''));
            metaValues[field] = rest[r];
          } else {
            metaValues.accountType = rest[r];
          }
        } else {
          metaValues[normalizeFieldName(r.split('-')[0])] = rest[r];
        }
      });

      const resultAction = await dispatch(
        createOneUpload({
          payload: {
            type: normalizeUploadTypes(type as string),
            customerId: currentCustomerId,
            userId,
            clientId,
            section: sectionByType(type as string),
            ...(formType !== 'insurance' && physicalLocation !== '' && { physicalLocation }),
            ...(s3Key !== '' && { s3Key, uuid: s3Key.split('/')[3] }),
            ...((staff as Staff) && { staff }),
            contentType,
            fileName,
            metadata: {
              ...metaValues,
              ...(isLinkIntakeForm && { linkToExistingForm: true }),
              ...((formType === 'insurance' || formType === 'other-assets') &&
                physicalLocation !== '' && { physicalLocation }),
              ...(formType === 'will' && isLinkIntakeForm && { formType: 'will' }),
            },
          },
        })
      );

      if (createOneUpload.fulfilled.match(resultAction)) {
        const { payload } = resultAction;
        if (payload && payload.section === 'assets') {
          const files = clientFiles[clientFilesByType(type as string)];
          if (files && files.length) {
            files.forEach((file) => {
              if (currentCustomerId && payload.id && payload.userId) {
                const fileInfo = {
                  fileName: file.fileName,
                  contentType: file.contentType,
                  key: file.key,
                };
                postFile(fileInfo as FileInfo, currentCustomerId, payload.id, payload.userId);
              }
            });
          }
        }
        closeModal();

        const linkIntakeFormSuccessMessage = `Property was linked to Intake Form - ${values?.['fileNumber-0']}`;
        const messagePayload =
          !Object.keys(documents).length && client?.metadata.areDocumentsUploaded
            ? 'An email with the invite has been sent to your client'
            : 'The entry was added';

        formSuccessMessage(isLinkIntakeForm ? linkIntakeFormSuccessMessage : messagePayload);
      }
      if (createOneUpload.rejected.match(resultAction)) {
        const linkFormError = 'Unable to find form with fileNumber';
        const payload = (resultAction.payload as string) || '';

        if (payload.indexOf(linkFormError) > -1) {
          setFieldError('fileNumber-0', payload.replace('fileNumber', 'File Number'));
        } else {
          formErrorMessage(payload);
        }
      }
      setNewFormDisabled(false);
    }
  };

  if (editModalOpen) {
    init(documents);
  }

  const AddNewButton = () => {
    return (
      <Button
        content="Add a New Entry"
        style={
          Object.keys(documents).length ? { float: 'right', width: '14rem' } : { width: '14rem' }
        }
        onClick={() => {
          setAddModalOpen(true);

          setBeneficiaries({
            'insurance-0': [{ name: 'firstInsurance' }],
          });
          setJointOwners({
            'financialAccount-0': [{ fullname: 'firstFinancialAccount' }],
          });
          setFinancialBeneficiaries({
            'financialAccount-0': [{ name: 'firstFinancialAccount' }],
          });
        }}
        primary
      />
    );
  };

  const typeOptions = [
    <Dropdown.Divider />,
    <Dropdown.Header content="Assets" />,
    <Dropdown.Divider />,
    {
      key: 'property',
      text: 'Property',
      value: 'property',
    },
    {
      key: 'insurance',
      text: 'Life Insurance',
      value: 'insurance',
    },
    <Dropdown.Divider />,
    <Dropdown.Header content="Legal" />,
    <Dropdown.Divider />,
    {
      key: 'will',
      text: 'Will',
      value: 'will',
    },
    {
      key: 'spouse-will',
      text: "Spouse / Partner's Will",
      value: 'spouse-will',
    },
    {
      key: 'poa',
      text: 'Power of Attorney',
      value: 'poa',
    },
    {
      key: 'spouse-poa',
      text: "Spouse / Partner's Power of Attorney",
      value: 'spouse-poa',
    },
    {
      key: 'health',
      text: 'Health Directive',
      value: 'health',
    },
    {
      key: 'spouse-health',
      text: "Spouse / Partner's Health Directive",
      value: 'spouse-health',
    },
  ];

  return (
    <>
      <Modal
        closeIcon
        onClose={closeModal}
        open={addModalOpen}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        className="Modal___centered"
        style={{ marginTop: '5em', padding: '1em 0' }}
      >
        <Modal.Content style={{ textAlign: 'center' }}>
          <Modal.Description>
            <Formik<Record<string, unknown>>
              innerRef={formikRef}
              enableReinitialize
              initialValues={initialNewValues}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleAddEntrySubmit}
            >
              <Form size="large" noValidate>
                <Modal.Header style={{ marginBottom: '1.5em' }}>Add Entry</Modal.Header>
                <Select
                  className="Dropdown_AddEntry"
                  name="type"
                  label="Entry Type"
                  selectOnBlur={false}
                  options={typeOptions}
                  disabled={newFormDisabled}
                  defaultValue={formType}
                  value={formType}
                  onChange={(e, data) => {
                    e.preventDefault();
                    const { value } = data;
                    if (value && formType !== value) {
                      setFormType(value as string);
                    }
                  }}
                />
                {formType === 'will' && (
                  <ClientWillFields
                    data={null}
                    formDisabled={newFormDisabled}
                    formType={formType}
                  />
                )}

                {/*
                TODO: A lot of these Fields components are just copy n paste,
                we should create a reusable component instead...
                */}

                {formType === 'spouse-will' && (
                  <ClientSpouseWillFields data={null} formDisabled={newFormDisabled} />
                )}
                {formType === 'poa' && (
                  <ClientPoaFields data={null} formDisabled={newFormDisabled} />
                )}
                {formType === 'spouse-poa' && (
                  <ClientSpousePoaFields data={null} formDisabled={newFormDisabled} />
                )}
                {formType === 'health' && (
                  <ClientHealthFields data={null} formDisabled={newFormDisabled} />
                )}
                {formType === 'spouse-health' && (
                  <ClientSpouseHealthFields data={null} formDisabled={newFormDisabled} />
                )}
                {formType === 'property' && (
                  <ClientPropertyField
                    data={null}
                    formDisabled={newFormDisabled}
                    index={0}
                    formType={formType}
                  />
                )}
                {formType === 'insurance' && (
                  <ClientInsuranceField
                    data={null}
                    formDisabled={newFormDisabled}
                    index={0}
                    beneficiaries={beneficiaries}
                    setBeneficiaries={setBeneficiaries}
                  />
                )}
                {formType === 'property' && (
                  <ReadyAdminStaffDropdown name="staff-0" disabled={newFormDisabled} />
                )}
              </Form>
            </Formik>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={
              Object.keys(documents).length || !client?.metadata.areDocumentsUploaded
                ? 'Add Entry'
                : 'Add Entry and Send Invite'
            }
            loading={Boolean(uploadingFiles)}
            disabled={newFormDisabled || Boolean(uploadingFiles)}
            onClick={() => {
              if (formikRef && formikRef.current) {
                formikRef.current.submitForm();
              }
            }}
            primary
          />
        </Modal.Actions>
      </Modal>

      <ReadyAdminTopNav />
      <Grid>
        <Grid.Column width={12}>
          <div className="Page_mainContent___dense">
            <Container>
              <Breadcrumb>
                <Link to="/clients/">
                  <Breadcrumb.Section>Dashboard</Breadcrumb.Section>
                </Link>
                <Breadcrumb.Divider icon="right chevron" />
                <Breadcrumb.Section>
                  <strong>
                    {client?.firstName} {client?.lastName}
                  </strong>
                </Breadcrumb.Section>
              </Breadcrumb>
              {Object.keys(documents).length && !loading ? (
                <>
                  <Grid columns={2} stackable style={{ margin: '0 0 .1rem' }}>
                    <Grid.Row>
                      <Grid.Column width={13}>
                        <header className="pageTitle">
                          <h4>Entries</h4>
                        </header>
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <AddNewButton />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  {documents.property &&
                    documents.property.length &&
                    documents.property.map((cardData, cardIndex) => {
                      return (
                        <CardPropertyOrWill
                          key={cardData.id + cardIndex}
                          cardData={cardData}
                          editModalOpen={() => setEditModalOpen(true)}
                          changeModalType={changeModalType}
                        />
                      );
                    })}
                  {documents.will && (
                    <CardPropertyOrWill
                      cardData={documents.will[0]}
                      editModalOpen={() => setEditModalOpen(true)}
                      changeModalType={changeModalType}
                    />
                  )}
                  {Object.keys(documents).length && (
                    <>
                      <CardNotPropertyOrWill
                        documents={documents}
                        editModalOpen={() => setEditModalOpen(true)}
                        changeModalType={changeModalType}
                      />
                    </>
                  )}
                </>
              ) : (
                [
                  loading ? (
                    <Dimmer
                      key={uuidv4()}
                      active
                      inverted
                      style={{
                        marginTop: '14px',
                      }}
                    >
                      <Loader inverted>Loading</Loader>
                    </Dimmer>
                  ) : (
                    <ReadyAdminEmptyPage
                      key={uuidv4()}
                      dataFetched={dataFetched}
                      content={
                        client?.metadata.areDocumentsUploaded
                          ? `Add your client's documentation when you are ready. Please note that as soon as you add the first Entry your Client will receive an invite.`
                          : `Add your client's documentation when you are ready.`
                      }
                      button={<AddNewButton />}
                    />
                  ),
                ]
              )}
              <>
                <Modal
                  closeIcon
                  onClose={closeModal}
                  open={editModalOpen}
                  closeOnEscape={false}
                  closeOnDimmerClick={false}
                  className="Modal___centered"
                  style={{ padding: '1em 0', marginTop: '6em' }}
                >
                  <Modal.Content style={{ textAlign: 'center' }}>
                    <Modal.Description>
                      {documents && Object.keys(documents).length ? (
                        <Formik
                          enableReinitialize
                          initialValues={initialValues}
                          validationSchema={editEntryValidationSchema}
                          validateOnChange={false}
                          validateOnBlur={false}
                          onSubmit={handleEditEntrySubmit}
                        >
                          {(
                            props: FormikProps<
                              | {
                                  willFile: string;
                                  spouseWillFile: string;
                                  poaFile: string;
                                  spousePoaFile: string;
                                  healthFile: string;
                                  spouseHealthFile: string;
                                  ['lifeInsuranceFiles-0']: string;
                                  ['propertyFiles-0']: string;
                                  ['otherLegalFiles-0']: string;
                                }
                              | Record<string, never>
                            >
                          ) => {
                            return (
                              <Form size="large" noValidate>
                                <Modal.Header style={{ marginBottom: '1em' }}>
                                  Edit Entry
                                </Modal.Header>
                                {documents.will && modalType === 'will' && (
                                  <ClientWillFields
                                    data={documents.will ? documents.will[0] : null}
                                    formDisabled={formDisabled}
                                    isEditModalOpen={editModalOpen}
                                  />
                                )}
                                {documents['spouse-will'] && modalType === 'spouse-will' && (
                                  <ClientSpouseWillFields
                                    data={
                                      documents['spouse-will'] ? documents['spouse-will'][0] : null
                                    }
                                    formDisabled={formDisabled}
                                  />
                                )}
                                {documents['power-of-attorney'] &&
                                  modalType === 'power-of-attorney' && (
                                    <ClientPoaFields
                                      data={
                                        documents['power-of-attorney']
                                          ? documents['power-of-attorney'][0]
                                          : null
                                      }
                                      formDisabled={formDisabled}
                                    />
                                  )}
                                {documents['spouse-power-of-attorney'] &&
                                  modalType === 'spouse-power-of-attorney' && (
                                    <ClientSpousePoaFields
                                      data={
                                        documents['spouse-power-of-attorney']
                                          ? documents['spouse-power-of-attorney'][0]
                                          : null
                                      }
                                      formDisabled={formDisabled}
                                    />
                                  )}
                                {documents['health-directive'] &&
                                  modalType === 'health-directive' && (
                                    <ClientHealthFields
                                      data={
                                        documents['health-directive']
                                          ? documents['health-directive'][0]
                                          : null
                                      }
                                      formDisabled={formDisabled}
                                    />
                                  )}
                                {documents['spouse-health-directive'] &&
                                  modalType === 'spouse-health-directive' && (
                                    <ClientSpouseHealthFields
                                      data={
                                        documents['spouse-health-directive']
                                          ? documents['spouse-health-directive'][0]
                                          : null
                                      }
                                      formDisabled={formDisabled}
                                    />
                                  )}
                                {documents.insurance &&
                                  modalType === 'insurance' &&
                                  documents.insurance.map((doc, index) => {
                                    return (
                                      <>
                                        {doc.id === modalId && (
                                          <ClientInsuranceField
                                            key={doc.id + index}
                                            data={doc}
                                            formDisabled={formDisabled}
                                            index={index}
                                            beneficiaries={beneficiaries}
                                            setBeneficiaries={setBeneficiaries}
                                          />
                                        )}
                                      </>
                                    );
                                  })}
                                {documents.property &&
                                  modalType === 'property' &&
                                  documents.property.map((doc, index) => {
                                    return (
                                      <>
                                        {doc.id === modalId && (
                                          <ClientPropertyField
                                            key={doc.id + index}
                                            data={doc}
                                            formDisabled={formDisabled}
                                            index={index}
                                          />
                                        )}
                                      </>
                                    );
                                  })}
                                {modalType === 'property' && (
                                  <ReadyAdminStaffDropdown name="staff-0" disabled={formDisabled} />
                                )}
                                <SubmitButton
                                  primary
                                  className="FileUpload_submit"
                                  disabled={
                                    !(
                                      (props.isValid && props.dirty) ||
                                      props.touched.willFile ||
                                      props.touched.spouseWillFile ||
                                      props.touched.poaFile ||
                                      props.touched.spousePoaFile ||
                                      props.touched.healthFile ||
                                      props.touched.spouseHealthFile ||
                                      props.touched['lifeInsuranceFiles-0'] ||
                                      props.touched['propertyFiles-0'] ||
                                      props.touched['otherLegalFiles-0']
                                    )
                                  }
                                >
                                  Save
                                </SubmitButton>
                              </Form>
                            );
                          }}
                        </Formik>
                      ) : null}
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
              </>
            </Container>
          </div>
        </Grid.Column>
        <Grid.Column
          width={4}
          className="Sidebar Signup"
          style={{ borderLeft: '1px solid #d2d2d2' }}
        >
          <ReadyAdminDashboardSideNav clientId={clientId} />
        </Grid.Column>
      </Grid>
    </>
  );
};

export default ClientEntriesPage;
