import { useEffect } from 'react';
import { Menu, MenuItem } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../assets/logo-portal-header.svg';
import { logout, selectLogin } from '../features/login/loginSlice';
import {
  fetchCustomers,
  selectCustomers,
  setCurrentCustomerId,
} from '../features/customers/customersSlice';
import { AppDispatch } from '../app/store';

async function signOut() {
  try {
    await Auth.signOut({ global: true });
  } catch (err) {
    console.log('error signing out: ', err);
  }
}

interface TopNavProps {
  title?: string;
  closePath?: boolean;
  callback?: () => void;
}
const ReadyAdminTopNav = ({ title, closePath, callback }: TopNavProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { customers, currentCustomerId } = useSelector(selectCustomers);
  const { currentUser } = useSelector(selectLogin);

  useEffect(() => {
    if (currentUser && customers && !customers.length) {
      dispatch(fetchCustomers(currentUser));
    }

    const savedCustomerId = sessionStorage.getItem('SavedCustomerId');
    if (customers[0] && currentCustomerId === null) {
      if (savedCustomerId !== null) {
        dispatch(setCurrentCustomerId(savedCustomerId));
      } else if (customers[0] !== null) {
        sessionStorage.setItem('SavedCustomerId', customers[0]);
        dispatch(setCurrentCustomerId(customers[0]));
      }
    }
  }, [dispatch, customers, currentCustomerId, currentUser]);

  return (
    <header className="App-header">
      <Menu pointing secondary fluid>
        <Menu.Item>
          <Link to="/">
            <img width={140} height={24} src={logo} className="ui " alt="ReadyWhen Logo" />
          </Link>
        </Menu.Item>
        {title && <h1 className="TopHeader_h1">{title}</h1>}
        {/* <MenuItem>
          <Link to="/clients">Clients</Link>
        </MenuItem>

        <MenuItem>
          <Link to="/licenses">License Manager</Link>
        </MenuItem>

        <MenuItem>
          <Link to="/invoices">Invoices</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/clients/intake" target="_blank">
            [TEMP]Client Intake Form
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/clients/sign-up" target="_blank">
            [TEMP]Client Sign Up Form
          </Link>
        </MenuItem> */}

        <Menu.Menu position="right">
          <MenuItem>
            {closePath ? (
              <Link
                to="/#"
                className="TopHeader_closeButton"
                onClick={(e) => {
                  e.preventDefault();
                  if (callback) {
                    callback();
                  } else {
                    navigate(-1);
                  }
                }}
              >
                <FontAwesomeIcon icon={['far', 'times']} style={{ marginRight: '4px' }} /> Close
              </Link>
            ) : (
              <Link
                to="/"
                onClick={async (e) => {
                  e.preventDefault();
                  dispatch(logout());
                  navigate('/');
                  await signOut();
                }}
              >
                Sign Out
              </Link>
            )}
          </MenuItem>
        </Menu.Menu>
      </Menu>
    </header>
  );
};

ReadyAdminTopNav.defaultProps = {
  title: '',
  closePath: false,
  callback: null,
};

export default ReadyAdminTopNav;
