import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { Button, Header, Modal, Transition } from 'semantic-ui-react';
import { PatchFormPayload } from '../../../api/interfaces';
import { AppDispatch } from '../../../app/store';
import { updateForm } from './formsSlice';

interface ThankYouModalProps {
  modalOpen: number;
  cancel: () => void;
  formEdited: boolean;
  currentCustomerId: string;
  clientId: string;
  formId: string;
  formValues: Record<string, string>;
  fileNumber: string;
}
const ThankYouModal = ({
  modalOpen,
  cancel,
  formEdited,
  currentCustomerId,
  clientId,
  formId,
  formValues,
  fileNumber,
}: ThankYouModalProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { id: documentId } = useParams();

  const handleSubmit = async () => {
    const newMetadata = {
      fileNumber: formValues?.fileNumber,
      currentValues: formValues,
      edits: {
        origin: 'ap',
        isHighlighted: false,
        changes: {},
      },
    };

    const payload = {
      customerId: currentCustomerId,
      clientId,
      formId,
      metadata: newMetadata,
      status: 'in progress',
      sendNotification: false,
    };

    await dispatch(updateForm(payload as PatchFormPayload));

    if (documentId && clientId) {
      navigate(`/clients/${clientId}/${documentId}`);
    } else {
      navigate('/');
    }
  };

  return (
    <>
      <Transition visible={modalOpen === 1} animation="scale" duration={400}>
        <Modal closeIcon size="tiny" open={modalOpen === 1} onClose={cancel}>
          <Header icon>Are you sure you want to quit?</Header>
          <Modal.Content>
            <Modal.Description style={{ margin: '20px' }}>
              The data entered for this form won’t be saved.
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button name="cancel" onClick={cancel}>
              Cancel
            </Button>
            <Button primary name="yes" onClick={handleSubmit}>
              Yes, I’m sure
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>

      <Transition visible={modalOpen === 2} animation="scale" duration={400}>
        <Modal open={modalOpen === 2} onClose={cancel}>
          <Header>Thank you for submitting the {clientId ? 'client' : ''} intake form</Header>
          <Modal.Content>
            <Modal.Description style={{ marginTop: '20px', textAlign: 'center' }}>
              {formEdited && clientId
                ? 'Your client will receive an email notifying them that they can access the form'
                : 'Your updates have been saved'}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button primary name="continue" onClick={() => navigate(-1)}>
              Continue
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </>
  );
};

export default ThankYouModal;
