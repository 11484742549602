import { useMemo, Fragment, useEffect, useState, useRef, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, CheckboxProps, Divider, FormGroup, Dimmer, Grid, Loader } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import includes from 'lodash/includes';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import {
  CopyFields,
  DateInput,
  DateWithCopyInput,
  EmailInput,
  MoneyInput,
  PhoneInput,
  RadioGroupInput,
  TextAreaInput,
  TextInput,
} from 'readywhen-ui-components';
import { RootState } from '../../../app/rootReducer';
import { selectCustomers } from '../../customers/customersSlice';
import { updateForm } from './formsSlice';
import ReadyAdminTopNav from '../../../components/ReadyAdminTopNav';
import ReadyFormHeader from '../../../components/ReadyFormHeader';

import { Document, Form as FormInterface, PatchFormPayload } from '../../../api/interfaces';
import { AppDispatch } from '../../../app/store';
// eslint-disable-next-line import/no-cycle
import { InternalNotesSale } from './InternalNotesSale';
import { getDiff, isNotification, makeNamesArr, removeEmptyAndIgnorePhone } from './formsUtils';
import { saleInternalNotesKeys, formFileTypes } from '../../../constants/forms';
import ThankYouModal from './ThankYouModal';
import HeaderRemoveButton from './HeaderRemoveButton';
import { useFormDataFetch } from './hooks';
import { formErrorMessage } from '../formMessage';

interface SellerProps {
  index: number;
  removeIndexField: (index: number, fieldName: string) => void;
  formDisabled: boolean;
  formData: Record<string, unknown>;
  handleSellerChange: (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
}

interface TenantsProps {
  index: number;
  removeIndexField: (index: number, fieldName: string) => void;
  formDisabled: boolean;
}

const SellerInfoFields = ({
  index,
  removeIndexField,
  formDisabled,
  formData,
  handleSellerChange,
}: SellerProps) => {
  return (
    <Fragment key={`seller-${index}`}>
      <HeaderRemoveButton index={index} removeIndexField={removeIndexField} fieldName="seller" />

      <FormGroup widths="equal">
        <TextInput
          name={`sellers[${index}].firstName`}
          label="First Name *"
          disabled={formDisabled}
        />
        <TextInput
          name={`sellers[${index}].middleName`}
          label="Middle Name"
          disabled={formDisabled}
        />
        <TextInput
          name={`sellers[${index}].lastName`}
          label="Last Name *"
          disabled={formDisabled}
        />
      </FormGroup>

      <FormGroup className="Form_notes">
        <span className="Name_notes">
          Please enter full legal name as per your government-issued identification (ie Passport /
          Driver&apos;s License).
        </span>
        <CopyFields
          label="Copy Full Name"
          fields={[
            `sellers[${index}].firstName`,
            `sellers[${index}].middleName`,
            `sellers[${index}].lastName`,
          ]}
        />
      </FormGroup>

      <FormGroup widths="equal">
        <EmailInput name={`sellers[${index}].email`} label="Email *" disabled={formDisabled} />

        <PhoneInput
          name={`sellers[${index}].phone`}
          label="Phone Number"
          disabled={formDisabled}
          defaultValue={(formData.sellers as Record<string, any>[])[index]?.phone.value}
        />
      </FormGroup>

      <FormGroup widths="equal">
        <DateWithCopyInput
          name={`sellers[${index}].dob`}
          label="Date of Birth"
          disabled={formDisabled}
        />

        <TextInput
          name={`sellers[${index}].sin`}
          label="Social Insurance Number (SIN)"
          placeholder="XXX-XXX-XXX or 9 digits"
          disabled={formDisabled}
        />
      </FormGroup>

      <TextInput
        name={`sellers[${index}].occupation`}
        label="Occupation"
        disabled={formDisabled}
        className="Form_input___halfWidth"
      />

      <RadioGroupInput
        name={`sellers[${index}].citizenResident`}
        label="Are you a Canadian Citizen / Permanent Resident?"
        value={(formData.sellers as Record<string, string>[])[index]?.citizenResident}
        onChange={handleSellerChange}
      />

      {(formData.sellers as Record<string, string>[])[index]?.citizenResident === '0' && (
        <TextInput
          name={`sellers[${index}].residence`}
          label="Country of residence"
          disabled={formDisabled}
          className="Form_input___halfWidth"
        />
      )}
    </Fragment>
  );
};

const TenantsFields = ({ index, removeIndexField, formDisabled }: TenantsProps) => {
  return (
    <Fragment key={`tenant-${index}`}>
      <HeaderRemoveButton index={index} removeIndexField={removeIndexField} fieldName="tenant" />
      <FormGroup widths="equal">
        <MoneyInput
          name={`tenants[${index}].monthlyRent`}
          label="Monthly Rent"
          disabled={formDisabled}
        />
        <TextInput
          name={`tenants[${index}].rentDayOfMonth`}
          label="Rent paid on what day of the month"
          disabled={formDisabled}
        />
      </FormGroup>
      <FormGroup widths="equal">
        <MoneyInput
          name={`tenants[${index}].securityDeposit`}
          label="Security Deposit"
          disabled={formDisabled}
        />
        <DateInput
          name={`tenants[${index}].dateSecurityDeposit`}
          label="Date security deposit taken"
          disabled={formDisabled}
        />
      </FormGroup>
    </Fragment>
  );
};

const MortgagesFields = ({ index, removeIndexField, formDisabled }: TenantsProps) => {
  return (
    <Fragment key={`mortgage-${index}`}>
      <HeaderRemoveButton index={index} removeIndexField={removeIndexField} fieldName="mortgage" />
      <FormGroup widths="equal">
        <TextInput name={`mortgages[${index}].bank`} label="Name of Bank" disabled={formDisabled} />
        <TextInput
          name={`mortgages[${index}].referenceNumber`}
          label="Mortgage Reference Number"
          disabled={formDisabled}
        />
      </FormGroup>
      <FormGroup widths="equal">
        <TextInput
          name={`mortgages[${index}].branchAddress`}
          label="Branch Address"
          disabled={formDisabled}
        />
      </FormGroup>
    </Fragment>
  );
};

const FormSale = (): JSX.Element => {
  const { currentCustomerId, loading: loadingCustomer } = useSelector(selectCustomers);
  const { clientId, id, intakeFormId } = useParams();
  const formikRef = useRef<FormikProps<typeof initialData>>(null);
  const { documents, loading: loadingDocuments } = useSelector((state: RootState) => state.clients);
  const dispatch: AppDispatch = useDispatch();
  const { loading: loadingForms, entities } = useSelector((state: RootState) => state.forms);
  const loading = loadingForms || loadingDocuments || loadingCustomer;
  const [modalOpen, setModalOpen] = useState(0);
  const [formEdited, setFormEdited] = useState(false);
  const [editedFields, setEditedFields] = useState<string[]>([]);

  const displayedProperty = useMemo(
    () =>
      documents && documents.property
        ? documents.property.find((property: Document) => property.id === id) ||
          (documents.property.find((property: Document) => property.assetId === id) as Document)
        : {
            metadata: {
              name: '',
              address: '',
              suite: '',
              city: '',
              postcode: '',
              completionDate: '',
            },
          },
    [documents, id]
  );

  const formId = useMemo(() => {
    if (intakeFormId) {
      return intakeFormId;
    }

    return displayedProperty?.forms?.length ? displayedProperty.forms[0].id : { id: '' };
  }, [displayedProperty, intakeFormId]);

  const form: FormInterface = useMemo(() => entities[formId], [entities, formId]);

  const { type: formType } = form ?? {};
  const fileNumber = useMemo(() => {
    return form?.metadata?.fileNumber || form?.metadata?.currentValues?.fileNumber;
  }, [form]);

  useFormDataFetch({ currentCustomerId, dispatch, clientId, intakeFormId, formId, id });

  const {
    metadata: { name, address, suite, city, postcode, completionDate },
  } = displayedProperty ?? { metadata: {} };

  const title: string =
    address && postcode
      ? `${suite ?? ''} ${address}, ${city ?? ''}, ${postcode.toUpperCase()}`
      : name;

  const [formDisabled, setFormDisabled] = useState(false);

  const emptySeller = useMemo(() => {
    return {
      citizenResident: '',
      email: '',
      firstName: '',
      lastName: '',
      middleName: '',
      phone: {
        value: '',
        country_code: 'CA',
      },
      occupation: '',
      dob: '',
      sin: '',
      residence: '',
    };
  }, []);

  const emptyTenant = useMemo(() => {
    return {
      monthlyRent: '',
      rentDayOfMonth: '',
      securityDeposit: '',
      dateSecurityDeposit: '',
    };
  }, []);

  const emptyMortgage = useMemo(() => {
    return {
      bank: '',
      referenceNumber: '',
      branchAddress: '',
    };
  }, []);

  const initialData = useMemo(() => {
    return {
      fileNumber,
      sellers: [emptySeller],
      tenants: [emptyTenant],
      mortgages: [emptyMortgage],
      forwardingAddress: '',
      realtorName: '',
      realtorPhone: {
        value: '',
        country_code: 'CA',
      },
      realtorEmail: '',
      salePrice: '',
      furtherInfo: '',
      referralInfo: '',
      realtor: '',
      mortgage: '',
      tenantsAfterCompletion: '',
      additionalInfo: '',
      // INTERNAL NOTES
      titleSearch: false,
      payoutOne: false,
      payoutTwo: false,
      vendorDocsSent: false,
      vendorDocsReceived: false,
      fiveDayReport: false,
      finalReport: false,
      releaseParticulars: false,
      other: false,
      otherText: '',
      names: '',
      possessionDate: '',
      adjustmentDate: '',
      hasLawyerOrNotary: false,
      lawyerCompany: '',
      lawyerContactName: '',
      lawyerPhone: '',
      lawyerEmail: '',
      hasRealtor: false,
      realtorOfficeName: '',
      realtorOfficePhone: '',
      realtorOfficeEmail: '',
      realtorOfficeFaxPhone: '',
      notesRealtorName: '',
      notesRealtorPhone: '',
      notesRealtorEmail: '',
      internalNotes: '',
    };
  }, [emptyMortgage, emptySeller, emptyTenant, fileNumber]);

  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    if (form) {
      const { metadata } = form;

      if (metadata.currentValues === undefined) {
        const formatted: any = { ...metadata };
        Object.keys(metadata).forEach((key) => {
          if (key.indexOf('Phone') > -1) {
            formatted[`${key}_ignore_phone`] = metadata[key]?.value;
            formatted[`${key}_ignore_country_code`] = metadata[key]?.country_code;
          }
        });
        setFormData({ ...initialData, ...formatted });
      } else {
        setFormData({ ...initialData, ...metadata.currentValues });
        if (metadata?.edits.origin === 'cp' && metadata?.edits.isHighlighted)
          setEditedFields(makeNamesArr(metadata.edits.changes));
      }
    }
  }, [form, initialData]);

  useEffect(() => {
    if (!isEmpty(editedFields)) {
      editedFields.forEach((key) => {
        const label = document.querySelector(`label[for='${key}']`);
        label?.classList.add('Form_label___highlighted');
      });
    }
  }, [editedFields]);

  const buildFilesSchema = () => {
    let sellersSchema;
    let tenantsSchema;

    const phoneObj = Yup.object().shape({
      value: Yup.string().matches(/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/i, 'Invalid phone number'),
      country_code: Yup.string(),
    });

    const keys = Object.keys(formData);
    if (keys.length) {
      const sellersArray = keys.filter((s) => s.includes('sellers'));

      if (sellersArray.length) {
        sellersSchema = Yup.object().shape({
          firstName: Yup.string().required('Required'),
          lastName: Yup.string().required('Required'),
          email: Yup.string().email('Invalid email address').required('Required'),
          occupation: Yup.string(),
          dob: Yup.date().max(new Date(), 'Incorrect date'),
          sin: Yup.string().matches(/^(\d{3}-\d{3}-\d{3})|(\d{9})$/, 'Invalid SIN format'),
          citizenResident: Yup.string().oneOf(['1', '0']),
          residence: Yup.string(),
          phone: phoneObj,
        });
      }

      const tenantsArray = keys.filter((s) => s.includes('tenants'));
      if (tenantsArray.length) {
        tenantsSchema = Yup.object().shape({
          securityDeposit: Yup.number(),
          dateSecurityDeposit: Yup.date(),
        });
      }
    }

    const commonSchema = {
      realtorPhone: phoneObj,
      realtorEmail: Yup.string().email('Invalid email address'),
      lawyerPhone: phoneObj,
      lawyerEmail: Yup.string().email('Invalid email address'),
      realtorOfficePhone: phoneObj,
      realtorOfficeEmail: Yup.string().email('Invalid email address'),
      realtorOfficeFaxPhone: phoneObj,
      notesRealtorPhone: phoneObj,
      notesRealtorEmail: Yup.string().email('Invalid email address'),
    };
    return Yup.object().shape({
      ...(sellersSchema && { sellers: Yup.array().of(sellersSchema) }),
      ...(tenantsSchema && { tenants: Yup.array().of(tenantsSchema) }),
      ...commonSchema,
    });
  };

  const validationSchema = buildFilesSchema();

  const updateFormValues = () => {
    if (formikRef?.current) {
      const { values } = formikRef.current;

      if (formData !== values) setFormData(values);
    }
  };

  const handleSubmit = async (values: typeof initialData) => {
    setFormDisabled(true);

    const formattedValues = values;

    updateFormValues();

    const diffObj = removeEmptyAndIgnorePhone(
      getDiff(
        formattedValues,
        form.metadata.currentValues !== undefined ? form.metadata.currentValues : form.metadata
      )
    );

    const send = !!clientId && isNotification(saleInternalNotesKeys, diffObj);

    setFormEdited(send);

    const multipleUpdatesDiff =
      form.metadata.edits !== undefined
        ? form.metadata?.edits.origin === 'ap' &&
          merge(cloneDeep(diffObj), form?.metadata.edits.changes)
        : {};

    const newMetadata = {
      fileNumber: values.fileNumber,
      currentValues: formattedValues,
      edits: {
        origin: 'ap',
        isHighlighted: true,
        changes:
          form.metadata.edits !== undefined && form.metadata.edits.origin === 'ap'
            ? multipleUpdatesDiff
            : diffObj,
      },
    };

    const payload = {
      customerId: currentCustomerId as string,
      clientId,
      formId,
      metadata: newMetadata,
      status: 'in progress',
      sendNotification: send,
    };

    const resultAction = await dispatch(updateForm(payload as PatchFormPayload));
    setFormDisabled(false);
    if (updateForm.rejected.match(resultAction)) {
      const errorMessage = (resultAction.payload as string) || 'Error has occurred';
      formErrorMessage(errorMessage.replace('fileNumber', 'file number'));
    } else {
      setModalOpen(2);
    }
  };

  const handleSellerChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const { name: formKey, value } = data;
    const key = (formKey as string).split('.').pop() as string;
    const index = (formKey as string).split('[')[1].split(']')[0] as unknown as number;
    if (formikRef?.current) {
      const { values, setValues } = formikRef.current;
      const newValues = cloneDeep(values);
      (newValues.sellers as Record<string, unknown>[])[index][key] = value;
      setValues(newValues);
      setFormData(newValues);
    }
  };

  const removeIndexField = (index: number, fieldName: string) => {
    if (formikRef?.current) {
      const { values } = formikRef.current;
      const clonedData = clone((formData as any)[fieldName]);
      clonedData.splice(index, 1);
      setFormData({ ...formData, ...values, [fieldName]: [...clonedData] });
    }
  };

  const handleRadioCheckboxChange = (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const { name: formKey, type, checked, value } = data;
    const newVal = type === 'checkbox' ? (checked as boolean) : (value as string);
    if (formikRef?.current) {
      const { values, setValues } = formikRef.current;
      const newValues = cloneDeep(values);
      (newValues as Record<string, unknown>)[formKey as string] = newVal;
      setValues(newValues);
      setFormData(newValues);
    }
  };

  const cancel = () => {
    setModalOpen(0);
  };

  return (
    <>
      <ReadyAdminTopNav
        closePath
        callback={() => {
          setModalOpen(1);
        }}
      />
      <div className="Page_mainContent___full">
        {loading ? (
          <Dimmer
            active
            inverted
            style={{
              marginTop: '60px',
            }}
          >
            <Loader inverted>Loading</Loader>
          </Dimmer>
        ) : (
          <Grid>
            <ReadyFormHeader
              formType={formFileTypes[formType ?? '']}
              clientId={clientId}
              title={title}
              completionDate={completionDate}
            />

            <Grid.Row>
              <Grid.Column>
                <Formik<typeof initialData>
                  enableReinitialize
                  innerRef={formikRef}
                  initialValues={formData}
                  validationSchema={validationSchema}
                  validateOnChange={false}
                  validateOnBlur={false}
                  onSubmit={handleSubmit}
                >
                  {(props: FormikProps<typeof initialData>) => (
                    <Form
                      size="large"
                      noValidate
                      onBlur={(e) => {
                        const { target } = e;

                        if (target.type !== 'checkbox') {
                          if (target.type === 'tel') {
                            if (target?.name?.indexOf('[') > -1) {
                              const parent = target?.name?.split('[')[0];
                              formikRef.current?.validateField(parent);
                              const regex = new RegExp(/\[(.*?)\]/g);
                              const regMatch = regex.exec(target?.name || '');
                              const index = parseInt(regMatch !== null ? regMatch[1] : '0', 10);
                              const child = target?.name?.split('.')[1];
                              const newKey = child?.split('_')[0];

                              const countryCode =
                                (formikRef.current &&
                                  (formikRef.current.values as any)[newKey]?.country_code) ||
                                'CA';

                              const existingArray: Record<string, unknown>[] = [
                                ...(formData[parent as keyof typeof initialData] as []),
                              ];
                              const newChild = {
                                ...existingArray[index],
                                [child]: target.value,
                                [newKey]: { country_code: countryCode, value: target.value },
                              };
                              existingArray.splice(index, 1, newChild);
                              props.validateForm();
                              setFormData({ ...formData, [parent]: existingArray });
                              return;
                            }

                            const newKey = target?.name?.split('_')[0];
                            const countryCode =
                              (formikRef.current &&
                                (formikRef.current.values as any)[newKey].country_code) ||
                              'CA';

                            setFormData({
                              ...formData,
                              [newKey]: { country_code: countryCode, value: target.value },
                              [target.name]: target.value,
                            });
                            return;
                          }

                          if (target?.name?.indexOf('[') > -1) {
                            const parent = target?.name?.split('[')[0];
                            const regex = new RegExp(/\[(.*?)\]/g);
                            const regMatch = regex.exec(target?.name || '');
                            const index = parseInt(regMatch !== null ? regMatch[1] : '0', 10);
                            const child = target?.name?.split('.')[1];
                            const existingArray: Record<string, unknown>[] = [
                              ...(formData[parent as keyof typeof initialData] as []),
                            ];
                            const newChild = { ...existingArray[index], [child]: target.value };

                            existingArray.splice(index, 1, newChild);
                            setFormData({ ...formData, [parent]: existingArray });
                            return;
                          }
                        }
                        if (target.type && !includes(['checkbox', 'submit'], target.type)) {
                          setFormData({ ...formData, [target.name]: target.value });
                        }
                      }}
                    >
                      <TextInput name="fileNumber" label="File Number" />
                      <Divider style={{ margin: '40px 0' }} />
                      {formData.sellers.length &&
                        formData.sellers.map((a, index) => (
                          <SellerInfoFields
                            index={index}
                            removeIndexField={removeIndexField}
                            formDisabled={formDisabled}
                            formData={formData}
                            handleSellerChange={handleSellerChange}
                            key={a.firstName}
                          />
                        ))}
                      <Button
                        type="button"
                        primary
                        className="Button_shape___sharp"
                        onClick={() => {
                          if (formikRef?.current) {
                            const { values } = formikRef.current;
                            setFormData({ ...values, sellers: [...values.sellers, emptySeller] });
                          }
                        }}
                      >
                        Add another Seller
                      </Button>
                      <Divider />

                      <TextInput
                        name="forwardingAddress"
                        label="Forwarding mailing address"
                        disabled={formDisabled}
                      />
                      <Divider />
                      <MoneyInput name="salePrice" label="Sale Price" disabled={formDisabled} />
                      <Divider />
                      <RadioGroupInput
                        name="mortgage"
                        label="Do you have a Mortgage?"
                        value={formData.mortgage}
                        onChange={handleRadioCheckboxChange}
                      />
                      {formData.mortgage === '1' && (
                        <>
                          {formData.mortgages.map((a, index) => (
                            <MortgagesFields
                              index={index}
                              removeIndexField={removeIndexField}
                              formDisabled={formDisabled}
                              key={a.referenceNumber}
                            />
                          ))}

                          <Button
                            type="button"
                            primary
                            className="Button_shape___sharp"
                            onClick={() => {
                              if (formikRef?.current) {
                                const { values } = formikRef.current;
                                setFormData({
                                  ...values,
                                  mortgages: [...values.mortgages, emptyMortgage],
                                });
                              }
                            }}
                          >
                            Add another Mortgage
                          </Button>
                        </>
                      )}
                      <Divider />
                      <RadioGroupInput
                        name="realtor"
                        label="Did you use a Realtor?"
                        value={formData.realtor}
                        onChange={handleRadioCheckboxChange}
                      />
                      {formData.realtor === '1' && (
                        <>
                          <TextInput
                            name="realtorName"
                            label="Realtor’s Name"
                            disabled={formDisabled}
                          />
                          <FormGroup widths="equal">
                            <PhoneInput
                              name="realtorPhone"
                              label="Realtor’s Phone Number"
                              disabled={formDisabled}
                            />
                            <EmailInput
                              name="realtorEmail"
                              label="Realtor’s Email"
                              disabled={formDisabled}
                            />
                          </FormGroup>
                        </>
                      )}
                      <Divider />
                      <RadioGroupInput
                        name="tenantsAfterCompletion"
                        label="Does this property have tenants staying after completion date?"
                        value={formData.tenantsAfterCompletion}
                        onChange={handleRadioCheckboxChange}
                      />
                      {formData.tenantsAfterCompletion === '1' && (
                        <>
                          {formData.tenants.map((a, index) => (
                            <TenantsFields
                              index={index}
                              removeIndexField={removeIndexField}
                              formDisabled={formDisabled}
                              key={a.dateSecurityDeposit}
                            />
                          ))}

                          <Button
                            type="button"
                            primary
                            className="Button_shape___sharp"
                            onClick={() => {
                              if (formikRef?.current) {
                                const { values } = formikRef.current;
                                setFormData({
                                  ...values,
                                  tenants: [...values.tenants, emptyTenant],
                                });
                              }
                            }}
                          >
                            Add another Tenant
                          </Button>
                        </>
                      )}
                      <Divider />
                      <RadioGroupInput
                        name="additionalInfo"
                        label="Additional Information"
                        value={formData.additionalInfo}
                        onChange={handleRadioCheckboxChange}
                      />
                      {formData.additionalInfo === '1' && (
                        <>
                          <TextAreaInput
                            rows={4}
                            name="furtherInfo"
                            label="Any further information you would like to share with our office"
                            disabled={formDisabled}
                          />
                        </>
                      )}

                      <Divider />

                      <TextInput
                        name="referralInfo"
                        label="How were you referred to our office?"
                        disabled={formDisabled}
                      />

                      <Divider />

                      <InternalNotesSale
                        formDisabled={formDisabled}
                        formData={formData}
                        handleRadioCheckboxChange={handleRadioCheckboxChange}
                      />

                      <br />
                      <div className="Button_floatRight">
                        <Button
                          content="Save and Close"
                          // eslint-disable-next-line react/prop-types
                          disabled={!props.isValid || loading}
                          onClick={() => {
                            if (formikRef && formikRef.current) {
                              formikRef.current.submitForm();
                            }
                          }}
                          primary
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}

        <ThankYouModal
          fileNumber={fileNumber}
          modalOpen={modalOpen}
          cancel={cancel}
          formEdited={formEdited}
          currentCustomerId={currentCustomerId as string}
          clientId={clientId as string}
          formId={formId}
          formValues={form?.metadata?.currentValues || form?.metadata}
        />
      </div>
    </>
  );
};

export default FormSale;
