import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Grid } from 'semantic-ui-react';
import { useNavigate } from 'react-router';
import { ReadyAdminTable } from 'readywhen-ui-components';
import { RootState } from '../../app/rootReducer';
import ReadyAdminSideNav from '../../components/ReadyAdminSideNav';
import ReadyAdminTopNav from '../../components/ReadyAdminTopNav';
import { licensesSelectors, fetchLicenses } from './licensesSlice';

import ReadyAdminEmptyPage from '../../components/ReadyAdminEmptyPage';
import { selectCustomers } from '../customers/customersSlice';
import { fetchClients } from '../clients/clientsSlice';
import { AppDispatch } from '../../app/store';

interface ButtonProps {
  floatRight: boolean;
}

function BuyLicensesButton({ floatRight }: ButtonProps) {
  const navigate = useNavigate();
  return (
    <Button
      primary
      content="Buy Licenses"
      className={floatRight ? 'Button__Add___right' : 'Button__Add___centered'}
      onClick={() => navigate('/buy-licenses')}
    />
  );
}

const LicensesPage = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const licenses = useSelector(licensesSelectors.selectAll);
  const { loading } = useSelector((state: RootState) => state.licenses);
  const { currentCustomerId } = useSelector(selectCustomers);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    if (currentCustomerId) {
      dispatch(fetchClients({ customerId: currentCustomerId }));
      dispatch(fetchLicenses(currentCustomerId));
      setDataFetched(true);
    }
  }, [currentCustomerId, dispatch]);

  const columns = [
    { field: 'licenseKey', headerName: 'License Key', type: 'copy' },
    { field: 'clientId', headerName: 'Client', type: 'client', sorted: 'ascending' },
    {
      field: 'createdAt',
      headerName: 'Date of Purchase',
      type: 'date',
      format: 'll',
      sorted: 'descending',
    },
    { field: 'expiryDate', headerName: 'Expiry', type: 'date', format: 'll', sorted: 'ascending' },
    { field: 'status', headerName: 'Status', type: 'status', sorted: 'ascending' },
  ];

  return (
    <>
      <ReadyAdminTopNav />
      <Grid divided>
        <Grid.Column width={3}>
          <ReadyAdminSideNav />
        </Grid.Column>

        <Grid.Column width={13}>
          <div className="Page_container">
            <Container style={{ marginTop: '20px' }}>
              {licenses.length || loading ? (
                <>
                  <Grid>
                    <Grid.Column width={10}>
                      <h1>Purchased Licenses</h1>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <BuyLicensesButton floatRight />
                    </Grid.Column>
                  </Grid>

                  <ReadyAdminTable
                    loading={loading}
                    columns={columns}
                    rows={licenses}
                    pageName="Licenses"
                  />
                </>
              ) : (
                <ReadyAdminEmptyPage
                  dataFetched={dataFetched}
                  icon={['far', 'credit-card']}
                  content="You don’t have any licenses in your account"
                  button={<BuyLicensesButton floatRight={false} />}
                />
              )}
            </Container>
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default LicensesPage;
