// eslint-disable-next-line import/prefer-default-export
export const accountOptions = [
  {
    key: 'ACCOUNT_CHEQUING',
    text: 'Chequing',
    value: 'ACCOUNT_CHECKING',
  },
  {
    key: 'ACCOUNT_SAVINGS',
    text: 'Savings',
    value: 'ACCOUNT_SAVINGS',
  },
  {
    key: 'ACCOUNT_INVESTMENT',
    text: 'Investment',
    value: 'ACCOUNT_INVESTMENT',
  },
  {
    key: 'GIC',
    text: 'GIC',
    value: 'GIC',
  },
  {
    key: 'STOCK_BOND_MUTUAL_FUND',
    text: 'Stock/Bond/Mutual Fund',
    value: 'STOCK_BOND_MUTUAL_FUND',
  },
  {
    key: 'RRIF',
    text: 'RRIF',
    value: 'RRIF',
  },
  {
    key: 'RRSP',
    text: 'RRSP',
    value: 'RRSP',
  },
  {
    key: 'RESP',
    text: 'RESP',
    value: 'RESP',
  },
  {
    key: 'TFSA',
    text: 'TFSA',
    value: 'TFSA',
  },
  {
    key: 'ACCOUNT_OTHER',
    text: 'Other',
    value: 'ACCOUNT_OTHER',
  },
];
