/* eslint-disable react/jsx-props-no-spreading */
import React, { Dispatch, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { Formik, useFormikContext } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-semantic-toasts';
import { useSelector } from 'react-redux';
import { buyLicenses } from '../../api/ReadyWhenAdminAPI';
import config from '../../configs/config';
import { selectCustomers } from '../customers/customersSlice';
import { ErrorType } from '../../app/interfaces';

const CARD_OPTIONS = {
  style: {
    base: {
      iconColor: '#000000de',
      fontWeight: '400',
      fontFamily: 'Lato, Helvetica Neue, Arial, Helvetica, sans-serif',
      fontSize: '1em',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#000000de',
      },
      '::placeholder': {
        color: '#757575',
      },
      ':-webkit-tap-highlight': {
        color: '#fff0',
      },
    },
    invalid: {
      iconColor: '#9F3A38',
      color: '#9F3A38',
    },
  },
};

function formErrorMessage(payload: string) {
  toast({
    type: 'error',
    icon: 'exclamation circle',
    title: 'Payment Failed',
    description: payload,
    animation: 'drop',
    time: 10000,
  });
}
interface NumberInputProps {
  name: string;
  label: string;
  autoComplete: string;
  disabled: boolean;
  errorPrompt: boolean;
  action: boolean;
  className: string;
  onChangeCallBack: Dispatch<React.SetStateAction<number>>;
}

const NumberInput = (props: NumberInputProps) => {
  const { values, setFieldValue } = useFormikContext<Record<string, unknown>>();
  const { name } = props;
  const { onChangeCallBack, ...rest } = props;
  const number = values[name] as number;

  return (
    <Input
      {...rest}
      onChange={(event: React.ChangeEvent<HTMLInputElement>, data: Record<string, unknown>) => {
        const { value } = data;
        if (onChangeCallBack) onChangeCallBack(value as number);
      }}
    >
      <input type="number" min="1" />
      {number > 1 && (
        <Button
          basic
          type="button"
          icon
          onClick={() => {
            if (onChangeCallBack) onChangeCallBack(number - 1);
            setFieldValue(name, number - 1);
          }}
          className="Form_numberInput___left"
        >
          <FontAwesomeIcon icon={['far', 'minus']} />
        </Button>
      )}

      <Button
        basic
        type="button"
        icon
        onClick={() => {
          if (onChangeCallBack) onChangeCallBack(number + 1);
          setFieldValue(name, number + 1);
        }}
      >
        <FontAwesomeIcon icon={['far', 'plus']} />
      </Button>
    </Input>
  );
};

interface LicenseFormProps {
  numberOfLicenses: number;
  setNumberOfLicenses: Dispatch<React.SetStateAction<number>>;
  priceData: {
    text: string;
    priceId: string;
    units: number;
    tax: number;
  };
  onSuccess: () => void;
}

const LicenseForm = ({
  numberOfLicenses,
  setNumberOfLicenses,
  priceData,
  onSuccess,
}: LicenseFormProps): JSX.Element => {
  const stripe = useStripe();
  const elements = useElements();
  const [formDisabled, setFormDisabled] = useState(false);
  const { currentCustomerId } = useSelector(selectCustomers);

  const initialValues = {
    numberOfLicenses,
    cardHolderName: '',
  };

  const validationSchema = Yup.object({
    numberOfLicenses: Yup.number().min(1).required('Required'),
    cardHolderName: Yup.string().required('Required'),
  });

  const buyReadyWhenLicenses = async (
    id: string,
    priceId: string,
    paymentMethodId: string
  ): Promise<string> => {
    try {
      const fetchedInfo = await buyLicenses(
        id,
        priceId,
        paymentMethodId,
        config.TAX_RATE,
        numberOfLicenses
      );
      if (
        fetchedInfo &&
        fetchedInfo.message &&
        (fetchedInfo.message.indexOf('error') !== -1 ||
          fetchedInfo.message.indexOf('insufficient') !== -1 ||
          fetchedInfo.message.indexOf('expired') !== -1 ||
          fetchedInfo.message.indexOf('incorrect') !== -1 ||
          fetchedInfo.message.indexOf('declined') !== -1)
      ) {
        throw fetchedInfo.message;
      }
      return fetchedInfo?.subscription?.id || '';
    } catch (err) {
      const error = err as ErrorType;
      const { message } = error.response.data;
      if (message) formErrorMessage(message);
      throw Error(message);
    }
  };

  const handleSubmit = async (values: any) => {
    // typeof initialValues
    const { cardholder_name: name } = values; // TODO cardHolderName??

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const cardElement = elements.getElement(CardElement);
    setFormDisabled(true);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      card: cardElement!,
      billing_details: {
        name,
      },
    });

    if (error) {
      formErrorMessage(error.message as string);
      setFormDisabled(false);
      return;
    }
    const paymentMethodId = paymentMethod?.id || '';
    if (currentCustomerId !== null) {
      try {
        await buyReadyWhenLicenses(currentCustomerId, priceData.priceId, paymentMethodId);
        onSuccess();
      } catch (errorMessage) {
        console.error(errorMessage);
      }
    }

    setFormDisabled(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values: typeof initialValues) => handleSubmit(values)}
    >
      <Form size="large" noValidate>
        <h1>Buy Licenses</h1>
        <NumberInput
          name="numberOfLicenses"
          label="Number of ReadyWhen Licenses"
          autoComplete="numberOfLicenses"
          disabled={formDisabled}
          errorPrompt
          action
          className="Form_numberInput"
          onChangeCallBack={setNumberOfLicenses}
        />

        <h1>Payment Details</h1>
        <Input
          name="cardHolderName"
          label="Name on card"
          autoComplete="cardHolderName"
          placeholder="Name on card"
          disabled={formDisabled}
          errorPrompt
        />

        <div className="field">
          <label htmlFor="card">Payment</label>

          <CardElement options={CARD_OPTIONS} />
        </div>

        <SubmitButton type="submit" primary>
          <FontAwesomeIcon icon={['far', 'lock']} /> Pay $
          {(((numberOfLicenses * priceData.units) / 100) * (1 + priceData.tax)).toFixed(2)}
        </SubmitButton>
      </Form>
    </Formik>
  );
};

export default LicenseForm;
