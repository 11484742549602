import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Container, Grid, Segment } from 'semantic-ui-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import LicenseForm from './LicenseForm';
import ReadyAdminTopNav from '../../components/ReadyAdminTopNav';
import config from '../../configs/config';
import ReadyAdminPaymentSummary from '../../components/ReadyAdminPaymentSummary';
import { selectCustomers } from '../customers/customersSlice';

const stripePromise = loadStripe(config.STRIPE_KEY);

const BuyLicensesPage = (): JSX.Element => {
  const navigate = useNavigate();
  const [numberOfLicenses, setNumberOfLicenses] = useState(1);
  const { customersById, currentCustomerId } = useSelector(selectCustomers);
  const [step, setStep] = useState(1);

  const priceData = {
    text: 'Year',
    priceId: config.plans.yearly,
    units:
      currentCustomerId && customersById[currentCustomerId].amount
        ? parseInt(customersById[currentCustomerId].amount, 10)
        : 5000,
    tax: 0.12,
  };

  return (
    <>
      <ReadyAdminTopNav closePath />

      <div className="Page_container">
        <Container style={{ marginTop: '40px' }}>
          {step === 1 ? (
            <Grid columns={2} relaxed="very">
              <Grid.Column width={10}>
                <Elements stripe={stripePromise}>
                  <LicenseForm
                    numberOfLicenses={numberOfLicenses}
                    setNumberOfLicenses={setNumberOfLicenses}
                    priceData={priceData}
                    onSuccess={() => setStep(2)}
                  />
                </Elements>
              </Grid.Column>
              <Grid.Column width={6}>
                <ReadyAdminPaymentSummary
                  numberOfLicenses={numberOfLicenses}
                  priceData={priceData}
                />
              </Grid.Column>
            </Grid>
          ) : (
            <Segment.Group horizontal>
              <Segment className="Segment_box___centered">
                <FontAwesomeIcon
                  icon={['far', 'check-circle']}
                  className="Header_graphic___center"
                />
                <h3>Your Payment was Successful</h3>
                <p>A receipt was sent to your email.</p>
                <Button primary content="License Keys" onClick={() => navigate('/licenses')} />
              </Segment>
              <ReadyAdminPaymentSummary numberOfLicenses={numberOfLicenses} priceData={priceData} />
            </Segment.Group>
          )}
        </Container>
      </div>
    </>
  );
};

export default BuyLicensesPage;
