/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/rootReducer';

export interface UserAttributes {
  sub: string;
  email: string;
  'custom:firm': string;
  'custom:address': string;
  'custom:suite': string;
  'custom:city': string;
  'custom:country': string;
  'custom:region': string;
  'custom:postcode': string;
}

export interface User {
  id: string;
  attributes: UserAttributes;
}

interface LoginState {
  isLoggedIn: boolean;
  currentUser: User | null;
  authState: string;
}

const initialState: LoginState = {
  isLoggedIn: false,
  currentUser: null,
  authState: '',
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login: (state) => {
      state.isLoggedIn = true;
    },
    setCurrentUser: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
    },
    logout: (state) => {
      state.authState = '';
      state.isLoggedIn = false;
      sessionStorage.clear();
      // reset the AWS amplify storage
      localStorage.clear();
    },
    forgotPass: (state) => {
      state.authState = 'forgotPass';
      state.isLoggedIn = false;
      sessionStorage.clear();
      localStorage.clear();
    },
    setAuthState: (state, action: PayloadAction<string>) => {
      state.authState = action.payload;
      if (action.payload === 'signedin') state.isLoggedIn = true;
    },
  },
});

export const { login, logout, setAuthState, setCurrentUser, forgotPass } = loginSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.login.value)`
export const selectLogin = (state: RootState): LoginState => state.login;

export default loginSlice.reducer;
