import { Input, Select } from 'formik-semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ClientDeleteFilePayload,
  ClientFilesPayload,
  FileInfo,
  FileUploadField,
  RegionDropdown,
  S3,
} from 'readywhen-ui-components';
import { FormGroup } from 'semantic-ui-react';
// eslint-disable-next-line import/no-cycle
import { countryOptions } from '../../..';
import config from '../../../configs/config';
import { selectCustomers } from '../../customers/customersSlice';
import { deleteClientFiles, selectClients, uploadClientFiles } from '../clientsSlice';
import { FieldsWithIndexProps } from '../interfaces';
import IntakeFormFields from './IntakeFormFields';

const { downloadFile } = S3;

export default function ClientPropertyField({
  data,
  formDisabled,
  index,
  formType,
}: FieldsWithIndexProps): JSX.Element {
  const { files } = useSelector(selectClients);
  const { currentCustomerId } = useSelector(selectCustomers);
  const dispatch = useDispatch();

  return (
    <>
      <Input
        name={`propertyTitle-${index}`}
        label="Property Title *"
        disabled={formDisabled}
        errorPrompt
      />
      <FormGroup widths="equal">
        <Input name={`address-${index}`} label="Address *" disabled={formDisabled} errorPrompt />
        <Input
          name={`suite-${index}`}
          label="Apt/Suite number"
          disabled={formDisabled}
          errorPrompt
        />
      </FormGroup>
      <FormGroup widths="equal">
        <Input name={`city-${index}`} label="City" disabled={formDisabled} errorPrompt />
        <Input
          name={`postcode-${index}`}
          label="Postal code/Zip Code"
          disabled={formDisabled}
          errorPrompt
        />
      </FormGroup>
      <FormGroup widths="equal">
        <Select
          name={`propertyCountry-${index}`}
          label="Country"
          selectOnBlur={false}
          options={countryOptions}
          disabled={formDisabled}
        />

        <RegionDropdown index={index} name={`propertyRegion-${index}`} disabled={formDisabled} />
      </FormGroup>
      <FileUploadField
        name={`propertyFiles-${index}`}
        label="Upload documents"
        required
        multiple
        type="file"
        accept="application/pdf, image/*"
        uploadId={data?.id}
        userId={data?.userId}
        files={files}
        currentCustomerId={currentCustomerId as string}
        uploadClientFiles={(payload: ClientFilesPayload) => {
          dispatch(uploadClientFiles(payload));
        }}
        downloadFile={(file: FileInfo) => {
          downloadFile('', config.s3.BUCKET, file?.key as string, '', file?.fileName);
        }}
        deleteClientFiles={(payload: ClientDeleteFilePayload) => {
          dispatch(deleteClientFiles(payload));
        }}
      />

      <IntakeFormFields
        formDisabled={formDisabled}
        index={index}
        formType={formType}
        isEdit={data !== null}
      />
    </>
  );
}
ClientPropertyField.defaultProps = {
  formType: null,
};
