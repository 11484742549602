/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { Header, Button, FormGroup, Modal, Transition, Divider } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-semantic-toasts';
import { staffSelectors, editStaff, addStaff } from './staffSlice';
import { selectCustomers } from '../customers/customersSlice';
import { formatPhoneNumber } from '../../utils/stringUtils';
import { AppDispatch } from '../../app/store';

interface FormProps {
  id?: string | null;
  closeModalCallback?: () => void;
  addStaffModalOpen?: boolean;
}

export default function AddStaffPage({
  id,
  closeModalCallback,
  addStaffModalOpen,
}: FormProps): JSX.Element {
  const [formDisabled, setFormDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState<number>(1);
  const dispatch: AppDispatch = useDispatch();
  const staff = useSelector((state) => staffSelectors.selectById(state, id as string));
  const { currentCustomerId } = useSelector(selectCustomers);

  const initialValues = {
    firstName: staff?.firstName,
    lastName: staff?.lastName,
    email: staff?.email,
    phoneNumber: staff?.phoneNumber,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    phoneNumber: id
      ? Yup.string()
          .matches(/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/i, 'Invalid phone number')
          .min(8, 'Must be at least 8 digits')
          .max(15, 'Must be 15 digits or less')
      : Yup.string()
          .matches(/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/i, 'Invalid phone number')
          .min(8, 'Must be at least 8 digits')
          .max(15, 'Must be 15 digits or less')
          .required('Required'),
  });

  const closeModal = () => {
    setFormDisabled(false);
    if (closeModalCallback) closeModalCallback();
  };

  function formErrorMessage(payload: string) {
    toast({
      type: 'error',
      icon: 'exclamation circle',
      title: id ? 'Staff Edit Error' : 'Staff Add Error',
      description: payload,
      animation: 'drop',
      time: 10000,
    });
  }

  const handleSubmit = async (values: typeof initialValues) => {
    const { firstName, lastName, email, phoneNumber } = values;

    setFormDisabled(true);

    if (currentCustomerId) {
      if (id) {
        const resultAction = await dispatch(
          editStaff({
            firstName: firstName as string,
            lastName: lastName as string,
            email: email as string,
            phoneNumber: formatPhoneNumber(phoneNumber as string, true),
            id,
            customerId: currentCustomerId,
          })
        );

        if (editStaff.fulfilled.match(resultAction)) {
          setModalOpen(2);
        }
        if (editStaff.rejected.match(resultAction)) {
          formErrorMessage(resultAction.payload as string);
        }
      } else {
        const resultAction = await dispatch(
          addStaff({
            firstName: firstName as string,
            lastName: lastName as string,
            email: email as string,
            phoneNumber: formatPhoneNumber(phoneNumber as string, true),
            customerId: currentCustomerId,
          })
        );

        if (addStaff.fulfilled.match(resultAction)) {
          setModalOpen(2);
        }
        if (addStaff.rejected.match(resultAction)) {
          formErrorMessage(resultAction.payload as string);
        }
      }
    }

    setFormDisabled(false);
  };

  return (
    <>
      <Transition visible={modalOpen === 1} animation="scale" duration={400}>
        <Modal closeIcon open={addStaffModalOpen} onClose={() => closeModal()}>
          <Modal.Description>
            <h1 style={{ margin: '5px 0 20px 30px' }}>{id ? 'Edit' : 'Add Staff'}</h1>
            <Divider />
            <div id="Form_container">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={handleSubmit}
              >
                <Form size="large" noValidate>
                  <FormGroup widths="equal">
                    <Input
                      name="firstName"
                      label="First Name *"
                      disabled={formDisabled}
                      errorPrompt
                    />
                    <Input
                      name="lastName"
                      label="Last Name *"
                      disabled={formDisabled}
                      errorPrompt
                    />
                  </FormGroup>

                  <FormGroup widths="equal">
                    <Input
                      name="email"
                      label={id ? 'Email' : 'Email *'}
                      type="email"
                      disabled={id ? true : formDisabled}
                      errorPrompt
                    />
                    <Input
                      name="phoneNumber"
                      label="Phone Number *"
                      placeholder="10-digit mobile number"
                      type="text"
                      disabled={id ? true : formDisabled}
                      errorPrompt
                    />
                  </FormGroup>
                  <SubmitButton
                    primary
                    className="Button__Add___centered"
                    style={{ margin: '20px 0 -10px' }}
                  >
                    Save
                  </SubmitButton>
                </Form>
              </Formik>
            </div>
          </Modal.Description>
        </Modal>
      </Transition>

      <Transition visible={modalOpen === 2} animation="scale" duration={400}>
        <Modal
          closeIcon
          open={modalOpen === 2}
          onClose={() => closeModal()}
          onOpen={() => setModalOpen(2)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          className="Modal___centered"
        >
          <Header icon>
            <FontAwesomeIcon icon={['far', 'check-circle']} className="Header_graphic___center" />
          </Header>
          <Modal.Content>
            <Modal.Description>
              <p style={{ textAlign: 'center', fontSize: 18 }}>
                {id ? 'Staff was edited Successfully' : 'Staff is added Successfully'}
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <br />
            <Button
              content="Continue"
              onClick={() => {
                closeModal();
              }}
              className="Form_button"
              primary
            />
          </Modal.Actions>
        </Modal>
      </Transition>
    </>
  );
}

AddStaffPage.defaultProps = {
  id: null,
  closeModalCallback: null,
  addStaffModalOpen: null,
};
