import React from 'react';
import { TextArea } from 'formik-semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { FileUploadField, FileInfo, ClientFilePayload, S3 } from 'readywhen-ui-components';
import { replaceClientFiles, selectClients } from '../clientsSlice';
import { selectCustomers } from '../../customers/customersSlice';
import config from '../../../configs/config';
import { FieldsProps } from '../interfaces';

const { downloadFile } = S3;

export default function ClientHealthFields({ data, formDisabled }: FieldsProps): JSX.Element {
  const { files } = useSelector(selectClients);
  const { currentCustomerId } = useSelector(selectCustomers);
  const dispatch = useDispatch();

  return (
    <>
      <FileUploadField
        name="healthFile"
        label={<strong>Client&apos;s Health Directive</strong>}
        required
        type="file"
        accept="application/pdf, image/*"
        uploadId={data?.id}
        userId={data?.userId}
        files={files}
        currentCustomerId={currentCustomerId as string}
        replaceClientFiles={(payload: ClientFilePayload) => {
          dispatch(replaceClientFiles(payload));
        }}
        downloadFile={(file: FileInfo) => {
          downloadFile('', config.s3.BUCKET, file?.key as string, '', file?.fileName);
        }}
      />

      <TextArea
        name="healthPhysicalLocation"
        label="Physical location of files*"
        placeholder="You can find this document in the top drawer of the filing cabinet."
        disabled={formDisabled}
        errorPrompt
      />
    </>
  );
}
