import { Container, Grid } from 'semantic-ui-react';
import ReadyAdminSideNav from './ReadyAdminSideNav';
import ReadyAdminTopNav from './ReadyAdminTopNav';

interface ReadyAdminNavContainerProps {
  children: React.ReactChild | React.ReactChild[] | string;
  rightChildren: React.ReactChild | React.ReactChild[] | string;
  title: string;
}

const ReadyAdminNavContainer = ({
  children,
  title,
  rightChildren,
}: ReadyAdminNavContainerProps) => {
  return (
    <>
      <ReadyAdminTopNav />
      <Grid divided>
        <Grid.Column width={3}>
          <ReadyAdminSideNav />
        </Grid.Column>
        <Grid.Column width={13} className="Page_background">
          <div className="Page_container">
            <Container style={{ marginTop: '20px' }}>
              <Grid>
                <Grid.Column width={10}>
                  <h1>{title}</h1>
                </Grid.Column>
                <Grid.Column width={6}>{rightChildren}</Grid.Column>
              </Grid>
              {children}
            </Container>
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default ReadyAdminNavContainer;
