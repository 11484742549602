/* eslint-disable react/destructuring-assignment */
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectCustomers } from '../features/customers/customersSlice';
import { clientsSelectors, fetchClients } from '../features/clients/clientsSlice';
import EditClientModal from '../features/clients/modals/EditClientModal';
// eslint-disable-next-line import/no-cycle
import { CountryProps, regionOptions } from '..';
import { AppDispatch } from '../app/store';

interface ClientIdProps {
  clientId: string;
}

const ReadyAdminDashboardSideNav = ({ clientId }: ClientIdProps): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(0);
  const { currentCustomerId } = useSelector(selectCustomers);
  const client = useSelector((state) => clientsSelectors.selectById(state, clientId));
  const dispatch: AppDispatch = useDispatch();
  const closeModal = () => {
    setModalOpen(0);
    if (currentCustomerId) dispatch(fetchClients({ customerId: currentCustomerId }));
  };

  const newRegion = useMemo(
    () =>
      regionOptions.reduce(
        (region: string, regionData: CountryProps) =>
          client && regionData.value === client?.region ? regionData.text : region,
        ''
      ),
    [client]
  );

  return (
    <>
      {client && currentCustomerId && (
        <>
          <h5>
            Client Info{' '}
            <FontAwesomeIcon
              icon={['far', 'edit']}
              onClick={() => setModalOpen(1)}
              className="EditIcon_color___teal"
            />
          </h5>
          <div className="Sidebar_block">
            <div className="textRow">
              <FontAwesomeIcon icon={['far', 'user']} style={{ marginRight: '18px' }} />
              <div className="textBlock">
                <span style={{ fontWeight: 500 }}>
                  {client.firstName} {client.lastName}
                </span>
                <div className="textBlock">{newRegion}</div>
              </div>
            </div>

            {client.phoneNumber && (
              <div className="textRow">
                <FontAwesomeIcon icon={['far', 'phone']} style={{ marginRight: '18px' }} />
                <div className="textBlock">{client.phoneNumber}</div>
              </div>
            )}
            <div className="textRow">
              <FontAwesomeIcon icon={['far', 'envelope']} style={{ marginRight: '18px' }} />
              <div className="textBlock">{client.email}</div>
            </div>
          </div>
        </>
      )}
      {modalOpen === 1 && (
        <EditClientModal
          clientId={clientId}
          modalOpenProp={modalOpen}
          closeModalCallback={closeModal}
        />
      )}
    </>
  );
};
export default ReadyAdminDashboardSideNav;
