import { useMemo, Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Button,
  CheckboxProps,
  Divider,
  FormGroup,
  Grid,
  Icon,
  Popup,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import includes from 'lodash/includes';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import {
  CopyFields,
  DateWithCopyInput,
  EmailInput,
  MoneyInput,
  PhoneInput,
  RadioGroupInput,
  TextAreaInput,
  TextInput,
} from 'readywhen-ui-components';
import { RootState } from '../../../app/rootReducer';
import { selectCustomers } from '../../customers/customersSlice';
import { updateForm } from './formsSlice';
import ReadyAdminTopNav from '../../../components/ReadyAdminTopNav';
import ReadyFormHeader from '../../../components/ReadyFormHeader';

import { Document, Form as FormInterface, PatchFormPayload } from '../../../api/interfaces';
import { AppDispatch } from '../../../app/store';
// eslint-disable-next-line import/no-cycle
import { InternalNotesTransfer } from './InternalNotesTransfer';
import { getDiff, isNotification, makeNamesArr, removeEmptyAndIgnorePhone } from './formsUtils';
import { transferInternalNotesKeys, formFileTypes } from '../../../constants/forms';
import ThankYouModal from './ThankYouModal';
import HeaderRemoveButton from './HeaderRemoveButton';
import { useFormDataFetch } from './hooks';
import { formErrorMessage } from '../formMessage';

interface OwnerProps {
  index: number;
  removeIndexField: (index: number, fieldName: string) => void;
  formDisabled: boolean;
  formData: Record<string, unknown>;
  handleOwnerChange: (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
}

const TenantTypeLabel = (
  <>
    How would you like to own the property?{' '}
    <Popup
      trigger={<Icon name="question" color="teal" size="small" circular />}
      content={
        <>
          <p>
            <strong>Joint Tenants</strong>
            <br />
            Parties have an equal undivided interest with a right of survivorship. The right of
            survivorship simply means that when an owner becomes deceased, their ownership passes to
            the survivor, regardless of any terms in a Will. This form of ownership is frequently
            used when family members (couples) acquire property.
          </p>
          <p>
            <strong>Tenants In Common</strong>
            <br />
            There is no right of survivorship. Each party on title may leave their interest to
            whomever they choose in their Will. This form of ownership is frequently used when
            business partners (not family members) acquire property. If you wish to go on title as
            Tenants In Common then we need a % for each person going on title (50 and 50 OR other,
            if other please specify each person’s share) Total must equal 100.
          </p>
        </>
      }
      position="right center"
      style={{ minHeight: '100px', height: 'auto' }}
    />
  </>
);

interface MortgagesProps {
  index: number;
  removeIndexField: (index: number, fieldName: string) => void;
  formDisabled: boolean;
}

const OwnerInfoFields = ({
  index,
  removeIndexField,
  formDisabled,
  formData,
  handleOwnerChange,
}: OwnerProps) => {
  return (
    <Fragment key={`owner-${index}`}>
      <HeaderRemoveButton index={index} removeIndexField={removeIndexField} fieldName="owner" />

      <FormGroup widths="equal">
        <TextInput
          name={`owners[${index}].firstName`}
          label="First Name *"
          disabled={formDisabled}
        />
        <TextInput
          name={`owners[${index}].middleName`}
          label="Middle Name"
          disabled={formDisabled}
        />
        <TextInput name={`owners[${index}].lastName`} label="Last Name *" disabled={formDisabled} />
      </FormGroup>

      <FormGroup className="Form_notes">
        <span className="Name_notes">
          Please enter full legal name as per your government-issued identification (ie Passport /
          Driver&apos;s License).
        </span>
        <CopyFields
          label="Copy Full Name"
          fields={[
            `owners[${index}].firstName`,
            `owners[${index}].middleName`,
            `owners[${index}].lastName`,
          ]}
        />
      </FormGroup>

      <FormGroup widths="equal">
        <EmailInput name={`owners[${index}].email`} label="Email *" disabled={formDisabled} />

        <PhoneInput
          name={`owners[${index}].phone`}
          label="Phone Number"
          disabled={formDisabled}
          defaultValue={(formData.owners as Record<string, any>[])[index]?.phone.value}
        />
      </FormGroup>

      <FormGroup widths="equal">
        <DateWithCopyInput
          name={`owners[${index}].dob`}
          label="Date of Birth"
          disabled={formDisabled}
        />

        <TextInput
          name={`owners[${index}].sin`}
          label="Social Insurance Number (SIN)"
          placeholder="XXX-XXX-XXX or 9 digits"
          disabled={formDisabled}
        />
      </FormGroup>

      <TextInput
        name={`owners[${index}].occupation`}
        label="Occupation"
        disabled={formDisabled}
        className="Form_input___halfWidth"
      />

      <RadioGroupInput
        name={`owners[${index}].citizenResident`}
        label="Are you a Canadian Citizen / Permanent Resident?"
        value={(formData.owners as Record<string, string>[])[index]?.citizenResident}
        onChange={handleOwnerChange}
      />

      {(formData.owners as Record<string, string>[])[index].citizenResident === '0' && (
        <TextInput
          name={`owners[${index}].residence`}
          label="Country of residence"
          disabled={formDisabled}
          className="Form_input___halfWidth"
        />
      )}

      {index >= 1 && (
        <RadioGroupInput
          name={`owners[${index}].tenantsType`}
          label={TenantTypeLabel}
          value={(formData.owners as Record<string, string>[])[index]?.tenantsType}
          options={[
            { value: 'jointTenants', label: 'Joint Tenants' },
            { value: 'tenantsInCommon', label: 'Tenants in Common' },
          ]}
          onChange={handleOwnerChange}
        />
      )}
    </Fragment>
  );
};

const MortgagesFields = ({ index, removeIndexField, formDisabled }: MortgagesProps) => {
  return (
    <Fragment key={`mortgage-${index}`}>
      <HeaderRemoveButton index={index} removeIndexField={removeIndexField} fieldName="mortgage" />

      <FormGroup widths="equal">
        <TextInput name={`mortgages[${index}].bank`} label="Name of Bank" disabled={formDisabled} />
        <TextInput
          name={`mortgages[${index}].referenceNumber`}
          label="Mortgage Reference Number"
          disabled={formDisabled}
        />
      </FormGroup>
      <FormGroup widths="equal">
        <TextInput
          name={`mortgages[${index}].branchAddress`}
          label="Branch Address"
          disabled={formDisabled}
        />
      </FormGroup>
    </Fragment>
  );
};

const AccountFields = ({ index, removeIndexField, formDisabled }: MortgagesProps) => {
  return (
    <Fragment key={`account-${index}`}>
      <HeaderRemoveButton index={index} removeIndexField={removeIndexField} fieldName="account" />
      <TextInput
        name={`accounts[${index}].accountName`}
        label="Account Name"
        disabled={formDisabled}
      />
      <FormGroup widths="equal">
        <TextInput
          name={`accounts[${index}].accountNumber`}
          label="Account Number"
          disabled={formDisabled}
        />
        <MoneyInput
          name={`accounts[${index}].accountBalance`}
          label="Account Balance"
          disabled={formDisabled}
        />
      </FormGroup>
    </Fragment>
  );
};

const FormTransfer = (): JSX.Element => {
  const { currentCustomerId } = useSelector(selectCustomers);
  const { clientId, id, intakeFormId } = useParams();
  const formikRef = useRef<FormikProps<typeof initialData>>(null);
  const { documents } = useSelector((state: RootState) => state.clients);
  const dispatch: AppDispatch = useDispatch();
  const { loading, entities } = useSelector((state: RootState) => state.forms);
  const [modalOpen, setModalOpen] = useState(0);
  const [formEdited, setFormEdited] = useState(false);
  const [editedFields, setEditedFields] = useState<string[]>([]);

  const displayedProperty = useMemo(
    () =>
      documents && documents.property
        ? documents.property.find((property: Document) => property.id === id) ||
          (documents.property.find((property: Document) => property.assetId === id) as Document)
        : {
            metadata: {
              name: '',
              address: '',
              suite: '',
              city: '',
              postcode: '',
              completionDate: '',
            },
          },
    [documents, id]
  );

  const formId = useMemo(() => {
    if (intakeFormId) {
      return intakeFormId;
    }

    return displayedProperty?.forms?.length ? displayedProperty.forms[0].id : { id: '' };
  }, [displayedProperty, intakeFormId]);

  const form: FormInterface = useMemo(() => entities[formId], [entities, formId]);

  const { type: formType } = form ?? {};
  const fileNumber = useMemo(() => {
    return form?.metadata?.fileNumber || form?.metadata?.currentValues?.fileNumber;
  }, [form]);

  useFormDataFetch({ currentCustomerId, dispatch, clientId, intakeFormId, formId, id });

  const {
    metadata: { name, address, suite, city, postcode, completionDate },
  } = displayedProperty ?? { metadata: {} };

  const title: string =
    address && postcode
      ? `${suite ?? ''} ${address}, ${city ?? ''}, ${postcode.toUpperCase()}`
      : name;

  const [formDisabled, setFormDisabled] = useState(false);

  const emptyOwner = useMemo(() => {
    return {
      citizenResident: '',
      email: '',
      firstName: '',
      lastName: '',
      middleName: '',
      phone: {
        value: '',
        country_code: 'CA',
      },
      occupation: '',
      dob: '',
      sin: '',
      residence: '',
      tenantsType: '',
    };
  }, []);

  const emptyMortgage = useMemo(() => {
    return {
      bank: '',
      referenceNumber: '',
      branchAddress: '',
    };
  }, []);

  const emptyAccount = useMemo(() => {
    return {
      accountName: '',
      accountNumber: '',
      accountBalance: '',
    };
  }, []);

  const initialData = useMemo(() => {
    return {
      fileNumber,
      owners: [emptyOwner],
      mortgages: [emptyMortgage],
      accounts: [emptyAccount],
      buyerResiding: '',
      mailingAddress: '',
      otherAddress: '',
      mortgagePhone: {
        value: '',
        country_code: 'CA',
      },
      mortgageEmail: '',
      strataName: '',
      strataPhone: {
        value: '',
        country_code: 'CA',
      },
      bank: '',
      mortgageBroker: '',
      insuranceName: '',
      insuranceAddress: '',
      insuranceBrokerName: '',
      insuranceBrokerEmail: '',
      insuranceBrokerPhone: {
        value: '',
        country_code: 'CA',
      },
      furtherInfo: '',
      referralInfo: '',
      ownerRelationship: '',
      ownerMoneyOwed: '',
      ownerMoneyOwedAmount: '',
      mortgage: '',
      existingMortgage: '',
      otherDebts: '',
      otherDebtsInfo: '',
      isPropertyTransfer: '',
      propertyResidents: '',
      propertyResidentsForHowLong: '',
      propertySize: '',
      propertyValue: '',
      isPropertyRentedOut: '',
      strata: '',
      additionalInfo: '',
      // INTERNAL NOTES
      titleSearch: false,
      taxSearch: false,
      payoutOne: false,
      payoutTwo: false,
      fireInsurance: false,
      strataForms: false,
      titleInsurance: false,
      requestForFunds: false,
      finalReport: false,
      releaseParticulars: false,
      stc: false,
      other: false,
      otherText: '',
      strataMaintenanceFee: '',
      outstandingFees: '',
      moveInOutFees: '',
      transactionUndertakings: '',
      transactionUndertakingsDetails: '',
      holdbacks: '',
      holdbacksDetails: '',
      internalNotes: '',
    };
  }, [emptyOwner, emptyMortgage, emptyAccount, fileNumber]);

  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    if (form) {
      const { metadata } = form;

      if (metadata.currentValues === undefined) {
        const formatted: any = { ...metadata };
        Object.keys(metadata).forEach((key) => {
          if (key.indexOf('Phone') > -1) {
            formatted[`${key}_ignore_phone`] = metadata[key]?.value;
            formatted[`${key}_ignore_country_code`] = metadata[key]?.country_code;
          }
        });
        setFormData({ ...initialData, ...formatted });
      } else {
        setFormData({ ...initialData, ...metadata.currentValues });
        if (metadata?.edits.origin === 'cp' && metadata?.edits.isHighlighted)
          setEditedFields(makeNamesArr(metadata.edits.changes));
      }
    }
  }, [form, initialData]);

  useEffect(() => {
    if (!isEmpty(editedFields)) {
      editedFields.forEach((key) => {
        const label = document.querySelector(`label[for='${key}']`);
        label?.classList.add('Form_label___highlighted');
      });
    }
  }, [editedFields]);

  const buildFilesSchema = () => {
    let ownersSchema;
    const phoneObj = Yup.object().shape({
      value: Yup.string().matches(/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/i, 'Invalid phone number'),
      country_code: Yup.string(),
    });

    const keys = Object.keys(formData);
    if (keys.length) {
      const ownersArray = keys.filter((s) => s.includes('owners'));
      if (ownersArray.length) {
        ownersSchema = Yup.object().shape({
          firstName: Yup.string().required('Required'),
          lastName: Yup.string().required('Required'),
          email: Yup.string().email('Invalid email address').required('Required'),
          occupation: Yup.string(),
          dob: Yup.date().max(new Date(), 'Incorrect date'),
          sin: Yup.string().matches(/^(\d{3}-\d{3}-\d{3})|(\d{9})$/, 'Invalid SIN format'),
          citizenResident: Yup.string().oneOf(['1', '0']),
          residence: Yup.string(),
          phone: phoneObj,
        });
      }
    }

    const commonSchema = {
      mortgagePhone: phoneObj,
      strataPhone: phoneObj,
      insuranceBrokerPhone: phoneObj,
      mortgageEmail: Yup.string().email('Invalid email address'),
      insuranceBrokerEmail: Yup.string().email('Invalid email address'),
    };
    return Yup.object().shape({
      ...(ownersSchema && { owners: Yup.array().of(ownersSchema) }),
      ...commonSchema,
    });
  };

  const validationSchema = buildFilesSchema();

  const updateFormValues = () => {
    if (formikRef?.current) {
      const { values } = formikRef.current;

      if (formData !== values) setFormData(values);
    }
  };

  const handleSubmit = async (values: typeof initialData) => {
    setFormDisabled(true);

    const formattedValues = values;

    updateFormValues();

    const diffObj = removeEmptyAndIgnorePhone(
      getDiff(
        formattedValues,
        form.metadata.currentValues !== undefined ? form.metadata.currentValues : form.metadata
      )
    );

    const send = !!clientId && isNotification(transferInternalNotesKeys, diffObj);

    setFormEdited(send);

    const multipleUpdatesDiff =
      form.metadata.edits !== undefined
        ? form.metadata?.edits.origin === 'ap' &&
          merge(cloneDeep(diffObj), form?.metadata.edits.changes)
        : {};

    const newMetadata = {
      fileNumber: values.fileNumber,
      currentValues: formattedValues,
      edits: {
        origin: 'ap',
        isHighlighted: true,
        changes:
          form.metadata.edits !== undefined && form.metadata.edits.origin === 'ap'
            ? multipleUpdatesDiff
            : diffObj,
      },
    };

    const payload = {
      customerId: currentCustomerId as string,
      clientId,
      formId,
      metadata: newMetadata,
      status: 'in progress',
      sendNotification: send,
    };

    const resultAction = await dispatch(updateForm(payload as PatchFormPayload));
    setFormDisabled(false);
    if (updateForm.rejected.match(resultAction)) {
      const errorMessage = (resultAction.payload as string) || 'Error has occurred';
      formErrorMessage(errorMessage.replace('fileNumber', 'file number'));
    } else {
      setModalOpen(2);
    }
  };

  const handleOwnerChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const { name: formKey, value } = data;
    const key = (formKey as string).split('.').pop() as string;
    const index = (formKey as string).split('[')[1].split(']')[0] as unknown as number;
    if (formikRef?.current) {
      const { values, setValues } = formikRef.current;
      const newValues = cloneDeep(values);
      (newValues.owners as Record<string, unknown>[])[index][key] = value;
      setValues(newValues);
      setFormData(newValues);
    }
  };

  const removeIndexField = (index: number, fieldName: string) => {
    if (formikRef?.current) {
      const { values } = formikRef.current;
      const clonedData = clone((formData as any)[fieldName]);
      clonedData.splice(index, 1);
      setFormData({ ...formData, ...values, [fieldName]: [...clonedData] });
    }
  };

  const handleRadioCheckboxChange = (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    const { name: formKey, type, checked, value } = data;

    const newVal = type === 'checkbox' ? (checked as boolean) : (value as string);
    if (formikRef?.current) {
      const { values, setValues } = formikRef.current;
      const newValues = cloneDeep(values);
      (newValues as Record<string, unknown>)[formKey as string] = newVal;
      setValues(newValues);
      setFormData(newValues);
    }
  };

  const cancel = () => {
    setModalOpen(0);
  };

  return (
    <>
      <ReadyAdminTopNav
        closePath
        callback={() => {
          setModalOpen(1);
        }}
      />
      <div className="Page_mainContent___full">
        {loading ? (
          <Dimmer
            active
            inverted
            style={{
              marginTop: '60px',
            }}
          >
            <Loader inverted>Loading</Loader>
          </Dimmer>
        ) : (
          <Grid>
            <ReadyFormHeader
              formType={formFileTypes[formType ?? '']}
              clientId={clientId}
              title={title}
              completionDate={completionDate}
            />
            <Grid.Row>
              <Grid.Column>
                <Formik<typeof initialData>
                  enableReinitialize
                  innerRef={formikRef}
                  initialValues={formData}
                  validationSchema={validationSchema}
                  validateOnChange={false}
                  validateOnBlur={false}
                  onSubmit={handleSubmit}
                >
                  {(props: FormikProps<typeof initialData>) => (
                    <Form
                      size="large"
                      noValidate
                      onBlur={(e) => {
                        const { target } = e;

                        if (target.type !== 'checkbox') {
                          if (target.type === 'tel') {
                            if (target?.name?.indexOf('[') > -1) {
                              const parent = target?.name?.split('[')[0];
                              formikRef.current?.validateField(parent);
                              const regex = new RegExp(/\[(.*?)\]/g);
                              const regMatch = regex.exec(target?.name || '');
                              const index = parseInt(regMatch !== null ? regMatch[1] : '0', 10);
                              const child = target?.name?.split('.')[1];
                              const newKey = child?.split('_')[0];

                              const countryCode =
                                (formikRef.current &&
                                  (formikRef.current.values as any)[newKey]?.country_code) ||
                                'CA';

                              const existingArray: Record<string, unknown>[] = [
                                ...(formData[parent as keyof typeof initialData] as []),
                              ];
                              const newChild = {
                                ...existingArray[index],
                                [child]: target.value,
                                [newKey]: { country_code: countryCode, value: target.value },
                              };
                              existingArray.splice(index, 1, newChild);
                              props.validateForm();
                              setFormData({ ...formData, [parent]: existingArray });
                              return;
                            }

                            const newKey = target?.name?.split('_')[0];
                            const countryCode =
                              (formikRef.current &&
                                (formikRef.current.values as any)[newKey].country_code) ||
                              'CA';

                            setFormData({
                              ...formData,
                              [newKey]: { country_code: countryCode, value: target.value },
                              [target.name]: target.value,
                            });
                            return;
                          }

                          if (target?.name?.indexOf('[') > -1) {
                            const parent = target?.name?.split('[')[0];
                            const regex = new RegExp(/\[(.*?)\]/g);
                            const regMatch = regex.exec(target?.name || '');
                            const index = parseInt(regMatch !== null ? regMatch[1] : '0', 10);
                            const child = target?.name?.split('.')[1];
                            const existingArray: Record<string, unknown>[] = [
                              ...(formData[parent as keyof typeof initialData] as []),
                            ];
                            const newChild = { ...existingArray[index], [child]: target.value };

                            existingArray.splice(index, 1, newChild);
                            setFormData({ ...formData, [parent]: existingArray });
                            return;
                          }
                        }
                        if (target.type && !includes(['checkbox', 'submit'], target.type)) {
                          setFormData({ ...formData, [target.name]: target.value });
                        }
                      }}
                    >
                      <TextInput name="fileNumber" label="File Number" />
                      <Divider style={{ margin: '40px 0' }} />
                      {formData.owners.length &&
                        formData.owners.map((a, index) => (
                          <OwnerInfoFields
                            index={index}
                            removeIndexField={removeIndexField}
                            formDisabled={formDisabled}
                            formData={formData}
                            handleOwnerChange={handleOwnerChange}
                            key={a.firstName}
                          />
                        ))}

                      <Button
                        type="button"
                        primary
                        className="Button_shape___sharp"
                        onClick={() => {
                          if (formikRef?.current) {
                            const { values } = formikRef.current;
                            setFormData({ ...values, owners: [...values.owners, emptyOwner] });
                          }
                        }}
                      >
                        Add another Owner
                      </Button>
                      <br />
                      <br />
                      <Divider />

                      <TextInput
                        name="ownerRelationship"
                        className="Form_input___halfWidth"
                        label="What is the relationship to the new owner?"
                        disabled={formDisabled}
                      />

                      <Divider />

                      <RadioGroupInput
                        name="ownerMoneyOwed"
                        label="Are you paying any money to the existing owner?"
                        value={formData.ownerMoneyOwed}
                        onChange={handleRadioCheckboxChange}
                      />
                      {formData.ownerMoneyOwed === '1' && (
                        <MoneyInput
                          name="ownerMoneyOwedAmount"
                          className="Form_input___halfWidth"
                          label="Amount"
                          disabled={formDisabled}
                        />
                      )}

                      <Divider />

                      <RadioGroupInput
                        name="buyerResiding"
                        label="Will all buyer(s) be residing at the property being transferred?"
                        value={formData.buyerResiding}
                        onChange={handleRadioCheckboxChange}
                      />
                      {formData.buyerResiding === '0' && (
                        <TextInput
                          name="otherAddress"
                          label="Provide addresses with postal code for any purchaser(s) not residing at the property being purchased:"
                          disabled={formDisabled}
                        />
                      )}
                      <Divider />

                      <TextInput
                        name="mailingAddress"
                        label="Mailing Address"
                        disabled={formDisabled}
                      />

                      <Divider />

                      <RadioGroupInput
                        name="mortgage"
                        label="Are you obtaining a Mortgage?"
                        value={formData.mortgage}
                        onChange={handleRadioCheckboxChange}
                      />
                      {formData.mortgage === '1' && (
                        <>
                          <FormGroup widths="equal">
                            <TextInput name="bank" label="Name of Bank" disabled={formDisabled} />
                            <TextInput
                              name="mortgageBroker"
                              label="Name of Mortgage Broker"
                              disabled={formDisabled}
                            />
                          </FormGroup>
                          <FormGroup widths="equal">
                            <PhoneInput
                              name="mortgagePhone"
                              label="Broker’s Phone Number"
                              disabled={formDisabled}
                            />
                            <EmailInput
                              name="mortgageEmail"
                              label="Broker’s Email Address"
                              disabled={formDisabled}
                            />
                          </FormGroup>
                        </>
                      )}
                      <Divider />
                      <RadioGroupInput
                        name="existingMortgage"
                        label="Do you have an existing Mortgage?"
                        value={formData.existingMortgage}
                        onChange={handleRadioCheckboxChange}
                      />
                      {formData.existingMortgage === '1' && (
                        <>
                          {formData.mortgages.map((a, index) => (
                            <MortgagesFields
                              index={index}
                              removeIndexField={removeIndexField}
                              formDisabled={formDisabled}
                              key={a.referenceNumber}
                            />
                          ))}

                          <Button
                            type="button"
                            primary
                            className="Button_shape___sharp"
                            onClick={() => {
                              if (formikRef?.current) {
                                const { values } = formikRef.current;
                                setFormData({
                                  ...values,
                                  mortgages: [...values.mortgages, emptyMortgage],
                                });
                              }
                            }}
                          >
                            Add another Mortgage
                          </Button>
                        </>
                      )}
                      <Divider />
                      <RadioGroupInput
                        name="otherDebts"
                        label="Any other debts to be paid from new Mortgage?"
                        value={formData.otherDebts}
                        onChange={handleRadioCheckboxChange}
                      />
                      {formData.otherDebts === '1' && (
                        <>
                          {formData.accounts.map((a: any, index: any) => (
                            <AccountFields
                              index={index}
                              removeIndexField={removeIndexField}
                              formDisabled={formDisabled}
                              key={a.accountNumber}
                            />
                          ))}
                          <TextAreaInput
                            rows={2}
                            name="otherDebtsInfo"
                            label="Provide details of other debts:"
                            disabled={formDisabled}
                          />
                          <Button
                            type="button"
                            primary
                            className="Button_shape___sharp"
                            onClick={() => {
                              if (formikRef?.current) {
                                const { values } = formikRef.current;
                                setFormData({
                                  ...values,
                                  accounts: [...values.accounts, emptyAccount],
                                });
                              }
                            }}
                          >
                            Add another Account
                          </Button>
                        </>
                      )}
                      <Divider />
                      <RadioGroupInput
                        name="isPropertyTransfer"
                        label="Is this a Property Transfer (adding or taking off someone from Title)?"
                        value={formData.isPropertyTransfer}
                        onChange={handleRadioCheckboxChange}
                      />
                      {formData.isPropertyTransfer === '1' && (
                        <>
                          <FormGroup widths="equal">
                            <TextInput
                              name="propertyResidents"
                              label="Who Resides?"
                              disabled={formDisabled}
                            />
                            <TextInput
                              name="propertyResidentsForHowLong"
                              label="How long?"
                              disabled={formDisabled}
                            />
                          </FormGroup>

                          <FormGroup widths="equal">
                            <TextInput
                              name="propertySize"
                              label="Size of property"
                              disabled={formDisabled}
                            />
                            <MoneyInput
                              name="propertyValue"
                              label="Assessed Value of Property"
                              disabled={formDisabled}
                            />
                          </FormGroup>
                          <RadioGroupInput
                            name="isPropertyRentedOut"
                            label="Is any portion of the property being rented out?"
                            value={formData.isPropertyRentedOut}
                            onChange={handleRadioCheckboxChange}
                          />
                        </>
                      )}
                      <Divider />
                      <RadioGroupInput
                        name="strata"
                        label="Is there a Strata Management Company?"
                        value={formData.strata}
                        onChange={handleRadioCheckboxChange}
                      />
                      {formData.strata === '1' && (
                        <>
                          <FormGroup widths="equal">
                            <TextInput
                              name="strataName"
                              label="Strata Company Name"
                              disabled={formDisabled}
                            />
                            <PhoneInput
                              name="strataPhone"
                              label="Strata Company Phone Number"
                              disabled={formDisabled}
                            />
                          </FormGroup>
                        </>
                      )}
                      <Divider />
                      <h5>
                        If buying a house or bare land strata with a mortgage - Insurance
                        Information
                      </h5>
                      <br />
                      <TextInput
                        name="insuranceName"
                        label="Name of Insurance Agency"
                        disabled={formDisabled}
                      />
                      <TextInput
                        name="insuranceAddress"
                        label="Insurance Agency Address"
                        disabled={formDisabled}
                      />
                      <TextInput
                        name="insuranceBrokerName"
                        label="Broker’s Name"
                        disabled={formDisabled}
                      />
                      <FormGroup widths="equal">
                        <PhoneInput
                          name="insuranceBrokerPhone"
                          label="Broker’s Phone Number"
                          disabled={formDisabled}
                        />
                        <EmailInput
                          name="insuranceBrokerEmail"
                          label="Broker’s Email Address"
                          disabled={formDisabled}
                        />
                      </FormGroup>

                      <Divider />
                      <RadioGroupInput
                        name="additionalInfo"
                        label="Additional Information"
                        value={formData.additionalInfo}
                        onChange={handleRadioCheckboxChange}
                      />
                      {formData.additionalInfo === '1' && (
                        <>
                          <TextAreaInput
                            rows={4}
                            name="furtherInfo"
                            label="Any further information you would like to share with our office"
                            disabled={formDisabled}
                          />
                        </>
                      )}

                      <Divider />

                      <TextInput
                        name="referralInfo"
                        label="How were you referred to our office?"
                        disabled={formDisabled}
                      />

                      <Divider />
                      <InternalNotesTransfer
                        formDisabled={formDisabled}
                        formData={formData}
                        handleRadioCheckboxChange={handleRadioCheckboxChange}
                      />
                      <br />
                      <div className="Button_floatRight">
                        <Button
                          content="Save and Close"
                          // eslint-disable-next-line react/prop-types
                          disabled={!props.isValid || loading}
                          onClick={() => {
                            if (formikRef && formikRef.current) {
                              formikRef.current.submitForm();
                            }
                          }}
                          primary
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}

        <ThankYouModal
          fileNumber={fileNumber}
          modalOpen={modalOpen}
          cancel={cancel}
          formEdited={formEdited}
          currentCustomerId={currentCustomerId as string}
          clientId={clientId as string}
          formId={formId}
          formValues={form?.metadata?.currentValues || form?.metadata}
        />
      </div>
    </>
  );
};

export default FormTransfer;
