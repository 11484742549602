/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

import { toast } from 'react-semantic-toasts';
import { NumberSchema, StringSchema } from 'yup';
import { FileInfo } from 'readywhen-ui-components';
import { accountOptions } from '../../constants/dropdownOptions';

export function buildFilesSchema(clientFiles: Record<string, FileInfo[]>) {
  return () => {
    const keys = Object.keys(clientFiles);

    if (keys.length) {
      const propertyArray = keys.filter((s) => s.includes('property'));
      const insuranceArray = keys.filter((s) => s.includes('Insurance'));
      const financialAccountsArray = keys.filter((s) => s.includes('financialAccount'));
      const otherLegalArray = keys.filter((s) => s.includes('otherLegal'));
      const otherAssetsArray = keys.filter((s) => s.includes('otherAssets'));
      const will = keys.filter((s) => s.includes('willFile'));
      const spouseWill = keys.filter((s) => s.includes('spouseWillFile'));
      const poa = keys.filter((s) => s.includes('poaFile'));
      const spousePoa = keys.filter((s) => s.includes('spousePoaFile'));
      const health = keys.filter((s) => s.includes('healthFile'));
      const spouseHealth = keys.filter((s) => s.includes('spouseHealthFile'));

      if (propertyArray.length) {
        const propertyFields: Record<
          string,
          StringSchema<string | undefined, Record<string, string>>
        > = {};
        propertyArray?.forEach((p, index: number) => {
          propertyFields[`propertyTitle-${index}`] = Yup.string().required('Required');
          propertyFields[`address-${index}`] = Yup.string().required('Required');
        });
        return Yup.object({ ...(propertyFields && propertyFields) });
      }

      if (otherLegalArray.length) {
        const otherLegalFields: Record<
          string,
          StringSchema<string | undefined, Record<string, string>>
        > = {};
        otherLegalArray?.forEach((p, index: number) => {
          otherLegalFields[`otherLegalTitle-${index}`] = Yup.string().required('Required');
        });
        return Yup.object({ ...(otherLegalFields && otherLegalFields) });
      }

      if (otherAssetsArray.length) {
        const otherAssetsFields: Record<
          string,
          StringSchema<string | undefined, Record<string, string>>
        > = {};
        otherAssetsArray?.forEach((p, index: number) => {
          otherAssetsFields[`otherAssetsTitle-${index}`] = Yup.string().required('Required');
        });
        return Yup.object({ ...(otherAssetsFields && otherAssetsFields) });
      }

      if (financialAccountsArray.length) {
        const financialAccountsFields: Record<
          string,
          | StringSchema<string | undefined, Record<string, string>>
          | NumberSchema<number | undefined, Record<string, string>>
        > = {};
        financialAccountsArray?.forEach((f, index: number) => {
          financialAccountsFields[`financialAccountType-${index}`] = Yup.string()
            .oneOf(accountOptions.map((a: { value: string }) => a.value))
            .required('Required');
          financialAccountsFields[`accountBalance-${index}`] = Yup.number();
          financialAccountsFields[`branch-${index}`] = Yup.string().required('Required');
        });
        return Yup.object({ ...(financialAccountsFields && financialAccountsFields) });
      }

      if (insuranceArray.length) {
        const insuranceFields: Record<
          string,
          StringSchema<string | undefined, Record<string, string>>
        > = {};
        insuranceArray?.forEach((i, index) => {
          insuranceFields[`insuranceTitle-${index}`] = Yup.string().required('Required');
          insuranceFields[`insuranceName-${index}`] = Yup.string().required('Required');
          insuranceFields[`insurancePolicy-${index}`] = Yup.string().required('Required');
          insuranceFields[`accountType-${index}`] = Yup.string()
            .oneOf(['personal', 'work', 'mortgage'])
            .required('Required');
        });
        return Yup.object({ ...(insuranceFields && insuranceFields) });
      }

      const legalFields: Record<
        string,
        StringSchema<string | undefined, Record<string, string>>
      > = {};

      if (will.length) {
        legalFields.willPhysicalLocation = Yup.string().required('Required');
      }

      if (spouseWill.length) {
        legalFields.spouseWillPhysicalLocation = Yup.string().required('Required');
      }

      if (poa.length) {
        legalFields.poaPhysicalLocation = Yup.string().required('Required');
      }

      if (spousePoa.length) {
        legalFields.spousePoaPhysicalLocation = Yup.string().required('Required');
      }

      if (health.length) {
        legalFields.healthPhysicalLocation = Yup.string().required('Required');
      }

      if (spouseHealth.length) {
        legalFields.spouseHealthPhysicalLocation = Yup.string().required('Required');
      }

      return Yup.object({ ...(legalFields && legalFields) });
    }

    return Yup.object({});
  };
}

export function formErrorMessage(payload: string) {
  toast({
    type: 'error',
    icon: 'exclamation circle',
    title: 'Error',
    description: payload,
    animation: 'drop',
    time: 10000,
  });
}
export function formSuccessMessage(payload: string) {
  toast({
    type: 'success',
    icon: 'check circle',
    title: 'Success',
    description: payload,
    animation: 'drop',
    time: 10000,
  });
}
