import { Input, Select } from 'formik-semantic-ui-react';
import { DateInput, RegionDropdown } from 'readywhen-ui-components';
import { FormGroup } from 'semantic-ui-react';
// eslint-disable-next-line import/no-cycle
import { countryOptions } from '../../..';
import { EditPropertyModalFieldProps } from '../interfaces';

export default function EditPropertyModalField({
  formDisabled,
  index,
}: EditPropertyModalFieldProps): JSX.Element {
  return (
    <>
      <FormGroup widths="equal">
        <Input
          name={`propertyTitle-${index}`}
          label="Property Title *"
          disabled={formDisabled}
          errorPrompt
        />
        <DateInput
          name={`completionDate-${index}`}
          label="Completion Date *"
          disabled={formDisabled}
          errorPrompt
        />
      </FormGroup>

      <FormGroup widths="equal">
        <Input name={`address-${index}`} label="Address" disabled={formDisabled} errorPrompt />
        <Input
          name={`suite-${index}`}
          label="Apt/Suite number"
          disabled={formDisabled}
          errorPrompt
        />
      </FormGroup>
      <FormGroup widths="equal">
        <Input name={`city-${index}`} label="City" disabled={formDisabled} errorPrompt />
        <Input
          name={`postcode-${index}`}
          label="Postal code/Zip Code"
          disabled={formDisabled}
          errorPrompt
        />
      </FormGroup>
      <FormGroup widths="equal">
        <Select
          name={`propertyCountry-${index}`}
          label="Country"
          selectOnBlur={false}
          options={countryOptions}
          disabled={formDisabled}
        />

        <RegionDropdown index={index} name={`propertyRegion-${index}`} disabled={formDisabled} />
      </FormGroup>
    </>
  );
}
