/* eslint-disable import/no-cycle */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllIntakeForms, deleteFormById, createForm } from '../../api/ReadyWhenAdminAPI';

import { RootState } from '../../app/rootReducer';
import { ErrorType, FormPayload, IntakeForm } from '../../app/interfaces';

interface IntakeFormsState {
  loading: boolean;
  error: boolean;
  metadata: Record<string, string>;
  intakeForms: IntakeForm[];
  deleteLoading: boolean;
  deleteError: boolean;
  createLoading: boolean;
  createError: boolean;
}

const initialState: IntakeFormsState = {
  loading: false,
  error: false,
  metadata: {},
  intakeForms: [],
  deleteLoading: false,
  deleteError: false,
  createLoading: false,
  createError: false,
};

const intakeFormsSlice: any = createSlice({
  name: 'intakeForms',
  initialState,
  reducers: {
    intakeFormsStarted(state) {
      state.loading = true;
    },
    intakeFormsFailure(state) {
      state.error = true;
      state.loading = false;
    },
    intakeFormsSuccess(state, action) {
      state.loading = false;
      const { payload } = action;
      if (payload.data && payload.metadata) {
        state.metadata = payload.metadata;
        state.intakeForms = payload.data;
      }
      state.error = false;
    },
    deleteIntakeFormStarted(state) {
      state.deleteLoading = true;
    },
    deleteIntakeFormSuccess(state) {
      state.deleteError = true;
      state.deleteLoading = false;
    },
    deleteIntakeFormFailure(state) {
      state.deleteError = true;
      state.deleteLoading = false;
    },
    createIntakeFormStarted(state) {
      state.createLoading = true;
    },
    createIntakeFormSuccess(state) {
      state.createError = true;
      state.createLoading = false;
    },
    createIntakeFormFailure(state) {
      state.createError = true;
      state.createLoading = false;
    },
  },
});

export const {
  intakeFormsStarted,
  intakeFormsFailure,
  intakeFormsSuccess,
  deleteIntakeFormStarted,
  deleteIntakeFormSuccess,
  deleteIntakeFormFailure,
  createIntakeFormStarted,
  createIntakeFormSuccess,
  createIntakeFormFailure,
} = intakeFormsSlice.actions;

interface IntakeFormsPayload {
  customerId: string;
  page?: number;
  size?: number;
  sortColumn?: string;
  sortOrder?: string;
  search?: string;
}

export const fetchIntakeForms = createAsyncThunk(
  'intakeForms/fetchIntakeForms',
  async (
    {
      customerId,
      page = 1,
      size = 25,
      sortColumn = 'created_at',
      sortOrder = 'descending',
      search,
    }: IntakeFormsPayload,
    thunkAPI
  ) => {
    const { dispatch } = thunkAPI;

    try {
      dispatch(intakeFormsStarted());
      const response = await getAllIntakeForms({
        customerId,
        page,
        size,
        sortColumn,
        sortOrder,
        search,
      });
      dispatch(intakeFormsSuccess(response));
      return response;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(intakeFormsFailure());
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteIntakeForm = createAsyncThunk(
  'intakeForms/deleteIntakeForm',
  async (data: { customerId: string; formId: string }, thunkAPI) => {
    const { customerId, formId } = data;
    const { dispatch } = thunkAPI;
    try {
      dispatch(deleteIntakeFormStarted());
      const response = await deleteFormById(customerId, formId);
      dispatch(deleteIntakeFormSuccess());
      return response;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(deleteIntakeFormFailure());
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const createIntakeForm = createAsyncThunk(
  'intakeForms/createIntakeForm',
  async (data: FormPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      dispatch(createIntakeFormStarted());
      const response = await createForm(data);
      dispatch(createIntakeFormSuccess());
      return response;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(createIntakeFormFailure());
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const selectIntakeForms = (state: RootState): IntakeFormsState => state.intakeForms;

export default intakeFormsSlice.reducer;
