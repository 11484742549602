import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReadyAdminTable } from 'readywhen-ui-components';
import { debounce } from 'lodash';
import { Input } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import NewIntakeFormButton from './NewIntakeFormButton';
import CreateIntakeFormModal from './CreateIntakeFormModal';
import DeleteIntakeFormModal from './DeleteIntakeFormModal';
import { selectCustomers } from '../customers/customersSlice';
import { selectIntakeForms, fetchIntakeForms, deleteIntakeForm } from './intakeFormsSlice';
import ReadyAdminNavContainer from '../../components/ReadyAdminNavContainer';
import { AppDispatch } from '../../app/store';
import { formFileTypes } from '../../constants/forms';
import getIntakeFormColumns from './IntakeFormColumns';
import { formErrorMessage, formSuccessMessage } from './formMessage';

const IntakeFormsPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { currentCustomerId } = useSelector(selectCustomers);
  const { intakeForms, metadata, loading, deleteLoading, createLoading } =
    useSelector(selectIntakeForms);
  const activeFormId = useRef<{ id: string; type: string; status: string } | null>(null);
  const [page, setPage] = useState({
    currentPage: metadata?.page ? parseInt(metadata?.page as string, 10) : 1,
    pageSize: parseInt(metadata?.limit as string, 10) || 25,
    sortColumn: 'created_at',
    sortOrder: 'descending',
  });
  const [search, setSearch] = useState('');
  const [isNewIntakeModalOpen, setNewIntakeModalOpen] = useState(false);
  const [isDeleteIntakeModalOpen, setDeleteIntakeModalOpen] = useState(false);

  useEffect(() => {
    if (currentCustomerId) {
      dispatch(
        fetchIntakeForms({
          customerId: currentCustomerId,
          page: page.currentPage,
          size: page.pageSize,
          sortColumn: page.sortColumn,
          sortOrder: page.sortOrder,
        })
      );
    }
  }, [dispatch, currentCustomerId, page]);

  const handleOpenDeleteIntakeModal = useCallback(
    (rowData?: { id: string; type: string; status: string } | null) => {
      if (rowData !== undefined) {
        activeFormId.current = rowData;
      }
      setDeleteIntakeModalOpen((prevState) => !prevState);
    },
    []
  );

  const handleDeleteForm = useCallback(async () => {
    if (activeFormId.current && currentCustomerId) {
      const deleteResponse = await dispatch(
        deleteIntakeForm({ customerId: currentCustomerId, formId: activeFormId.current.id })
      );

      if (deleteIntakeForm.rejected.match(deleteResponse)) {
        const payload = (deleteResponse.payload as string) || 'Could not delete the Intake Form';
        formErrorMessage(payload);
      }

      if (deleteIntakeForm.fulfilled.match(deleteResponse)) {
        console.log('deleteResponse', deleteResponse);
        formSuccessMessage(
          `${formFileTypes[activeFormId.current.type]} Form was deleted successfully`
        );
      }

      handleOpenDeleteIntakeModal(null);
      if (currentCustomerId !== null) {
        await dispatch(fetchIntakeForms({ customerId: currentCustomerId }));
      }
    }
  }, [currentCustomerId, activeFormId, dispatch, handleOpenDeleteIntakeModal]);

  const handleEditForm = useCallback(
    (id: string, type: string) => {
      navigate(`/forms/${id}/${type}-form`);
    },
    [navigate]
  );

  const handleOpenIntakeModal = useCallback(
    () => setNewIntakeModalOpen((prevState) => !prevState),
    []
  );

  const columns = useMemo(
    () =>
      getIntakeFormColumns({
        onDelete: handleOpenDeleteIntakeModal,
        onEdit: handleEditForm,
        metadata,
      }),
    [handleOpenDeleteIntakeModal, handleEditForm, metadata]
  );

  const handleSort = useCallback(
    (payload) => {
      setPage({
        currentPage: payload.page,
        pageSize: payload.size,
        sortColumn: payload.sortColumn,
        sortOrder: payload.sortOrder,
      });
    },
    [setPage]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((nextValue) => {
      if (currentCustomerId)
        dispatch(
          fetchIntakeForms({
            customerId: currentCustomerId,
            page: page.currentPage,
            size: page.pageSize,
            search: nextValue,
          })
        );
    }, 600),
    [currentCustomerId]
  );

  const handleChangeSearch = useCallback(
    (e) => {
      const { value } = e.target;
      if (search !== value) {
        setSearch(value);
      }
      debouncedSearch(encodeURIComponent(value.trim()));
    },
    [debouncedSearch, search]
  );

  const handleResetSearch = useCallback(
    (e) => {
      e.preventDefault();
      setSearch('');

      if (currentCustomerId)
        dispatch(
          fetchIntakeForms({
            customerId: currentCustomerId,
            page: page.currentPage,
            size: page.pageSize,
          })
        );
    },
    [dispatch, currentCustomerId, page]
  );

  return (
    <ReadyAdminNavContainer
      title="Intake Forms"
      rightChildren={<NewIntakeFormButton onClick={handleOpenIntakeModal} />}
    >
      <Input
        icon="search"
        iconPosition="left"
        placeholder="Search"
        value={search}
        onChange={handleChangeSearch}
      />
      {search && (
        <a href="#reset" className="Search_reset" onClick={handleResetSearch}>
          Reset
        </a>
      )}
      <ReadyAdminTable
        pageName="Intake forms"
        loading={loading}
        columns={columns}
        rows={intakeForms}
        serverPagination
        metadata={metadata}
        serverCallback={handleSort}
      />
      <CreateIntakeFormModal
        loading={createLoading}
        isOpen={isNewIntakeModalOpen}
        onCloseModal={handleOpenIntakeModal}
        currentCustomerId={currentCustomerId}
      />
      <DeleteIntakeFormModal
        isOpen={isDeleteIntakeModalOpen}
        onClose={handleOpenDeleteIntakeModal}
        onConfirm={handleDeleteForm}
        loading={deleteLoading}
      />
    </ReadyAdminNavContainer>
  );
};

export default IntakeFormsPage;
