/* eslint-disable import/no-cycle */
import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import loginReducer from '../features/login/loginSlice';
import clientsReducer from '../features/clients/clientsSlice';
import licensesReducer from '../features/licenses/licensesSlice';
import employeesReducer from '../features/employees/employeesSlice';
import staffReducer from '../features/staff/staffSlice';
import customersReducer from '../features/customers/customersSlice';
import formsReducer from '../features/intakeForms/forms/formsSlice';
import intakeFormsReducer from '../features/intakeForms/intakeFormsSlice';
import messagesReducer from '../features/clients/details/messagesSlice';

const combinedReducer = combineReducers({
  login: loginReducer,
  clients: clientsReducer,
  licenses: licensesReducer,
  employees: employeesReducer,
  staff: staffReducer,
  customers: customersReducer,
  forms: formsReducer,
  intakeForms: intakeFormsReducer,
  messages: messagesReducer,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const rootReducer = (state: any, action: AnyAction): any => {
  let stateRef = state;
  if (action.type === 'login/logout') {
    stateRef = undefined;
  }
  return combinedReducer(stateRef, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
