import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Styles from './Nav.module.css';
import { selectCustomers } from '../features/customers/customersSlice';

const ReadyAdminSideNav = (): JSX.Element => {
  const { customersById, currentCustomerId } = useSelector(selectCustomers);
  return (
    <div className={Styles.SideNav}>
      {currentCustomerId !== null && (
        <ul>
          <li>
            <NavLink to="/clients" className={(navData) => (navData.isActive ? 'active' : '')}>
              <FontAwesomeIcon icon={['far', 'users']} className={Styles.SideNav_icon} />
              Clients
            </NavLink>
          </li>

          <li>
            <NavLink to="/intake-forms" className={(navData) => (navData.isActive ? 'active' : '')}>
              <FontAwesomeIcon icon={['far', 'files']} className={Styles.SideNav_icon} />
              Intake Forms
            </NavLink>
          </li>

          <li>
            <NavLink to="/staff" className={(navData) => (navData.isActive ? 'active' : '')}>
              <FontAwesomeIcon icon={['far', 'briefcase']} className={Styles.SideNav_icon} />
              Staff
            </NavLink>
          </li>
          <li>
            <NavLink to="/employees" className={(navData) => (navData.isActive ? 'active' : '')}>
              <FontAwesomeIcon icon={['far', 'balance-scale']} className={Styles.SideNav_icon} />
              Employees
            </NavLink>
          </li>
          {customersById?.[currentCustomerId]?.hasLicenses && (
            <li>
              <NavLink to="/licenses" className={(navData) => (navData.isActive ? 'active' : '')}>
                <FontAwesomeIcon icon={['far', 'credit-card']} className={Styles.SideNav_icon} />
                Licenses
              </NavLink>
            </li>
          )}

          <li>
            <NavLink
              to="/account-settings"
              className={(navData) => (navData.isActive ? 'active' : '')}
            >
              <FontAwesomeIcon icon={['far', 'cog']} className={Styles.SideNav_icon} />
              Account Details
            </NavLink>
          </li>
        </ul>
      )}
    </div>
  );
};

export default ReadyAdminSideNav;
