import { Divider, Grid, Segment } from 'semantic-ui-react';

interface PriceData {
  text: string;
  priceId: string;
  tax: number;
  units: number;
}
interface SummaryProps {
  numberOfLicenses: number;
  priceData: PriceData;
}

const ReadyAdminPaymentSummary = ({ numberOfLicenses, priceData }: SummaryProps): JSX.Element => {
  const { tax, text, units } = priceData;
  return (
    <Segment secondary padded="very" className="Segment_box___gray">
      <h3>Order Summary</h3>
      <p>
        <strong>${(units / 100).toFixed(2)}</strong>/license/{text}
      </p>
      <Divider style={{ borderTopStyle: 'dashed' }} />
      <strong>{numberOfLicenses} x Licenses</strong>
      <p />
      <Grid columns={2} stackable>
        <Grid.Row verticalAlign="middle">
          <Grid.Column>
            <p>Subtotal</p>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <p>${((numberOfLicenses * units) / 100).toFixed(2)}</p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row verticalAlign="middle">
          <Grid.Column>
            <p>Tax</p>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <p>${(((numberOfLicenses * units) / 100) * tax).toFixed(2)}</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider style={{ borderTopStyle: 'dashed' }} />

      <Grid columns={2} stackable style={{ marginTop: '20px' }}>
        <Grid.Row verticalAlign="middle">
          <Grid.Column>
            <p>
              <strong>Total</strong>
            </p>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <p>
              <strong style={{ fontSize: '24px' }}>
                ${(((numberOfLicenses * units) / 100) * (1 + tax)).toFixed(2)}
              </strong>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default ReadyAdminPaymentSummary;
