/* eslint-disable import/prefer-default-export */

import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import { Credentials } from 'aws-sdk/lib/credentials';
import config from '../configs/config';

import { Credential } from './interfaces';

async function fetchTempCredentials() {
  const response = await fetch(`${config.API_DOMAIN}/auth/credentials`, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  });
  const data = await response.json();
  return data;
}

const setAWSCred = async (tempCred: Credential) => {
  AWS.config.update({
    accessKeyId: tempCred.AccessKeyId,
    secretAccessKey: tempCred.SecretAccessKey,
    sessionToken: tempCred.SessionToken,
    region: config.AWS_REGION,
  });

  // renew the credentials when expired
  if ((AWS.config.credentials as Credentials).expired) {
    const renewed = await fetchTempCredentials();
    if (renewed) {
      AWS.config.update({
        accessKeyId: renewed.AccessKeyId,
        secretAccessKey: renewed.SecretAccessKey,
        sessionToken: renewed.SessionToken,
      });
      sessionStorage.setItem('TempCred', JSON.stringify(renewed));
    }
  }
};

export const updateTemporaryCredentials = async (
  forceReset?: boolean
): Promise<Credential | null> => {
  const savedTempCred = sessionStorage.getItem('TempCred');

  if (savedTempCred === null || forceReset) {
    sessionStorage.removeItem('TempCred');

    const data = await fetchTempCredentials();
    if (data) {
      await setAWSCred(data);
      sessionStorage.setItem('TempCred', JSON.stringify(data));
    }
  }

  if (savedTempCred) await setAWSCred(JSON.parse(savedTempCred));
  return new Promise((resolve) => resolve(null));
};
