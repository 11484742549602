/* eslint-disable import/no-cycle */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { ClientFilePayload } from 'readywhen-ui-components';
import orderBy from 'lodash/orderBy';

import {
  getClients,
  deleteClient,
  getUploads,
  patchClient,
  postClientSignUp,
  uploadFile,
  getUploadFiles,
  deleteFileById,
  patchUpload,
  postFile,
  postResendInvite,
  postUpload,
} from '../../api/ReadyWhenAdminAPI';
import {
  ClientSignUpFormPayload,
  EditClientPayload,
  ResendInvitePayload,
  Document,
  Client,
} from '../../api/interfaces';
import { ErrorType } from '../../app/interfaces';

import { RootState } from '../../app/rootReducer';
import { normalizeLocalUploadTypes } from '../../utils/stringUtils';

const clientsAdapter = createEntityAdapter<Client>();

export const fetchClients = createAsyncThunk(
  'clients/fetchClients',
  async ({
    customerId,
    page = 1,
    size = 25,
    sortColumn = 'created_at',
    sortOrder = 'descending',
    search,
  }: {
    customerId: string;
    page?: number;
    size?: number;
    sortColumn?: string;
    sortOrder?: string;
    search?: string;
  }) => {
    const response = await getClients(customerId, page, size, sortColumn, sortOrder, search);
    return response;
  }
);

export const fetchUploads = createAsyncThunk(
  'clients/fetchUploads',
  async (data: { customerId: string; clientId: string }, thunkAPI) => {
    const { customerId, clientId } = data;
    const uploads = await getUploads(customerId, clientId);
    const ordered = orderBy(uploads, ['updatedAt'], ['desc']);

    const { dispatch } = thunkAPI;
    const index = {};

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    dispatch(initClientFiles());

    // eslint-disable-next-line no-restricted-syntax
    for (const upload of ordered) {
      // for files with index
      if (upload.section === 'assets' || upload.section === 'health') {
        const name = `${normalizeLocalUploadTypes(upload.type)}Files-${
          (index as any)[upload.type] || '0'
        }`;
        // eslint-disable-next-line no-await-in-loop
        const files = await getUploadFiles(customerId, upload.id);
        files.forEach((f) => {
          const { s3Key: key } = f;
          dispatch(
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            addOneClientFile({
              name,
              file: {
                fileName: f.fileName,
                contentType: f.contentType,
                progress: 100,
                customerId,
                id: f.id,
                key,
                updatedAt: f.updatedAt,
                createdAt: f.createdAt,
              },
            })
          );
        });
        if ((index as any)[upload.type]) {
          (index as any)[upload.type] += 1;
        } else {
          (index as any)[upload.type] = 1;
        }
      }
    }
    return ordered;
  }
);

export const fetchPropertyUploads = createAsyncThunk(
  'clients/fetchOnePropertyUpload',
  async (data: { customerId: string; clientId: string; uploadId: string }, thunkAPI) => {
    const { customerId, uploadId, clientId } = data;
    const uploads = await getUploads(customerId, clientId);
    const ordered = orderBy(uploads, ['updatedAt'], ['desc']);

    const { dispatch } = thunkAPI;
    const index = {};
    const name = `propertyFiles-${(index as any).property || '0'}`;
    const files = await getUploadFiles(customerId, uploadId);

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    dispatch(initClientFiles());
    files.forEach((f) => {
      const { s3Key: key } = f;
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        addOneClientFile({
          name,
          file: {
            fileName: f.fileName,
            contentType: f.contentType,
            progress: 100,
            customerId,
            id: f.id,
            key,
            updatedAt: f.updatedAt,
            createdAt: f.createdAt,
            ownerId: f.ownerId,
          },
        })
      );
    });
    if ((index as any).property) {
      (index as any).property += 1;
    } else {
      (index as any).property = 1;
    }

    return ordered;
  }
);

export interface FileInfo {
  fileName: string;
  contentType: string;
  progress?: number;
  error?: string;
  customerId?: string;
  id?: string;
  cannotDel?: boolean;
  key?: string;
  updatedAt?: string;
  createdAt?: string;
  ownerId?: string;
  s3Key?: string;
}

interface ClientState {
  loading: boolean;
  uploadingFiles: number;
  error: boolean;
  files: Record<string, FileInfo[]>;
  documents: Record<string, Document[]>;
  metadata: Record<string, string>;
  entities: Record<string, unknown>;
}

const initialState: ClientState = {
  loading: false,
  uploadingFiles: 0,
  error: false,
  files: {},
  documents: {},
  metadata: {},
  entities: {},
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState: clientsAdapter.getInitialState(initialState),
  reducers: {
    clientsStarted(state) {
      state.loading = true;
    },
    clientsFailure(state) {
      state.error = true;
      state.loading = false;
    },
    clientsUploadStarted(state) {
      state.uploadingFiles += 1;
    },
    clientsUploadFinished(state) {
      state.uploadingFiles -= 1;
    },
    clientAdded(state, action) {
      clientsAdapter.addOne(state, action.payload);
      state.loading = false;
    },
    clientsFinished(state) {
      state.loading = false;
    },
    clientDeleteOne(state, action) {
      const { clientId } = action.payload;
      clientsAdapter.removeOne(state, clientId);
      state.loading = false;
    },
    clientUpdateOne(state, action) {
      const { clientId, ...rest } = action.payload;
      clientsAdapter.upsertOne(state, { id: clientId, ...rest });
      state.loading = false;
    },
    clientsSuccess(state, action) {
      state.loading = false;

      const { payload } = action;
      if (payload.data && payload.metadata) {
        state.metadata = payload.metadata;
        clientsAdapter.setAll(state, payload.data);
      } else {
        clientsAdapter.setAll(state, payload);
      }
    },
    documentsSuccess(state, action) {
      const { payload } = action;
      state.documents = {};
      const sorted = orderBy(payload, ['createdAt'], ['desc']);

      sorted.forEach(function process(doc: Document) {
        if (state.documents[doc.type]) {
          state.documents[doc.type].push(doc);
        } else {
          state.documents[doc.type] = [doc];
        }
      });
      state.loading = false;
    },
    addOneDocument(state, action) {
      const doc = action.payload;

      if (state.documents[doc.type]) {
        state.documents[doc.type].unshift(doc);
      } else {
        state.documents[doc.type] = [doc];
      }
      state.loading = false;
    },
    updateOneDocument(state, action) {
      const doc = action.payload;

      if (state.documents[doc.type]) {
        state.documents[doc.type] = state.documents[doc.type].map((document) => {
          if (document.id === doc.id) {
            return doc;
          }
          return document;
        });
      } else {
        state.documents[doc.type] = [doc];
      }
      state.loading = false;
    },
    initClientFiles(state) {
      state.files = {};
    },
    addOneClientFile(state, action) {
      const { name, file } = action.payload;

      if (state.files[name]) {
        state.files[name].unshift(file);
      } else {
        state.files[name] = [file];
      }
    },
    updateOneClientFile(state, action) {
      const { name, file } = action.payload;

      if (state.files[name]) {
        state.files[name] = state.files[name].map((existingFile) => {
          if (existingFile.fileName === file.fileName) {
            return file;
          }
          return existingFile;
        });
      } else {
        state.files[name] = [file];
      }
    },
    replaceOneClientFile(state, action) {
      const { name, file } = action.payload;
      state.files[name] = [file];
    },
    deleteOneClientFile(state, action) {
      const { name, file } = action.payload;

      if (state.files[name]) {
        state.files[name] = state.files[name].filter(
          (existingFile) => existingFile.key !== file.key
        );
      }
    },
  },
  extraReducers: {
    [fetchClients.pending as unknown as string]: (state) => {
      clientsSlice.caseReducers.clientsStarted(state);
    },
    [fetchClients.fulfilled as unknown as string]: (state, action) => {
      clientsSlice.caseReducers.clientsSuccess(state, action);
    },
    [fetchClients.rejected as unknown as string]: (state) => {
      clientsSlice.caseReducers.clientsFailure(state);
    },

    [fetchUploads.pending as unknown as string]: (state) => {
      clientsSlice.caseReducers.clientsStarted(state);
    },
    [fetchUploads.fulfilled as unknown as string]: (state, action) => {
      clientsSlice.caseReducers.documentsSuccess(state, action);
    },
    [fetchUploads.rejected as unknown as string]: (state) => {
      clientsSlice.caseReducers.clientsFailure(state);
    },

    [fetchPropertyUploads.pending as unknown as string]: (state) => {
      clientsSlice.caseReducers.clientsStarted(state);
    },
    [fetchPropertyUploads.fulfilled as unknown as string]: (state, action) => {
      clientsSlice.caseReducers.documentsSuccess(state, action);
    },
    [fetchPropertyUploads.rejected as unknown as string]: (state) => {
      clientsSlice.caseReducers.clientsFailure(state);
    },
  },
});

export const {
  clientAdded,
  clientsStarted,
  clientsFinished,
  clientsSuccess,
  clientsFailure,
  clientDeleteOne,
  clientUpdateOne,
  updateOneDocument,
  addOneDocument,
  clientsUploadStarted,
  clientsUploadFinished,
  addOneClientFile,
  updateOneClientFile,
  replaceOneClientFile,
  initClientFiles,
  deleteOneClientFile,
} = clientsSlice.actions;

export const resendInvite = createAsyncThunk(
  'clients/resend',
  async (formData: ResendInvitePayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
      dispatch(clientsStarted());

      const response = await postResendInvite(formData);
      dispatch(clientsFinished());
      return response;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(clientsFailure());
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const signUpClient = createAsyncThunk(
  'clients/signUp',
  async (formData: ClientSignUpFormPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
      dispatch(clientsStarted());

      const response = await postClientSignUp(formData);
      dispatch(clientAdded(response));

      return response;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(clientsFailure());
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);
export const uploadOneClientFile = createAsyncThunk(
  'clients/uploadOne',
  async (
    data: {
      name: string;
      files: File;
      tempDir: string;
      customerId: string;
      section: string;
      uploadId?: string;
      userId?: string;
      formId?: string;
    },
    thunkAPI
  ) => {
    const { dispatch } = thunkAPI;
    const { name, tempDir, customerId, section, uploadId, userId, files, formId } = data;
    try {
      let response = {};
      dispatch(clientsUploadStarted());
      dispatch(
        addOneClientFile({
          name,
          file: {
            fileName: files.name,
            contentType: files.type,
            progress: 99,
          },
        })
      );
      const fileUpload = await uploadFile(files, customerId, tempDir, section);

      try {
        if (fileUpload) {
          const { Key } = fileUpload;
          dispatch(
            updateOneClientFile({
              name,
              file: {
                fileName: files.name,
                contentType: files.type,
                progress: 100,
                key: Key,
              },
            })
          );
          if (customerId && uploadId && userId) {
            const fileInfo = {
              fileName: files.name,
              contentType: files.type,
              key: Key,
            };
            if (section !== 'legal') {
              const result = await postFile(
                fileInfo as FileInfo,
                customerId,
                uploadId,
                userId,
                formId
              );

              try {
                const newFile = {
                  id: result?.id,
                  fileName: files.name,
                  contentType: files.type,
                  progress: 100,
                  key: Key,
                };
                dispatch(
                  updateOneClientFile({
                    name,
                    file: newFile,
                  })
                );
                response = newFile;
              } catch (error) {
                console.error(error);
              }
            }
          }
          dispatch(clientsUploadFinished());
        }
      } catch (err) {
        const error = err as ErrorType;
        dispatch(
          updateOneClientFile({
            name,
            file: {
              fileName: files.name,
              contentType: files.type,
              progress: 0,
              error: error.message,
            },
          })
        );
        dispatch(clientsUploadFinished());
      }

      return response as FileInfo;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(clientsFailure());
      dispatch(clientsUploadFinished());
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const uploadClientFiles = createAsyncThunk(
  'clients/upload',
  async (
    data: {
      name: string;
      files: File[];
      tempDir: string;
      customerId: string;
      section: string;
      uploadId?: string;
      userId?: string;
      formId?: string;
    },
    thunkAPI
  ) => {
    const { dispatch } = thunkAPI;
    const { name, tempDir, customerId, section, uploadId, userId, files, formId } = data;
    try {
      files.forEach((file) => {
        dispatch(clientsUploadStarted());
        dispatch(
          addOneClientFile({
            name,
            file: {
              fileName: file.name,
              contentType: file.type,
              progress: 99,
            },
          })
        );

        const fileUpload = uploadFile(file, customerId, tempDir, section);
        fileUpload
          .then((res) => {
            const { Key } = res;
            dispatch(
              updateOneClientFile({
                name,
                file: {
                  fileName: file.name,
                  contentType: file.type,
                  progress: 100,
                  key: Key,
                },
              })
            );

            if (customerId && uploadId && userId) {
              const fileInfo = {
                fileName: file.name,
                contentType: file.type,
                key: Key,
              };
              if (section !== 'legal') {
                const promise = postFile(
                  fileInfo as FileInfo,
                  customerId,
                  uploadId,
                  userId,
                  formId
                );
                promise.then((result) => {
                  dispatch(
                    updateOneClientFile({
                      name,
                      file: {
                        id: result?.id,
                        fileName: file.name,
                        contentType: file.type,
                        progress: 100,
                        key: Key,
                      },
                    })
                  );
                });
              }
            }

            dispatch(clientsUploadFinished());
          })
          .catch((err) => {
            dispatch(
              updateOneClientFile({
                name,
                file: {
                  fileName: file.name,
                  contentType: file.type,
                  progress: 0,
                  error: err.message,
                },
              })
            );
            dispatch(clientsUploadFinished());
          });
      });

      const response = {};

      return response;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(clientsFailure());
      dispatch(clientsUploadFinished());
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const replaceClientFiles = createAsyncThunk(
  'clients/upload',
  async (data: ClientFilePayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { name, file, tempDir, customerId, section, uploadId, userId, formId } = data;
    dispatch(clientsUploadStarted());
    try {
      dispatch(
        replaceOneClientFile({
          name,
          file: {
            fileName: file.name,
            contentType: file.type,
            progress: 99,
          },
        })
      );

      const fileUpload = uploadFile(file, customerId, tempDir, section);
      fileUpload
        .then((res) => {
          const { Key } = res;
          dispatch(
            updateOneClientFile({
              name,
              file: {
                fileName: file.name,
                contentType: file.type,
                progress: 100,
                key: Key,
                ...(section === 'legal' && { cannotDel: true }),
              },
            })
          );

          if (customerId && uploadId && userId) {
            const fileInfo = {
              fileName: file.name,
              contentType: file.type,
              key: Key,
            };

            if (section !== 'legal')
              postFile(fileInfo as FileInfo, customerId, uploadId, userId, formId);
          }

          dispatch(clientsUploadFinished());
        })
        .catch((err) => {
          dispatch(
            updateOneClientFile({
              name,
              file: {
                fileName: file.name,
                contentType: file.type,
                progress: 0,
                error: err.message,
              },
            })
          );
          dispatch(clientsUploadFinished());
        });

      const response = {};

      return response;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(clientsFailure());
      dispatch(clientsUploadFinished());
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteClientFiles = createAsyncThunk(
  'clients/deleteFile',
  async (data: { name: string; file: FileInfo }, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { name, file } = data;
    try {
      dispatch(
        deleteOneClientFile({
          name,
          file,
        })
      );

      if (file.customerId && file.id) deleteFileById(file.customerId, file.id);

      const response = {};

      return response;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(clientsFailure());
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const removeOneClient = createAsyncThunk(
  'clients/delete',
  async (data: { customerId: string; clientId: string }, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { customerId, clientId } = data;
    try {
      let response;
      if (customerId && clientId) {
        await deleteClient(customerId, clientId);
        dispatch(clientDeleteOne(data));
      }

      return response;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(clientsFailure());
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const updateOneClient = createAsyncThunk(
  'clients/update',
  async (data: { customerId: string; clientId: string; payload: EditClientPayload }, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { customerId, clientId, payload } = data;

    try {
      let response;
      if (customerId && clientId) {
        await patchClient(customerId, clientId, payload);
        dispatch(clientUpdateOne({ clientId, ...payload }));
      }

      return response;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(clientsFailure());
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const createOneUpload = createAsyncThunk(
  'clients/createUpload',
  async (data: { payload: any }, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { payload } = data;

    const { customerId, clientId } = payload;
    try {
      let response;
      if (customerId) {
        response = await postUpload(customerId, clientId, payload);
        dispatch(addOneDocument(response));
      }

      return response;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(clientsFailure());
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const updateOneUpload = createAsyncThunk(
  'clients/updateUpload',
  async (data: { customerId: string; uploadId: string; payload: any }, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { customerId, uploadId, payload } = data;
    try {
      let response;
      if (customerId && uploadId) {
        response = await patchUpload(customerId, uploadId, payload);
        dispatch(updateOneDocument(response));
      }

      return response;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(clientsFailure());
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const clientsSelectors = clientsAdapter.getSelectors<RootState>((state) => state.clients);
export const selectClients = (state: RootState): ClientState => state.clients;

export default clientsSlice.reducer;
