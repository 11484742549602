/* eslint-disable import/no-cycle */
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { FunctionComponent, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

import { onAuthUIStateChange } from '@aws-amplify/ui-components';

import { useQuery } from 'readywhen-ui-components';
import logo from '../assets/logo-portal.svg';

import './App.less';

import {
  selectLogin,
  setAuthState,
  setCurrentUser,
  User,
  UserAttributes,
} from '../features/login/loginSlice';

import AccountSettingsPage from '../features/account/AccountSettingsPage';
import ClientsPage from '../features/clients/ClientsPage';

// eslint-disable-next-line import/no-cycle
import { updateTemporaryCredentials } from '../api/auth';
import ClientEntriesPage from '../features/clients/ClientEntriesPage';
import PropertyOrWillEntryPage from '../features/clients/details/PropertyOrWillEntryPage';
import SignUpFormPage from '../features/clients/signup/SignUpFormPage';
import EmployeesPage from '../features/employees/EmployeesPage';
import FormPurchase from '../features/intakeForms/forms/FormPurchase';
import FormRefinance from '../features/intakeForms/forms/FormRefinance';
import FormSale from '../features/intakeForms/forms/FormSale';
import FormTransfer from '../features/intakeForms/forms/FormTransfer';
import FormWill from '../features/intakeForms/forms/FormWill';
import IntakeFormsPage from '../features/intakeForms/IntakeFormsPage';
import BuyLicensesPage from '../features/licenses/BuyLicensesPage';
import LicensesPage from '../features/licenses/LicensesPage';
import StaffPage from '../features/staff/StaffPage';
import { AppDispatch } from './store';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any;
  }
}

interface QueryProps {
  clientIdFromQuery: string | null;
  formTypeFromQuery: string | null;
  toFromQuery: string | null;
  propertyIdFromQuery: string | null;
}

const computePath = ({
  clientIdFromQuery,
  formTypeFromQuery,
  toFromQuery,
  propertyIdFromQuery,
}: QueryProps): ReactElement | null => {
  switch (toFromQuery) {
    case 'message':
      return (
        <Navigate
          to={`/clients/${clientIdFromQuery}/${propertyIdFromQuery}?to=message`}
          key={`redirectFromQuery${clientIdFromQuery}`}
        />
      );

    case 'property':
      return (
        <Navigate
          to={`/clients/${clientIdFromQuery}/${propertyIdFromQuery}?to=property`}
          key={`redirectFromQuery${clientIdFromQuery}`}
        />
      );

    case 'form':
      return (
        <Navigate
          to={`/clients/${clientIdFromQuery}/${propertyIdFromQuery}/${formTypeFromQuery}-form`}
          key={`redirectFromQuery${clientIdFromQuery}`}
        />
      );

    default:
      return (
        <Navigate
          to={`/clients/${clientIdFromQuery}?propertyId=${propertyIdFromQuery}`}
          key={`redirectFromQuery${clientIdFromQuery}`}
        />
      );
  }
};

const App = (): JSX.Element => {
  const query = useQuery();

  const clientIdFromQuery = query.get('clientId');
  const formTypeFromQuery = query.get('formType');
  const toFromQuery = query.get('to');
  const propertyIdFromQuery = query.get('propertyId');

  const EmailRedirect = () => {
    if (clientIdFromQuery && propertyIdFromQuery && toFromQuery) {
      return computePath({
        clientIdFromQuery,
        formTypeFromQuery,
        toFromQuery,
        propertyIdFromQuery,
      });
    }
    return <Navigate to="/clients" />;
  };

  return (
    <Routes>
      <Route path="/clients" element={<ClientsPage />} />
      <Route path="/intake-forms" element={<IntakeFormsPage />} />
      <Route path="/licenses" element={<LicensesPage />} />
      <Route path="/account-settings" element={<AccountSettingsPage />} />
      <Route path="/employees" element={<EmployeesPage />} />
      <Route path="/staff" element={<StaffPage />} />
      <Route path="/buy-licenses" element={<BuyLicensesPage />} />
      <Route path="/clients/sign-up" element={<SignUpFormPage />} />
      <Route path="/clients/:clientId" element={<ClientEntriesPage />} />
      <Route path="/clients/:clientId/:id" element={<PropertyOrWillEntryPage />} />

      <Route path="/clients/:clientId/:id/purchase-form" element={<FormPurchase />} />
      <Route path="/clients/:clientId/:id/refinance-form" element={<FormRefinance />} />
      <Route path="/clients/:clientId/:id/transfer-form" element={<FormTransfer />} />
      <Route path="/clients/:clientId/:id/sale-form" element={<FormSale />} />

      <Route path="/forms/:intakeFormId/purchase-form" element={<FormPurchase />} />
      <Route path="/forms/:intakeFormId/refinance-form" element={<FormRefinance />} />
      <Route path="/forms/:intakeFormId/transfer-form" element={<FormTransfer />} />
      <Route path="/forms/:intakeFormId/sale-form" element={<FormSale />} />

      <Route path="/clients/:clientId/:id/will-form" element={<FormWill />} />
      <Route path="*" element={<EmailRedirect />} />
    </Routes>
  );
};

const AuthStateApp: FunctionComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const { isLoggedIn } = useSelector(selectLogin);
  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      dispatch(setAuthState(nextAuthState));
      if (authData) {
        const myUser: User = {
          id: (authData as Record<string, unknown>).username as string,
          attributes: (authData as Record<string, unknown>).attributes as UserAttributes,
        };
        dispatch(setCurrentUser(myUser));
        window.analytics.identify(myUser.id, {
          type: 'professional',
          username: myUser.attributes.email,
          email: myUser.attributes.email,
          firm: myUser.attributes['custom:firm'],
          address: myUser.attributes['custom:address'],
          suite: myUser.attributes['custom:suite'],
          city: myUser.attributes['custom:city'],
          postcode: myUser.attributes['custom:postcode'],
          region: myUser.attributes['custom:region'] || 'BC',
          country: myUser.attributes['custom:country'] || 'CA',
        });
      }
      updateTemporaryCredentials();
    });
  }, [dispatch]);

  return isLoggedIn ? (
    <App />
  ) : (
    <Container text className="Login_container">
      <header className="Login_header">
        <img src={logo} className="App_logo" alt="ReadyWhen Logo" />
      </header>
      <AmplifyAuthenticator usernameAlias="email" />
    </Container>
  );
};

export default AuthStateApp;
