import React, { useMemo, Fragment, useEffect, useState, useRef, FocusEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  CheckboxProps,
  Divider,
  FormGroup,
  Grid,
  Header,
  Modal,
  Transition,
} from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import {
  CopyFields,
  DateWithCopyInput,
  EmailInput,
  MoneyInput,
  PhoneInput,
  RadioGroupInput,
  TextAreaInput,
  TextInput,
} from 'readywhen-ui-components';
import includes from 'lodash/includes';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import startCase from 'lodash/startCase';
import { RootState } from '../../../app/rootReducer';
import { fetchUploads, fetchPropertyUploads } from '../../clients/clientsSlice';
import { selectCustomers } from '../../customers/customersSlice';
import { fetchForms, updateForm } from './formsSlice';
import ReadyAdminTopNav from '../../../components/ReadyAdminTopNav';
import { Document, Form as FormInterface } from '../../../api/interfaces';
import { AppDispatch } from '../../../app/store';

interface RouterProps {
  clientId: string;
  id: string;
}
interface HeaderRemoveButtonProps {
  index: number;
  fieldName: string;
  title: string;
  removeIndexField: (index: number, fieldName: string) => void;
}
// TODO: use from another file
export const HeaderRemoveButton = ({
  index,
  fieldName,
  title,
  removeIndexField,
}: HeaderRemoveButtonProps): JSX.Element => {
  return (
    <>
      {index >= 1 && (
        <div className="Header__field--indexOnly">
          <h5>
            {startCase(title)} #{index + 1}
          </h5>
          <span
            style={{ cursor: 'pointer' }}
            role="button"
            tabIndex={0}
            onKeyDown={() => removeIndexField(index, fieldName)}
            onClick={() => {
              removeIndexField(index, fieldName);
            }}
          >
            <FontAwesomeIcon icon={['far', 'trash']} /> <p>Remove</p>
          </span>
        </div>
      )}
    </>
  );
};

interface PropertyProps {
  index: number;
  removeIndexField: (index: number, fieldName: string) => void;
  formDisabled: boolean;
  formData: Record<string, unknown>;
  handleIndexFieldChange: (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps,
    fieldName?: string
  ) => void;
}

interface OtherProps {
  index: number;
  removeIndexField: (index: number, fieldName: string) => void;
  formDisabled: boolean;
}

const PropertyFields = ({
  index,
  removeIndexField,
  formDisabled,
  formData,
  handleIndexFieldChange,
}: PropertyProps) => {
  return (
    <Fragment key={`property-${index}`}>
      <HeaderRemoveButton
        index={index}
        removeIndexField={removeIndexField}
        fieldName="properties"
        title="property"
      />
      <TextInput
        name={`properties[${index}].address`}
        label="Address of Property"
        disabled={formDisabled}
      />
      <FormGroup widths="equal">
        <TextInput
          name={`properties[${index}].owing`}
          label="Mortgage & Amount Owing"
          disabled={formDisabled}
        />
        <MoneyInput
          name={`properties[${index}].value`}
          label="Approximate Value"
          disabled={formDisabled}
        />
      </FormGroup>
      <TextInput
        name={`properties[${index}].owner`}
        label="Registered Owner(s) - exactly as shown on Title"
        disabled={formDisabled}
      />
      <RadioGroupInput
        name={`properties[${index}].tenantsType`}
        label="If more than one registered owner how do you own the property?"
        value={(formData.properties as Record<string, string>[])[index]?.tenantsType}
        options={[
          { value: 'jointTenants', label: 'Joint Tenants' },
          { value: 'tenantsInCommon', label: 'Tenants in Common' },
        ]}
        onChange={(e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) =>
          handleIndexFieldChange(e, data, 'properties')
        }
      />

      <TextAreaInput name={`properties[${index}].notes`} label="Notes" disabled={formDisabled} />
    </Fragment>
  );
};

const PropertiesOutsideFields = ({ index, removeIndexField, formDisabled }: OtherProps) => {
  return (
    <Fragment key={`propertyOutside-${index}`}>
      <HeaderRemoveButton
        index={index}
        removeIndexField={removeIndexField}
        fieldName="propertiesOutside"
        title="property"
      />

      <TextInput
        name={`propertiesOutside[${index}].address`}
        label="Address of Property"
        disabled={formDisabled}
      />
      <TextAreaInput
        name={`propertyOutside[${index}].notes`}
        label="Notes"
        disabled={formDisabled}
      />
    </Fragment>
  );
};

const BankFields = ({ index, removeIndexField, formDisabled }: OtherProps) => {
  return (
    <Fragment key={`bank-${index}`}>
      <HeaderRemoveButton
        index={index}
        removeIndexField={removeIndexField}
        fieldName="bankAccounts"
        title="account"
      />

      <TextInput
        name={`bankAccounts[${index}].bank`}
        label="Name of Financial Institution"
        disabled={formDisabled}
      />
      <FormGroup widths="equal">
        <MoneyInput
          name={`bankAccounts[${index}].value`}
          label="Approximate Value"
          disabled={formDisabled}
        />

        <TextInput
          name={`bankAccounts[${index}].joint`}
          label="Is the account joint with anyone?"
          disabled={formDisabled}
        />
      </FormGroup>
      <TextAreaInput name={`bankAccounts[${index}].notes`} label="Notes" disabled={formDisabled} />
    </Fragment>
  );
};

const LifeInsuranceFields = ({ index, removeIndexField, formDisabled }: OtherProps) => {
  return (
    <Fragment key={`lifeInsurance-${index}`}>
      <HeaderRemoveButton
        index={index}
        removeIndexField={removeIndexField}
        fieldName="lifeInsurances"
        title="life insurance"
      />
      <FormGroup widths="equal">
        <TextInput
          name={`lifeInsurances[${index}].companyName`}
          label="Name of Insurance Company"
          disabled={formDisabled}
        />
        <TextInput
          name={`lifeInsurances[${index}].policyOwner`}
          label="Policy Owner"
          disabled={formDisabled}
        />
      </FormGroup>
      <FormGroup widths="equal">
        <MoneyInput
          name={`lifeInsurances[${index}].value`}
          label="Approximate Value"
          disabled={formDisabled}
        />

        <TextInput
          name={`lifeInsurances[${index}].beneficiary`}
          label="Any named Beneficiary"
          disabled={formDisabled}
        />
      </FormGroup>
      <TextAreaInput
        name={`lifeInsurances[${index}].notes`}
        label="Notes"
        disabled={formDisabled}
      />
    </Fragment>
  );
};

const InvestmentFields = ({ index, removeIndexField, formDisabled }: OtherProps) => {
  return (
    <Fragment key={`investment-${index}`}>
      <HeaderRemoveButton
        index={index}
        removeIndexField={removeIndexField}
        fieldName="investments"
        title="RRSPs / RRIFS"
      />
      <FormGroup widths="equal">
        <TextInput
          name={`investments[${index}].institutionName`}
          label="Name of Institution"
          disabled={formDisabled}
        />
        <TextInput
          name={`investments[${index}].investmentType`}
          label="Investment Type"
          disabled={formDisabled}
        />
      </FormGroup>
      <FormGroup widths="equal">
        <MoneyInput
          name={`investments[${index}].value`}
          label="Approximate Value"
          disabled={formDisabled}
        />

        <TextInput
          name={`investments[${index}].beneficiary`}
          label="Any named Beneficiary"
          disabled={formDisabled}
        />
      </FormGroup>
      <TextAreaInput name={`investments[${index}].notes`} label="Notes" disabled={formDisabled} />
    </Fragment>
  );
};

const SecurityFields = ({ index, removeIndexField, formDisabled }: OtherProps) => {
  return (
    <Fragment key={`security-${index}`}>
      <HeaderRemoveButton
        index={index}
        removeIndexField={removeIndexField}
        fieldName="securities"
        title="security"
      />
      <TextInput
        name={`securities[${index}].description`}
        label="Description"
        disabled={formDisabled}
      />

      <FormGroup widths="equal">
        <MoneyInput
          name={`securities[${index}].value`}
          label="Approximate Value"
          disabled={formDisabled}
        />

        <TextInput
          name={`securities[${index}].beneficiary`}
          label="Any named Beneficiary"
          disabled={formDisabled}
        />
      </FormGroup>
      <TextAreaInput name={`securities[${index}].notes`} label="Notes" disabled={formDisabled} />
    </Fragment>
  );
};

const PensionFields = ({ index, removeIndexField, formDisabled }: OtherProps) => {
  return (
    <Fragment key={`pension-${index}`}>
      <HeaderRemoveButton
        index={index}
        removeIndexField={removeIndexField}
        fieldName="pensions"
        title="pension"
      />
      <FormGroup widths="equal">
        <TextInput
          name={`pensions[${index}].description`}
          label="Description"
          disabled={formDisabled}
        />

        <TextInput
          name={`pensions[${index}].beneficiary`}
          label="Any named Beneficiary"
          disabled={formDisabled}
        />
      </FormGroup>
      <TextAreaInput name={`pensions[${index}].notes`} label="Notes" disabled={formDisabled} />
    </Fragment>
  );
};

const CompanyFields = ({
  index,
  removeIndexField,
  formDisabled,
  formData,
  handleIndexFieldChange,
}: PropertyProps) => {
  return (
    <Fragment key={`company-${index}`}>
      <HeaderRemoveButton
        index={index}
        removeIndexField={removeIndexField}
        fieldName="companies"
        title="company"
      />
      <RadioGroupInput
        name={`companies[${index}].incorporated`}
        label="Is it incorporated?"
        value={(formData.companies as Record<string, string>[])[index].incorporated}
        onChange={handleIndexFieldChange}
      />

      <TextInput
        name={`companies[${index}].companyName`}
        label="Name of Company"
        disabled={formDisabled}
      />
      <TextAreaInput name={`companies[${index}].notes`} label="Notes" disabled={formDisabled} />
    </Fragment>
  );
};

const PowerOfAttorneyFields = ({
  index,
  removeIndexField,
  formDisabled,
  formData,
  handleIndexFieldChange,
}: PropertyProps) => {
  return (
    <Fragment key={`poa-${index}`}>
      <HeaderRemoveButton
        index={index}
        removeIndexField={removeIndexField}
        title="power of attorney"
        fieldName="powerOfAttorneys"
      />

      {formData.poaSpousesAppointFirst === '1' && index === 0 ? null : (
        <>
          <TextInput
            name={`powerOfAttorneys[${index}].fullName`}
            label="Full Name of Attorney"
            disabled={formDisabled}
          />
          <Form.Group widths="equal">
            <TextInput
              name={`powerOfAttorneys[${index}].occupation`}
              label="Occupation"
              disabled={formDisabled}
            />
            <TextInput
              name={`powerOfAttorneys[${index}].relationship`}
              label="Relationship to you"
              disabled={formDisabled}
            />
          </Form.Group>
          <TextInput
            name={`powerOfAttorneys[${index}].address`}
            label="Address"
            disabled={formDisabled}
          />
        </>
      )}
    </Fragment>
  );
};

const FormWill = (): JSX.Element => {
  const { currentCustomerId } = useSelector(selectCustomers);
  const { clientId, id } = useParams();
  const formikRef = useRef<FormikProps<typeof initialData>>(null);
  const { documents } = useSelector((state: RootState) => state.clients);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { loading, entities } = useSelector((state: RootState) => state.forms);
  const [modalOpen, setModalOpen] = useState(0);

  const displayedWill = useMemo(
    () =>
      documents && documents.will
        ? documents.will.find((will: Document) => will.id === id)
        : {
            metadata: {
              name: '',
              address: '',
              suite: '',
              city: '',
              postcode: '',
              completionDate: '',
            },
          },
    [documents, id]
  );

  const formId: string = useMemo(
    () => (displayedWill && displayedWill?.forms?.length ? displayedWill.forms[0].id : { id: '' }),
    [displayedWill]
  );
  const form: FormInterface = useMemo(() => entities[formId], [entities, formId]);

  useEffect(() => {
    if (currentCustomerId !== null && clientId) {
      dispatch(fetchForms(currentCustomerId, clientId));
      dispatch(fetchUploads({ customerId: currentCustomerId, clientId }));
    }
  }, [dispatch, currentCustomerId, clientId, id]);

  useEffect(() => {
    if (currentCustomerId !== null && !formId && clientId && id) {
      dispatch(fetchPropertyUploads({ customerId: currentCustomerId, clientId, uploadId: id }));
    }
  }, [dispatch, currentCustomerId, clientId, id, formId]);

  const [formDisabled, setFormDisabled] = useState(false);

  const emptyProperty = useMemo(() => {
    return {
      address: '',
      owing: '',
      value: '',
      owner: '',
      tenantsType: '',
      notes: '',
    };
  }, []);

  const emptyPropertyOutside = useMemo(() => {
    return {
      address: '',
      notes: '',
    };
  }, []);

  const emptyBankAccount = useMemo(() => {
    return {
      bank: '',
      value: '',
      joint: '',
      notes: '',
    };
  }, []);

  const emptyLifeInsurance = useMemo(() => {
    return {
      companyName: '',
      policyOwner: '',
      value: '',
      beneficiary: '',
      notes: '',
    };
  }, []);

  const emptyInvestment = useMemo(() => {
    return {
      institutionName: '',
      investmentType: '',
      value: '',
      beneficiary: '',
      notes: '',
    };
  }, []);

  const emptySecurity = useMemo(() => {
    return {
      description: '',
      value: '',
      beneficiary: '',
      notes: '',
    };
  }, []);

  const emptyPension = useMemo(() => {
    return {
      description: '',
      beneficiary: '',
      notes: '',
    };
  }, []);

  const emptyCompany = useMemo(() => {
    return {
      incorporated: false,
      companyName: '',
      notes: '',
    };
  }, []);

  const emptyPowerOfAttorney = useMemo(() => {
    return {
      fullName: '',
      occupation: '',
      relationship: '',
      address: '',
    };
  }, []);

  const initialData = useMemo(() => {
    return {
      yourself: {
        firstName: '',
        lastName: '',
        middleName: '',
        address: '',
        email: '',
        phone: {
          value: '',
          country_code: 'CA',
        },
        occupation: '',
        citizenship: '',
        dob: '',
        pob: '',
      },

      spouse: {
        firstName: '',
        lastName: '',
        middleName: '',
        address: '',
        email: '',
        phone: {
          value: '',
          country_code: 'CA',
        },
        occupation: '',
        citizenship: '',
        dob: '',
        pob: '',
      },
      previousWill: 'undefined',
      haveSpouse: 'undefined',
      havePropertiesOutside: 'undefined',
      childDisability: 'undefined',
      children: '',
      childrenPrevious: '',
      properties: [emptyProperty],
      propertiesOutside: [emptyPropertyOutside],
      bankAccounts: [emptyBankAccount],
      lifeInsurances: [emptyLifeInsurance],
      investments: [emptyInvestment],
      securities: [emptySecurity],
      pensions: [emptyPension],
      ownCompany: '0',
      companies: [emptyCompany],
      executeNow: '0',
      powerOfAttorneys: [emptyPowerOfAttorney],
      otherAssets: '',
      otherAssetsDescription: '',
      liabilitiesDescription: '',
      netWorth: '',
      safetyDeposit: '0',
      safetyDepositNotes: '',
      anyoneOwnYouMoney: '0',
      whoOwnsYouMoney: '',
      amountOwned: '',
      spousesAppointFirst: 'undefined',
      alternateExecutor: '',
      guardianInformation: '',
      poaSpousesAppointFirst: 'undefined',
      attorneysActTogether: 'undefined',
      attorneysActSeparately: 'undefined',
      attorneyCompensated: 'undefined',
      attorneyRemainsEffective: 'undefined',
      representationAgreement: 'undefined',
      specificAuthorities: 'undefined',
      additionalInfo: '',
      referralInfo: '',
      internalNotes: '',
    };
  }, [
    emptyProperty,
    emptyPropertyOutside,
    emptyBankAccount,
    emptyLifeInsurance,
    emptyInvestment,
    emptySecurity,
    emptyPension,
    emptyCompany,
    emptyPowerOfAttorney,
  ]);

  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    if (form) {
      const { metadata } = form;
      const formatted = { ...metadata };
      Object.keys(metadata).forEach((key) => {
        if (key.indexOf('Phone') > -1) {
          formatted[`${key}_ignore_phone`] = metadata[key]?.value;
          formatted[`${key}_ignore_country_code`] = metadata[key]?.country_code;
        }
      });

      setFormData({ ...initialData, ...formatted });
    }
  }, [form, initialData]);

  // const phoneObj = Yup.object().shape({
  //   value: Yup.string().matches(/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/i, 'Invalid phone number'),
  //   country_code: Yup.string(),
  // });
  // TODO Phone Obj
  const validationSchema = Yup.object().shape({
    yourself: Yup.object().shape({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address'),
      dob: Yup.date().max(new Date(), 'Incorrect date'),
      // phone: phoneObj
    }),

    spouse: Yup.object().shape({
      email: Yup.string().email('Invalid email address'),
      // phone: phoneObj
    }),
  });

  const updateFormValues = () => {
    if (formikRef?.current) {
      const { values } = formikRef.current;
      if (formData !== values) setFormData(values);
    }
  };

  const handleSubmit = async (values: typeof initialData) => {
    setFormDisabled(true);
    const formattedValues = values;

    const keyToDelete = Object.keys(formattedValues).filter((key) => key.includes(`_ignore_`));

    keyToDelete.forEach((key) => {
      delete formattedValues[key as keyof typeof initialData];
    });

    updateFormValues();

    const payload = {
      customerId: currentCustomerId as string,
      clientId,
      formId,
      metadata: formattedValues,
      status: 'in progress',
    };
    // dispatch(updateForm(payload));
    setFormDisabled(false);

    setModalOpen(2);
  };

  const handleIndexFieldChange = (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps,
    fieldName: string
  ) => {
    const { name: formKey, value } = data;
    const key = (formKey as string).split('.').pop() as string;
    const index = (formKey as string).split('[')[1].split(']')[0] as unknown as number;
    if (formikRef?.current) {
      const { values, setValues } = formikRef.current;
      const newValues = cloneDeep(values);

      (newValues as any)[fieldName][index][key] = value;
      setValues(newValues);
      setFormData(newValues);
    }
  };

  const removeIndexField = (index: number, fieldName: string) => {
    if (formikRef?.current) {
      const { values } = formikRef.current;
      const clonedData = clone((formData as any)[fieldName]);
      clonedData.splice(index, 1);
      setFormData({ ...formData, ...values, [fieldName]: [...clonedData] });
    }
  };

  const handleRadioCheckboxChange = (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    const { name: formKey, type, checked, value } = data;
    const newVal = type === 'checkbox' ? (checked as boolean) : (value as string);
    if (formikRef?.current) {
      const { values, setValues } = formikRef.current;
      const newValues = cloneDeep(values);
      (newValues as Record<string, unknown>)[formKey as string] = newVal;
      setValues(newValues);
      setFormData(newValues);
    }
  };

  const cancel = () => {
    setModalOpen(0);
  };

  return (
    <>
      <ReadyAdminTopNav
        closePath
        callback={() => {
          setModalOpen(1);
        }}
      />
      <div className="Page_mainContent___full">
        <header className="pageTitle Form">
          <h4>Will Intake Form</h4>
        </header>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Formik<typeof initialData>
                enableReinitialize
                innerRef={formikRef}
                initialValues={formData}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={handleSubmit}
              >
                {(props) => (
                  <Form
                    size="large"
                    noValidate
                    onBlur={(e) => {
                      const { target } = e;

                      if (target.type !== 'checkbox') {
                        if (target.type === 'tel') {
                          if (target?.name?.indexOf('.') > -1) {
                            const parent = target?.name?.split('.')[0];
                            const child = target?.name?.split('.')[1];
                            const newKey = child?.split('_')[0];
                            const countryCode =
                              (formikRef.current &&
                                (formikRef.current.values as any)[newKey]?.country_code) ||
                              'CA';

                            const phoneVal = { country_code: countryCode, value: target.value };

                            setFormData({
                              ...formData,
                              [parent]: { ...(formData as any)[parent], [newKey]: phoneVal },
                            });

                            return;
                          }
                        }

                        if (target?.name?.indexOf('[') > -1) {
                          const parent = target?.name?.split('[')[0];
                          const regex = new RegExp(/\[(.*?)\]/g);
                          const regMatch = regex.exec(target?.name || '');
                          const index = parseInt(regMatch !== null ? regMatch[1] : '0', 10);
                          const child = target?.name?.split('.')[1];
                          const existingArray: Record<string, unknown>[] = [
                            ...(formData[parent as keyof typeof initialData] as []),
                          ];
                          const newChild = { ...existingArray[index], [child]: target.value };

                          existingArray.splice(index, 1, newChild);
                          setFormData({ ...formData, [parent]: existingArray });
                          return;
                        }
                        if (target?.name?.indexOf('.') > -1) {
                          const parent = target?.name?.split('.')[0];
                          const child = target?.name?.split('.')[1];

                          setFormData({
                            ...formData,
                            [parent]: { ...(formData as any)[parent], [child]: target.value },
                          });
                          return;
                        }
                      }
                      if (target.type && !includes(['checkbox', 'submit'], target.type)) {
                        setFormData({ ...formData, [target.name]: target.value });
                      }
                    }}
                  >
                    <RadioGroupInput
                      name="previousWill"
                      label="Do you have a previous Will(s)?"
                      value={formData.previousWill}
                      onChange={handleRadioCheckboxChange}
                    />
                    <br />
                    <Divider />
                    <h3>Yourself</h3>
                    <FormGroup widths="equal">
                      <TextInput
                        name="yourself.firstName"
                        label="First Name *"
                        disabled={formDisabled}
                      />
                      <TextInput
                        name="yourself.middleName"
                        label="Middle Name"
                        disabled={formDisabled}
                      />
                      <TextInput
                        name="yourself.lastName"
                        label="Last Name *"
                        disabled={formDisabled}
                      />
                    </FormGroup>
                    <FormGroup className="Form_notes">
                      <span className="Name_notes">
                        Please enter full legal name as per your government-issued identification
                        (ie Passport / Driver&apos;s License).
                      </span>
                      <CopyFields
                        label="Copy Full Name"
                        fields={['yourself.firstName', 'yourself.middleName', 'yourself.lastName']}
                      />
                    </FormGroup>
                    <FormGroup widths="equal">
                      <TextInput
                        name="yourself.occupation"
                        label="Occupation"
                        disabled={formDisabled}
                      />

                      <TextInput
                        name="yourself.citizenship"
                        label="Citizenship"
                        disabled={formDisabled}
                      />
                    </FormGroup>
                    <FormGroup widths="equal">
                      <DateWithCopyInput
                        name="yourself.dob"
                        label="Date of Birth"
                        disabled={formDisabled}
                      />

                      <TextInput
                        name="yourself.pob"
                        label="Place of Birth"
                        disabled={formDisabled}
                      />
                    </FormGroup>
                    <TextInput name="yourself.address" label="Address" disabled={formDisabled} />
                    <FormGroup widths="equal">
                      <EmailInput
                        name="yourself.email"
                        label="Email Address"
                        disabled={formDisabled}
                      />

                      <PhoneInput
                        name="yourself.phone"
                        label="Phone Number"
                        disabled={formDisabled}
                      />
                    </FormGroup>
                    <Divider />
                    <RadioGroupInput
                      name="haveSpouse"
                      label="Do you have a Spouse (including common-law spouse or partner)?"
                      value={formData.haveSpouse}
                      onChange={handleRadioCheckboxChange}
                    />
                    {formData.haveSpouse === '1' && (
                      <>
                        <FormGroup widths="equal">
                          <TextInput
                            name="spouse.firstName"
                            label="First Name *"
                            disabled={formDisabled}
                          />
                          <TextInput
                            name="spouse.middleName"
                            label="Middle Name"
                            disabled={formDisabled}
                          />
                          <TextInput
                            name="spouse.lastName"
                            label="Last Name *"
                            disabled={formDisabled}
                          />
                        </FormGroup>
                        <FormGroup className="Form_notes">
                          <span className="Name_notes">
                            Please enter full legal name as per your government-issued
                            identification (ie Passport / Driver&apos;s License).
                          </span>
                          <CopyFields
                            label="Copy Full Name"
                            fields={['spouse.firstName', 'spouse.middleName', 'spouse.lastName']}
                          />
                        </FormGroup>
                        <FormGroup widths="equal">
                          <TextInput
                            name="spouse.occupation"
                            label="Occupation"
                            disabled={formDisabled}
                          />

                          <TextInput
                            name="spouse.citizenship"
                            label="Citizenship"
                            disabled={formDisabled}
                          />
                        </FormGroup>
                        <FormGroup widths="equal">
                          <DateWithCopyInput
                            name="spouse.dob"
                            label="Date of Birth"
                            disabled={formDisabled}
                          />
                          <TextInput
                            name="spouse.pob"
                            label="Place of Birth"
                            disabled={formDisabled}
                          />
                        </FormGroup>
                        <TextInput name="spouse.address" label="Address" disabled={formDisabled} />
                        <FormGroup widths="equal">
                          <EmailInput
                            name="spouse.email"
                            label="Email Address"
                            disabled={formDisabled}
                          />

                          <PhoneInput
                            name="spouse.phone"
                            label="Phone Number"
                            disabled={formDisabled}
                          />
                        </FormGroup>
                      </>
                    )}
                    <Divider />
                    <h3>Children</h3>
                    <TextAreaInput
                      name="children"
                      label="Names of Children from this relationship / marriage (relationship - age):"
                      disabled={formDisabled}
                    />
                    <TextAreaInput
                      name="childrenPrevious"
                      label="Children from previous relationship / marriage  (names - relationship - age):"
                      disabled={formDisabled}
                    />
                    <RadioGroupInput
                      name="childDisability"
                      label="Does any child have a physical or mental disability?"
                      value={formData.childDisability}
                      onChange={handleRadioCheckboxChange}
                    />
                    <Divider />
                    <h3>Assets</h3>
                    <h4>Properties</h4>
                    {formData.properties.length &&
                      formData.properties.map((a, index) => (
                        <PropertyFields
                          index={index}
                          removeIndexField={removeIndexField}
                          formDisabled={formDisabled}
                          formData={formData}
                          handleIndexFieldChange={(e, data) =>
                            handleIndexFieldChange(e, data, 'properties')
                          }
                          key={a.address}
                        />
                      ))}
                    <br />
                    <Button
                      type="button"
                      primary
                      className="Button_shape___sharp"
                      onClick={() => {
                        if (formikRef?.current) {
                          const { values } = formikRef.current;
                          setFormData({
                            ...values,
                            properties: [...values.properties, emptyProperty],
                          });
                        }
                      }}
                    >
                      Add another Property
                    </Button>
                    <br />
                    <br />
                    <h4>Properties outside of British Columbia</h4>
                    <RadioGroupInput
                      name="havePropertiesOutside"
                      label="Do you own any real or personal property outside of British Columbia?"
                      value={formData.havePropertiesOutside}
                      onChange={handleRadioCheckboxChange}
                    />
                    {formData.havePropertiesOutside === '1' && (
                      <>
                        {formData.propertiesOutside.length &&
                          formData.propertiesOutside.map((a, index) => (
                            <PropertiesOutsideFields
                              index={index}
                              removeIndexField={removeIndexField}
                              formDisabled={formDisabled}
                              key={a.address}
                            />
                          ))}
                        <br />
                        <Button
                          type="button"
                          primary
                          className="Button_shape___sharp"
                          onClick={() => {
                            if (formikRef?.current) {
                              const { values } = formikRef.current;
                              setFormData({
                                ...values,
                                propertiesOutside: [
                                  ...values.propertiesOutside,
                                  emptyPropertyOutside,
                                ],
                              });
                            }
                          }}
                        >
                          Add another Property
                        </Button>
                        <br />
                        <br />
                      </>
                    )}
                    <h4>Bank Accounts</h4>
                    {formData.bankAccounts.length &&
                      formData.bankAccounts.map((a, index) => (
                        <BankFields
                          index={index}
                          removeIndexField={removeIndexField}
                          formDisabled={formDisabled}
                          key={a.bank}
                        />
                      ))}
                    <br />
                    <Button
                      type="button"
                      primary
                      className="Button_shape___sharp"
                      onClick={() => {
                        if (formikRef?.current) {
                          const { values } = formikRef.current;
                          setFormData({
                            ...values,
                            bankAccounts: [...values.bankAccounts, emptyBankAccount],
                          });
                        }
                      }}
                    >
                      Add another Bank Account
                    </Button>
                    <br />
                    <br />
                    <h4> Life Insurance</h4>
                    {formData.lifeInsurances.length &&
                      formData.lifeInsurances.map((a, index) => (
                        <LifeInsuranceFields
                          index={index}
                          removeIndexField={removeIndexField}
                          formDisabled={formDisabled}
                          key={a.companyName}
                        />
                      ))}
                    <br />

                    <Button
                      type="button"
                      primary
                      className="Button_shape___sharp"
                      onClick={() => {
                        if (formikRef?.current) {
                          const { values } = formikRef.current;
                          setFormData({
                            ...values,
                            lifeInsurances: [...values.lifeInsurances, emptyLifeInsurance],
                          });
                        }
                      }}
                    >
                      Add another Life Insurance
                    </Button>
                    <br />
                    <br />
                    <h4>RRSPs / RRIFS</h4>
                    {formData.investments.length &&
                      formData.investments.map((a, index) => (
                        <InvestmentFields
                          index={index}
                          removeIndexField={removeIndexField}
                          formDisabled={formDisabled}
                          key={a.institutionName}
                        />
                      ))}
                    <br />
                    <Button
                      type="button"
                      primary
                      className="Button_shape___sharp"
                      onClick={() => {
                        if (formikRef?.current) {
                          const { values } = formikRef.current;
                          setFormData({
                            ...values,
                            investments: [...values.investments, emptyInvestment],
                          });
                        }
                      }}
                    >
                      Add another RRSPs / RRIFS
                    </Button>
                    <br />
                    <br />
                    <h4>Securities (Stocks/bonds/Mutual funds)</h4>
                    {formData.securities.length &&
                      formData.securities.map((a, index) => (
                        <SecurityFields
                          index={index}
                          removeIndexField={removeIndexField}
                          formDisabled={formDisabled}
                          key={a.description}
                        />
                      ))}
                    <br />
                    <Button
                      type="button"
                      primary
                      className="Button_shape___sharp"
                      onClick={() => {
                        if (formikRef?.current) {
                          const { values } = formikRef.current;
                          setFormData({
                            ...values,
                            securities: [...values.securities, emptySecurity],
                          });
                        }
                      }}
                    >
                      Add another Security
                    </Button>
                    <br />
                    <br />
                    <h4>Pension Plans</h4>
                    {formData.pensions.length &&
                      formData.pensions.map((a, index) => (
                        <PensionFields
                          index={index}
                          removeIndexField={removeIndexField}
                          formDisabled={formDisabled}
                          key={a.description}
                        />
                      ))}
                    <br />
                    <Button
                      type="button"
                      primary
                      className="Button_shape___sharp"
                      onClick={() => {
                        if (formikRef?.current) {
                          const { values } = formikRef.current;
                          setFormData({
                            ...values,
                            pensions: [...values.pensions, emptyPension],
                          });
                        }
                      }}
                    >
                      Add another Pension
                    </Button>
                    <br />
                    <br />
                    <h4>Company</h4>
                    <RadioGroupInput
                      name="ownCompany"
                      label="Do you own a company?"
                      value={formData.ownCompany}
                      onChange={handleRadioCheckboxChange}
                    />
                    {formData.ownCompany === '1' && (
                      <>
                        {formData.companies.length &&
                          formData.companies.map((a, index) => (
                            <CompanyFields
                              index={index}
                              removeIndexField={removeIndexField}
                              formDisabled={formDisabled}
                              key={a.companyName}
                              formData={formData}
                              handleIndexFieldChange={(e, data) =>
                                handleIndexFieldChange(e, data, 'companies')
                              }
                            />
                          ))}
                        <br />
                        <Button
                          type="button"
                          primary
                          className="Button_shape___sharp"
                          onClick={() => {
                            if (formikRef?.current) {
                              const { values } = formikRef.current;
                              setFormData({
                                ...values,
                                companies: [...values.companies, emptyCompany],
                              });
                            }
                          }}
                        >
                          Add another Company
                        </Button>
                        <br />
                        <br />
                      </>
                    )}
                    <h4>Other Interests and Assets</h4>
                    <TextInput name="otherAssets" label="Name" disabled={formDisabled} />
                    <TextAreaInput
                      name="otherAssetsDescription"
                      label="Description"
                      disabled={formDisabled}
                    />
                    <h4>Liabilities</h4>
                    <TextAreaInput
                      name="liabilitiesDescription"
                      label="Description"
                      disabled={formDisabled}
                    />
                    <MoneyInput
                      name="netWorth"
                      label="Approximate Net Worth"
                      disabled={formDisabled}
                      className="Form_input___halfWidth"
                    />
                    <RadioGroupInput
                      name="safetyDeposit"
                      label="Do you have a Safety Deposit Box?"
                      value={formData.safetyDeposit}
                      onChange={handleRadioCheckboxChange}
                    />
                    {formData.safetyDeposit === '1' && (
                      <TextInput name="safetyDepositNotes" label="Notes" disabled={formDisabled} />
                    )}
                    <RadioGroupInput
                      name="anyoneOwnYouMoney"
                      label="Does anyone owe you any money?"
                      value={formData.anyoneOwnYouMoney}
                      onChange={handleRadioCheckboxChange}
                    />
                    {formData.anyoneOwnYouMoney === '1' && (
                      <Form.Group widths="equal">
                        <TextInput
                          name="whoOwnsYouMoney"
                          label="Name of Person(s) who owe"
                          disabled={formDisabled}
                        />
                        <TextInput name="amountOwned" label="Amount" disabled={formDisabled} />
                      </Form.Group>
                    )}
                    <Divider />
                    <h4>Appointment of Executor / Trustees</h4>
                    <RadioGroupInput
                      name="spousesAppointFirst"
                      label="Spouses appointing each other first?"
                      value={formData.spousesAppointFirst}
                      onChange={handleRadioCheckboxChange}
                    />
                    <TextAreaInput
                      name="alternateExecutor"
                      label="Alternate Executor(s) – (note order, relationship, acting together or separate)"
                      // value={formData.alternateExecutor}
                      // onChange={handleRadioCheckboxChange}
                      disabled={formDisabled}
                    />
                    <TextAreaInput
                      name="guardianInformation"
                      label="Guardian Information (Children from this marriage – note order, relationship, acting together or separate):"
                      disabled={formDisabled}
                    />
                    <Divider />
                    <h4>Power of Attorney</h4>
                    <RadioGroupInput
                      name="executeNow"
                      label="Do you wish to execute a Power of Attorney at this time?"
                      value={formData.executeNow}
                      onChange={handleRadioCheckboxChange}
                    />
                    {formData.executeNow === '1' && (
                      <>
                        <RadioGroupInput
                          name="poaSpousesAppointFirst"
                          label="Spouses appointing each other first?"
                          value={formData.poaSpousesAppointFirst}
                          onChange={handleRadioCheckboxChange}
                        />
                        {formData.powerOfAttorneys.map((a, index) => (
                          <PowerOfAttorneyFields
                            index={index}
                            removeIndexField={removeIndexField}
                            formDisabled={formDisabled}
                            key={a.fullName}
                            formData={formData}
                            handleIndexFieldChange={(e, data) =>
                              handleIndexFieldChange(e, data, 'powerOfAttorneys')
                            }
                          />
                        ))}
                        <Button
                          type="button"
                          primary
                          className="Button_shape___sharp"
                          onClick={() => {
                            if (formikRef?.current) {
                              const { values } = formikRef.current;
                              setFormData({
                                ...values,
                                powerOfAttorneys:
                                  formData.poaSpousesAppointFirst === '1' &&
                                  !values.powerOfAttorneys
                                    ? [emptyPowerOfAttorney]
                                    : [...values.powerOfAttorneys, emptyPowerOfAttorney],
                              });
                            }
                          }}
                        >
                          Add another Attorney
                        </Button>
                        <br />
                        <br />
                        <RadioGroupInput
                          name="attorneysActTogether"
                          label="If more than one attorney is appointed, must they act together OR may they act separately?"
                          value={formData.attorneysActTogether}
                          onChange={handleRadioCheckboxChange}
                        />
                        <RadioGroupInput
                          name="attorneysActSeparately"
                          label="If Attorneys are to act separately, would you like one Attorney to act 1st before the other? "
                          value={formData.attorneysActSeparately}
                          onChange={handleRadioCheckboxChange}
                        />

                        <RadioGroupInput
                          name="attorneyCompensated"
                          label="Is the person you appoint as your attorney to be compensated for acting as your attorney?"
                          value={formData.attorneyCompensated}
                          onChange={handleRadioCheckboxChange}
                        />
                        <RadioGroupInput
                          name="attorneyRemainsEffective"
                          label="Do you wish the Power of Attorney to only become effective during your mental infirmity?"
                          value={formData.attorneyRemainsEffective}
                          onChange={handleRadioCheckboxChange}
                        />
                        <RadioGroupInput
                          name="specificAuthorities"
                          label="Do you wish to provide for any specific authorities in your Power of Attorney form?"
                          value={formData.specificAuthorities}
                          onChange={handleRadioCheckboxChange}
                        />
                      </>
                    )}
                    <Divider />
                    <RadioGroupInput
                      name="representationAgreement"
                      label="Do you wish to have a Representation Agreement?"
                      value={formData.representationAgreement}
                      options={[
                        { value: '1', label: 'Yes' },
                        { value: '0', label: 'No' },
                        { value: 'dontKnow', label: 'Do not know' },
                      ]}
                      onChange={handleRadioCheckboxChange}
                    />
                    <br />
                    <TextAreaInput
                      name="additionalInfo"
                      label="Additional Information"
                      disabled={formDisabled}
                    />

                    <Divider />

                    <TextInput
                      name="referralInfo"
                      label="How were you referred to our office?"
                      disabled={formDisabled}
                    />

                    <Divider />

                    <TextAreaInput
                      name="internalNotes"
                      label="Internal Notes"
                      disabled={formDisabled}
                      placeholder="Internal notes - All notes within this section will only be seen by your office"
                    />

                    <Divider />

                    <div className="Button_floatRight">
                      <Button
                        content="Save and Close"
                        // eslint-disable-next-line react/prop-types
                        disabled={!props.isValid || loading}
                        onClick={() => {
                          if (formikRef && formikRef.current) {
                            formikRef.current.submitForm();
                          }
                        }}
                        primary
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {/* TODO Reuse for other forms */}
        <Transition visible={modalOpen === 1} animation="scale" duration={400}>
          <Modal closeIcon size="tiny" open={modalOpen === 1} onClose={cancel}>
            <Header icon>Are you sure you want to quit?</Header>
            <Modal.Content>
              <Modal.Description style={{ margin: '20px' }}>
                The data entered for this form won’t be saved.
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button name="cancel" onClick={cancel}>
                Cancel
              </Button>
              <Button primary name="yes" onClick={() => navigate(-1)}>
                Yes, I’m sure
              </Button>
            </Modal.Actions>
          </Modal>
        </Transition>

        <Transition visible={modalOpen === 2} animation="scale" duration={400}>
          <Modal open={modalOpen === 2} onClose={cancel}>
            <Header>Thank you for submitting the client intake form</Header>
            <Modal.Content>
              <Modal.Description style={{ marginTop: '20px', textAlign: 'center' }}>
                Your client will receive an email notifying them that they can access the form
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button primary name="continue" onClick={() => navigate(-1)}>
                Continue
              </Button>
            </Modal.Actions>
          </Modal>
        </Transition>
      </div>
    </>
  );
};

export default FormWill;
