import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Grid, Message } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReadyAdminTable } from 'readywhen-ui-components';
import { RootState } from '../../app/rootReducer';
import ReadyAdminSideNav from '../../components/ReadyAdminSideNav';
import ReadyAdminTopNav from '../../components/ReadyAdminTopNav';
import AddEmployeesFormPage from './AddEmployeesFormPage';
import { employeesSelectors, fetchEmployees } from './employeesSlice';
import { selectCustomers } from '../customers/customersSlice';
import ReadyAdminEmptyPage from '../../components/ReadyAdminEmptyPage';
import { Client } from '../../api/interfaces';
import { AppDispatch } from '../../app/store';

const EmployeesPage = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const employees = useSelector(employeesSelectors.selectAll);
  const { currentCustomerId } = useSelector(selectCustomers);
  const { loading, entities } = useSelector((state: RootState) => state.employees);
  const [employeeId, setEmployeeId] = useState<string | null>(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [addEmployeesModalOpen, setAddEmployeesModalOpen] = useState(false);

  const closeModal = () => {
    setEmployeeId(null);
    setAddEmployeesModalOpen(false);
  };

  useEffect(() => {
    if (currentCustomerId) {
      dispatch(fetchEmployees(currentCustomerId));
      setDataFetched(true);
    }
  }, [currentCustomerId, dispatch]);

  const columns = [
    { field: 'id', headerName: 'Name', type: 'employee', sorted: 'ascending' },
    { field: 'email', headerName: 'Email', sorted: 'ascending' },
    { field: 'title', headerName: 'Role', sorted: 'ascending' },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      children: (rowData: Client) => {
        const { id } = rowData;
        return (
          <FontAwesomeIcon
            id={id}
            style={{ cursor: 'pointer', marginLeft: '10px' }}
            icon={['far', 'edit']}
            onClick={(event) => {
              if (event.currentTarget.id) {
                setEmployeeId(event.currentTarget.id as string);
                setAddEmployeesModalOpen(true);
              }
            }}
          />
        );
      },
    },
  ];

  const AddEmployeeButton = (): JSX.Element => {
    return (
      <Button
        primary
        className={employees.length ? 'Button__Add___right' : 'Button__Add___centered'}
        onClick={() => setAddEmployeesModalOpen(true)}
      >
        Add Employee
      </Button>
    );
  };

  return (
    <>
      <ReadyAdminTopNav />
      <Grid divided>
        <Grid.Column width={3}>
          <ReadyAdminSideNav />
        </Grid.Column>

        <Grid.Column width={13} className="Page_background">
          <div className="Page_container">
            <Container style={{ marginTop: '20px' }}>
              {employees.length || loading ? (
                <>
                  <Grid>
                    <Grid.Column width={10}>
                      <h1>Your Employees</h1>
                      <p>
                        Add employees so they can purchase licenses and enter information on your
                        behalf
                      </p>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <AddEmployeeButton />
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Message>
                        <p>
                          <FontAwesomeIcon icon={['far', 'exclamation-circle']} /> Note: Each
                          employee is required to have an active <strong>ReadyWhen</strong> account.
                        </p>
                      </Message>
                    </Grid.Column>
                  </Grid>

                  <ReadyAdminTable
                    pageName="employees"
                    loading={loading}
                    columns={columns}
                    rows={employees}
                    employeesMap={entities}
                  />
                </>
              ) : (
                <ReadyAdminEmptyPage
                  dataFetched={dataFetched}
                  icon={['far', 'briefcase']}
                  content="You do not have any employees listed"
                  button={<AddEmployeeButton />}
                />
              )}
              {addEmployeesModalOpen ? (
                <AddEmployeesFormPage
                  employeeId={employeeId as string}
                  closeModalCallback={closeModal}
                  addEmployeesModalOpen={addEmployeesModalOpen}
                />
              ) : (
                <></>
              )}
            </Container>
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default EmployeesPage;
