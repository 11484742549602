/* eslint-disable react/jsx-props-no-spreading */

import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'formik-semantic-ui-react';
import { useFormikContext } from 'formik';
import { DropdownProps } from 'semantic-ui-react';
import { DropdownInput } from 'readywhen-ui-components';
import { selectCustomers } from '../features/customers/customersSlice';
import { fetchStaff, staffSelectors } from '../features/staff/staffSlice';
import { AppDispatch } from '../app/store';
import { selectLogin } from '../features/login/loginSlice';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function ReadyAdminStaffDropdown(props: {
  name: string;
  disabled: boolean;
}): JSX.Element {
  const { name, ...rest } = props;

  const { values, setFieldValue } = useFormikContext<Record<string, string>>() ?? {};
  const dispatch: AppDispatch = useDispatch();
  const [staffOptions, setStaffOptions] = useState<Record<string, string>[]>([]);
  const { currentCustomerId } = useSelector(selectCustomers);
  const staff = useSelector(staffSelectors.selectAll);
  const { currentUser } = useSelector(selectLogin);

  useEffect(() => {
    if (currentCustomerId) {
      dispatch(fetchStaff(currentCustomerId));
    }
  }, [currentCustomerId, dispatch]);

  useEffect(() => {
    if (staff.length) {
      const options = staff.map((s) => {
        if (s.userId === currentUser?.id) {
          if (values[name || 'staff'] === '') {
            setFieldValue(name, [s.id]);
          }
        }

        return {
          key: s.id as string,
          text: `${s.firstName} ${s.lastName}`,
          value: s.id as string,
        };
      });

      if (!staffOptions.length) setStaffOptions(options);
    }
  }, [currentUser, name, setFieldValue, staff, staffOptions, values]);

  return (
    <>
      <DropdownInput
        fluid
        multiple
        search
        selection
        options={staffOptions}
        name="staff_ignore"
        label="Assign Staff"
        placeholder="Select one or multiple"
        value={values[name || 'staff']}
        onChange={(_event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
          const dropdownValue = data?.value;

          if (values[name || 'staff'] !== dropdownValue)
            setFieldValue(name || 'staff', dropdownValue);
        }}
        {...rest}
      />
      <Input name={name || 'staff'} type="hidden" />
    </>
  );
}
