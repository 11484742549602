/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { Button, FormGroup, Grid, Header, Icon, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import { toast } from 'react-semantic-toasts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const formErrorMessage = (message: string) => {
  toast({
    type: 'error',
    icon: 'exclamation circle',
    title: 'Change Password Failed',
    description: message,
    animation: 'drop',
    time: 10000,
  });
};

const ChangePassword = (): JSX.Element => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);

  const togglePasswordMasks = () => {
    setPasswordShown(!passwordShown);
  };

  const initialValues = {
    password: '',
    new_password: '',
    repeated_new_password: '',
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, 'Your password must be at least 8 characters long')
      .matches(
        /(?=.*\d)(?=.*[!@#$%^&.*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        'The password must contain one or more uppercase, lowercase, special characters and numeric values'
      )
      .required('Required'),
    new_password: Yup.string()
      .min(8, 'Your password must be at least 8 characters long')
      .matches(
        /(?=.*\d)(?=.*[!@#$%^&.*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        'The password must contain one or more uppercase, lowercase, special characters and numeric values'
      )
      .required('Required'),
    repeated_new_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], 'New passwords must match')
      .required('Required'),
  });

  const handleSubmit = async (
    values: typeof initialValues,
    actions: FormikHelpers<{
      password: string;
      new_password: string;
      repeated_new_password: string;
    }>
  ) => {
    setFormDisabled(true);

    const { password, new_password: newPassword } = values;

    const user = await Auth.currentAuthenticatedUser();

    try {
      const response = await Auth.changePassword(user, password, newPassword);
      if (response === 'SUCCESS') {
        setModalOpen(true);
        actions.resetForm();
      }
    } catch (e) {
      if (typeof e === 'string') {
        formErrorMessage(e.toUpperCase());
      } else if (e instanceof Error) {
        formErrorMessage(e.message);
      }
    }
    setFormDisabled(false);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, actions) => handleSubmit(values, actions)}
      >
        <Form size="large" noValidate>
          <section className="Card Card_Settings">
            <h4>
              <strong>Change Password</strong>
            </h4>
            <div style={{ marginLeft: -22 }}>
              <FormGroup widths="equal">
                <Icon
                  name={passwordShown ? 'eye slash' : 'eye'}
                  onClick={togglePasswordMasks}
                  className="PasswordIcon"
                />
                <Input
                  name="password"
                  label="Password *"
                  type={passwordShown ? 'text' : 'password'}
                  autoComplete="password"
                  placeholder="Enter your password"
                  style={{ width: '48.4%' }}
                  errorPrompt
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Icon
                  name={passwordShown ? 'eye slash' : 'eye'}
                  onClick={togglePasswordMasks}
                  className="PasswordIcon"
                />
                <Input
                  name="new_password"
                  label="New Password *"
                  disabled={formDisabled}
                  type={passwordShown ? 'text' : 'password'}
                  autoComplete="nope"
                  placeholder="Enter your new password"
                  errorPrompt
                />
                <Icon
                  name={passwordShown ? 'eye slash' : 'eye'}
                  onClick={togglePasswordMasks}
                  className="PasswordIcon"
                />
                <Input
                  name="repeated_new_password"
                  label="Confirm New Password *"
                  type={passwordShown ? 'text' : 'password'}
                  autoComplete="repeated_new_password"
                  placeholder="Repeat your new password"
                  errorPrompt
                />
              </FormGroup>
            </div>
            <br />
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={4}>
                  <SubmitButton primary>Update Password</SubmitButton>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </section>
        </Form>
      </Formik>
      <Modal
        closeIcon
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        className="Modal___centered"
      >
        <Header icon>
          <FontAwesomeIcon className="Header_graphic___center" icon={['far', 'check-circle']} />
          <br />
          Thank you!
        </Header>
        <Modal.Content style={{ textAlign: 'center' }}>
          <Modal.Description>Your Password was successfully changed!</Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Continue"
            onClick={() => {
              setModalOpen(false);
            }}
            primary
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ChangePassword;
