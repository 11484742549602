import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button, Container, Grid, Header, Modal, Input } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-semantic-toasts';
import _, { debounce } from 'lodash';
import { unwrapResult } from '@reduxjs/toolkit';
import { ReadyAdminTable } from 'readywhen-ui-components';
import { RootState } from '../../app/rootReducer';
import ReadyAdminSideNav from '../../components/ReadyAdminSideNav';
import ReadyAdminTopNav from '../../components/ReadyAdminTopNav';
import { fetchEmployees } from '../employees/employeesSlice';

import {
  clientsSelectors,
  removeOneClient,
  fetchClients,
  resendInvite,
  initClientFiles,
} from './clientsSlice';
import ReadyAdminEmptyPage from '../../components/ReadyAdminEmptyPage';
import { selectCustomers } from '../customers/customersSlice';
import { Client, ResendInvitePayload } from '../../api/interfaces';
import { AppDispatch } from '../../app/store';

interface SignUpButtonProps {
  floatRight?: boolean;
}

const SignUpClientButton = ({ floatRight }: SignUpButtonProps): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Button
      primary
      className={floatRight ? 'Button__Add___right' : 'Button__Add___centered'}
      onClick={() => {
        navigate('/clients/sign-up');
      }}
    >
      Sign up a Client
    </Button>
  );
};

SignUpClientButton.defaultProps = {
  floatRight: false,
};

function formErrorMessage(payload: string) {
  toast({
    type: 'error',
    icon: 'exclamation circle',
    title: 'Error',
    description: payload,
    animation: 'drop',
    time: 10000,
  });
}
function formSuccessMessage(payload: string) {
  toast({
    type: 'success',
    icon: 'check circle',
    title: 'Success',
    description: payload,
    animation: 'drop',
    time: 10000,
  });
}

const ClientsPage = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const clients = useSelector(clientsSelectors.selectAll);
  const { currentCustomerId } = useSelector(selectCustomers);
  const { loading, metadata, entities } = useSelector((state: RootState) => state.clients);
  const { entities: employeesEntities } = useSelector((state: RootState) => state.employees);
  const [clientId, setClientId] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState<number>(0);
  const [dataFetched, setDataFetched] = useState(false);
  const [page, setPage] = useState({
    currentPage: metadata?.page ? parseInt(metadata?.page as string, 10) : 1,
    pageSize: parseInt(metadata?.limit as string, 10) || 25,
  });
  const [search, setSearch] = useState('');

  const client = useSelector((state) => clientsSelectors.selectById(state, clientId as string));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((nextValue) => {
      if (currentCustomerId)
        dispatch(
          fetchClients({
            customerId: currentCustomerId,
            page: page.currentPage,
            size: page.pageSize,
            search: nextValue,
          })
        );
    }, 600),
    [currentCustomerId]
  );

  useEffect(() => {
    if (currentCustomerId) {
      dispatch(initClientFiles());
      dispatch(
        fetchClients({ customerId: currentCustomerId, page: page.currentPage, size: page.pageSize })
      );
      dispatch(fetchEmployees(currentCustomerId));
      setDataFetched(true);
    }
  }, [currentCustomerId, dispatch, page]);

  const columns = [
    {
      field: 'id',
      headerName: 'Name',
      type: 'client',
      sortBy: 'last_name',
      sorted: metadata?.sort_by === 'last_name' ? metadata?.sort_order : 'ascending',
    },
    {
      field: 'email',
      headerName: 'Email',
      sorted: metadata?.sort_by === 'email' ? metadata?.sort_order : 'ascending',
    },
    { field: 'employeeId', headerName: 'Employee', type: 'employee', sorted: false },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      type: 'date',
      sortBy: 'created_at',
      sorted: metadata?.sort_by === 'created_at' ? metadata?.sort_order : 'ascending',
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'status',
      sorted: metadata?.sort_by === 'status' ? metadata?.sort_order : 'ascending',
    },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      children: (rowData: Client) => {
        const { id, status } = rowData;

        return (
          <Fragment key={`actions-${id}`}>
            {status && _.includes(['pending'], status) && (
              <FontAwesomeIcon
                data-id={id}
                className="Table_actionButtons"
                icon={['far', 'envelope']}
                title="Re-send Invite"
                onClick={() => {
                  setClientId(id);
                  setModalOpen(3);
                }}
              />
            )}
            {status && _.includes(['draft', 'pending'], status) && (
              <FontAwesomeIcon
                data-id={id}
                className="Table_actionButtons"
                icon={['far', 'trash']}
                title="Delete"
                onClick={(event) => {
                  if (event.currentTarget.dataset.id)
                    setClientId(event.currentTarget.dataset.id as string);

                  setModalOpen(1);
                }}
              />
            )}
          </Fragment>
        );
      },
    },
  ];

  const closeModal = () => {
    setModalOpen(0);
  };

  return (
    <>
      <ReadyAdminTopNav />
      <Grid divided>
        <Grid.Column width={3}>
          <ReadyAdminSideNav />
        </Grid.Column>

        <Grid.Column width={13} className="Page_background">
          <div className="Page_container">
            <Container style={{ marginTop: '20px' }}>
              {clients.length || loading || search !== '' ? (
                <>
                  <Grid>
                    <Grid.Column width={10}>
                      <h1>Clients</h1>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <SignUpClientButton floatRight />
                    </Grid.Column>
                  </Grid>
                  <Input
                    icon="search"
                    iconPosition="left"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (search !== value) setSearch(value);
                      debouncedSearch(encodeURIComponent(value.trim()));
                    }}
                  />
                  {search !== '' && (
                    <a
                      href="#reset"
                      className="Search_reset"
                      onClick={(e) => {
                        e.preventDefault();
                        setSearch('');

                        if (currentCustomerId)
                          dispatch(
                            fetchClients({
                              customerId: currentCustomerId,
                              page: page.currentPage,
                              size: page.pageSize,
                            })
                          );
                      }}
                    >
                      Reset
                    </a>
                  )}
                  <ReadyAdminTable
                    pageName="clients"
                    loading={loading}
                    columns={columns}
                    rows={clients}
                    clientsMap={entities}
                    employeesMap={employeesEntities}
                    serverPagination
                    metadata={metadata}
                    serverCallback={(payload) => {
                      setPage({ currentPage: payload.page, pageSize: payload.size });
                      if (currentCustomerId)
                        dispatch(
                          fetchClients({
                            customerId: currentCustomerId,
                            ...payload,
                          })
                        );
                    }}
                  />
                  {/* 1 */}
                  <Modal
                    closeIcon
                    onClose={() => closeModal()}
                    open={modalOpen === 1}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    className="Modal___centered"
                  >
                    <Modal.Header as="h4">
                      Are you sure you want to delete {client?.firstName} {client?.lastName}?
                    </Modal.Header>
                    <Modal.Content />
                    <Modal.Actions>
                      <Button
                        content="Cancel"
                        onClick={() => {
                          closeModal();
                        }}
                        className="Form_button___half"
                      />

                      <Button
                        content="Yes, I'm Sure"
                        onClick={async () => {
                          if (currentCustomerId && clientId) {
                            const resultAction = await dispatch(
                              removeOneClient({ customerId: currentCustomerId, clientId })
                            );

                            if (removeOneClient.fulfilled.match(resultAction)) {
                              closeModal();
                              formSuccessMessage(
                                `${client?.firstName} ${client?.lastName} is deleted`
                              );
                            }
                            if (removeOneClient.rejected.match(resultAction)) {
                              formErrorMessage(resultAction.payload as string);
                            }
                          }
                        }}
                        className="Form_button___half"
                        primary
                      />
                    </Modal.Actions>
                  </Modal>
                  {/* 2 */}
                  <Modal
                    closeIcon
                    onClose={() => closeModal()}
                    open={modalOpen === 2}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    className="Modal___centered"
                  >
                    <Header icon>
                      <FontAwesomeIcon
                        icon={['far', 'check-circle']}
                        className="Header_graphic___center"
                      />
                      <br />
                      Thank you!
                    </Header>
                    <Modal.Content>
                      <Modal.Description>
                        Your account information was successfully changed!
                      </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        content="Continue"
                        onClick={() => {
                          closeModal();
                        }}
                        className="Form_button"
                        primary
                      />
                    </Modal.Actions>
                  </Modal>
                  {/* 3 */}
                  <Modal
                    closeIcon
                    onClose={() => closeModal()}
                    open={modalOpen === 3}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    className="Modal___centered"
                  >
                    <Header icon>
                      <FontAwesomeIcon
                        icon={['far', 'envelope']}
                        className="Header_graphic___center"
                      />
                      <br />
                      Would you like to re-send the invite?
                    </Header>
                    <Modal.Content>
                      <Modal.Description>
                        <br />
                        Your client will receive an email with instructions in order to <br />
                        complete the process of creating their ReadyWhen account.
                      </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        style={{ width: 130 }}
                        content="Cancel"
                        className="Form_button___half"
                        onClick={() => {
                          closeModal();
                        }}
                      />
                      <Button
                        style={{ width: 130 }}
                        primary
                        content="Yes"
                        className="Form_button___half"
                        onClick={async () => {
                          if (clientId) {
                            const fileData: ResendInvitePayload = {
                              customerId: currentCustomerId,
                              id: clientId,
                            };
                            try {
                              const result = await dispatch(resendInvite(fileData));
                              unwrapResult(result);
                              setModalOpen(4);
                            } catch (err) {
                              formErrorMessage(err as string);
                            }
                          }
                        }}
                      />
                    </Modal.Actions>
                  </Modal>
                  {/* 4 */}
                  <Modal
                    closeIcon
                    onClose={() => closeModal()}
                    open={modalOpen === 4}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    className="Modal___centered"
                  >
                    <Header icon>
                      <FontAwesomeIcon
                        icon={['far', 'check-circle']}
                        className="Header_graphic___center"
                      />
                      <br />
                      Thank you!
                    </Header>
                    <Modal.Content>
                      <Modal.Description>The invite was successfully sent!</Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        content="Continue"
                        onClick={() => {
                          closeModal();
                        }}
                        className="Form_button"
                        primary
                      />
                    </Modal.Actions>
                  </Modal>
                </>
              ) : (
                search === '' && (
                  <ReadyAdminEmptyPage
                    dataFetched={dataFetched}
                    icon={['far', 'users']}
                    content="No Clients Signed up yet"
                    button={<SignUpClientButton />}
                  />
                )
              )}
            </Container>
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default ClientsPage;
