/* eslint-disable @typescript-eslint/no-explicit-any */
import AWS, { AWSError } from 'aws-sdk';
import { API } from 'aws-amplify';
import { PromiseResult } from 'aws-sdk/lib/request';
import { v4 as uuidv4 } from 'uuid';
import { FileInfo, S3 } from 'readywhen-ui-components';

import { PutObjectOutput } from 'aws-sdk/clients/s3';
import config from '../configs/config';

import {
  IntakeFormsResponse,
  IntakeForm,
  IntakeFormsPayload,
  FormPayload,
} from '../app/interfaces';

import {
  Client,
  Document,
  License,
  Employee,
  Staff,
  Customer,
  CustomerPayload,
  Message,
  Form,
  ClientSignUpFormPayload,
  EditClientPayload,
  EmployeesAddFormPayload,
  ErrorProps,
  ResendInvitePayload,
  StaffAddFormPayload,
  PatchFormPayload,
} from './interfaces';

// TODO fix willRegistration shows twice in payload TEST
export async function postDocumentS3(
  file: File,
  key: string,
  section: string
): Promise<AWS.S3.ManagedUpload.SendData | PromiseResult<PutObjectOutput, AWSError>> {
  return S3.uploadFile(file, config.s3.BUCKET, key, section, () => {});
}

export async function deleteDocumentS3(
  key: string,
  section: string
): Promise<PromiseResult<AWS.S3.DeleteObjectOutput, AWS.AWSError>> {
  return S3.deleteFile(config.s3.BUCKET, key, section);
}

export function processErrorMessage(err: ErrorProps): string {
  let message = 'Server Error';

  if (err.response) {
    if (err.response.data && err.response.data.message) {
      message = err.response.data.message;
    }
    if (err.response.message) {
      message = err.response.message;
    }
  }

  if (err.message) {
    message = err.message;
  }

  return message;
}

export async function getClientByEmail(customerId: string, email: string): Promise<Client | []> {
  const data = await API.get(
    'CustomersAPI',
    `/${customerId}/clients?email=${encodeURIComponent(email)}`,
    {}
  );
  return data;
}

interface ClientsResponse {
  metadata: Record<string, any>;
  data: any[];
}

export async function getClients(
  customerId: string,
  page?: number,
  size?: number,
  sortColumn?: string,
  sortOrder?: string,
  search?: string
): Promise<ClientsResponse | Client[]> {
  const data = await API.get(
    'CustomersAPI',
    `/${customerId}/clients${page ? `?page=${page}` : ''}${size ? `&limit=${size}` : ''}${
      sortColumn ? `&sort_by=${sortColumn}` : ''
    }${sortOrder ? `&sort_order=${sortOrder}` : ''}${search ? `&search=${search}` : ''}`,
    {}
  );
  return data;
}

export async function deleteClient(customerId: string, clientId: string): Promise<Client[]> {
  const data = await API.del('CustomersAPI', `/${customerId}/clients/${clientId}`, {});
  return data;
}

export async function patchClient(
  customerId: string,
  clientId: string,
  payload: EditClientPayload
): Promise<Client[]> {
  const myInit = {
    body: payload,
  };
  const data = await API.patch('CustomersAPI', `/${customerId}/clients/${clientId}`, myInit);
  return data;
}

export async function postUpload(
  customerId: string,
  clientId: string,
  payload: Document
): Promise<Document> {
  const myInit = {
    body: payload,
  };
  const data = await API.post('CustomersAPI', `/${customerId}/clients/${clientId}/uploads`, myInit);

  return data;
}
export async function patchUpload(
  customerId: string,
  uploadId: string,
  payload: Document
): Promise<Document> {
  const myInit = {
    body: payload,
  };
  const data = await API.patch('CustomersAPI', `/${customerId}/uploads/${uploadId}`, myInit);
  return data;
}

export async function getUploads(customerId: string, clientId: string): Promise<Document[]> {
  const data = await API.get('CustomersAPI', `/${customerId}/clients/${clientId}/uploads`, {});

  return data;
}

export async function getUploadFiles(customerId: string, uploadId: string): Promise<Document[]> {
  const data = await API.get('CustomersAPI', `/${customerId}/uploads/${uploadId}/files`, {});
  return data;
}

export async function getLicenses(customerId: string): Promise<License[]> {
  const data = await API.get('CustomersAPI', `/${customerId}/licenses`, {});
  return data;
}

export async function getEmployees(customerId: string): Promise<Employee[]> {
  const data = await API.get('CustomersAPI', `/${customerId}/employees`, {});
  return data;
}
export async function getStaff(customerId: string): Promise<Staff[]> {
  const data = await API.get('CustomersAPI', `/${customerId}/users`, {});
  return data;
}

export async function postFile(
  file: FileInfo,
  customerId: string,
  uploadId: string,
  userId: string,
  formId?: string
): Promise<Document> {
  const { contentType, fileName, key: s3Key } = file;
  const myInit = {
    body: {
      uploadId,
      userId,
      fileName,
      contentType,
      s3Key,
      formId,
    },
  };

  const data = await API.post('CustomersAPI', `/${customerId}/files`, myInit);

  return data;
}

export async function uploadFile(
  file: File,
  customerId: string,
  tempDir: string,
  section: string
): Promise<any> {
  const nonce = uuidv4();
  const s3Key = `${customerId}/${tempDir || nonce}/${section}/${nonce}/${file.name}`;
  const res = await postDocumentS3(file, s3Key, `estate_section=${section}`);

  // Shared S3 lib uses .putObject instead of .upload
  // Key is missing from res
  const formattedRes = {
    ...res,
    Key: s3Key,
  };

  return formattedRes;
}

export async function deleteFile(
  file: FileInfo,
  customerId: string,
  tempDir: string,
  section: string
): Promise<any> {
  const s3Key = `${customerId}/${tempDir}/${section}/${file.fileName}`;

  return deleteDocumentS3(s3Key, `estate_section=${section}`);
}

export async function deleteFileById(customerId: string, fileId: string): Promise<any> {
  const data = await API.del('CustomersAPI', `/${customerId}/files/${fileId}`, {});
  return data;
}

export async function postResendInvite(payload: ResendInvitePayload): Promise<any> {
  const { customerId, id } = payload;

  const myInit = {
    body: payload,
    headers: {},
  };
  const data = await API.post('CustomersAPI', `/${customerId}/clients/${id}/resend`, myInit);

  return data;
}

export async function postClientSignUp(payload: ClientSignUpFormPayload): Promise<any> {
  const { customerId } = payload;

  const myInit = {
    body: payload,
  };

  const data = await API.post('CustomersAPI', `/${customerId}/clients`, myInit);
  // if (data) {
  //   // console.log(`the data is ${myInit.body}`);
  //   window.analytics.track(`Signed up Client`, {
  //     firstName: data.firstName,
  //     lastName: data.lastName,
  //     email: data.email,
  //     customerId: data.customerId,
  //   });
  //   // we're using this call for SMFC journeys
  //   window.analytics.identify(data.userId, {
  //     firstName: data.firstName,
  //     lastName: data.lastName,
  //     email: data.email,
  //     confirmedEmail: false,
  //     plan: 'year',
  //     subscriptionType: 'Pro',
  //     status: 'pending',
  //     source: 'professional-portal',
  //   });
  //   // reset indentify
  //   window.analytics.identify(customerId, { type: 'professional-test' });
  // }

  return data;
}

export async function postEmployee(payload: EmployeesAddFormPayload): Promise<any> {
  const { customerId, ...rest } = payload;

  const myInit = {
    body: { ...rest, role: 'professional-legal', customerId },
  };

  const data = await API.post('CustomersAPI', `/${customerId}/employees`, myInit);
  return data;
}

export async function patchEmployee(id: string, payload: EmployeesAddFormPayload): Promise<any> {
  const { firstName, lastName, title, customerId } = payload;

  const myInit = {
    body: { firstName, lastName, title },
  };

  const data = await API.patch('CustomersAPI', `/${customerId}/employees/${id}`, myInit);
  return data;
}

export async function postStaff(payload: StaffAddFormPayload): Promise<any> {
  const { customerId, ...rest } = payload;

  const myInit = {
    body: { ...rest, customerId },
  };

  const data = await API.post('CustomersAPI', `/${customerId}/users`, myInit);
  return data;
}

export async function patchStaff(id: string, payload: Staff): Promise<any> {
  const { firstName, lastName, phoneNumber, customerId } = payload;

  const myInit = {
    body: { firstName, lastName, phoneNumber },
  };

  const data = await API.patch('CustomersAPI', `/${customerId}/users/${id}`, myInit);
  return data;
}

export async function buyLicenses(
  customerId: string,
  priceId: string,
  paymentMethodId: string,
  taxRateId: string,
  numberOfLicenses: number
): Promise<any> {
  const url = `/${customerId}/licenses/purchase`;
  const myInit = {
    body: {
      customerId,
      priceId,
      paymentMethodId,
      taxRateId,
      numberOfLicenses: parseInt(numberOfLicenses.toString(), 10),
    },
  };
  const { data } = await API.post('CustomersAPI', url, myInit);
  return data;
}

export async function getCustomers(): Promise<Customer[]> {
  const data = await API.get('CustomersAPI', '', {});
  return data;
}

export async function postCustomer(customer: CustomerPayload): Promise<Customer> {
  const myInit = {
    body: customer,
  };

  const data = await API.post('CustomersAPI', '', myInit);
  return data;
}

export async function postMessage(payload: Message): Promise<any> {
  const { threadId, customerId, clientId, senderId, recipientId, message, userId, files, formId } =
    payload;

  const url = `/${customerId}/clients/${clientId}/threads/${threadId}/messages`;

  const myInit = {
    body: {
      senderId,
      recipientId,
      message,
      metadata: {},
      files,
      userId,
      formId,
    },
  };

  const data = await API.post('CustomersAPI', url, myInit);
  return data;
}

export async function getThreads(customerId: string, clientId: string): Promise<any> {
  const data = await API.get('CustomersAPI', `/${customerId}/clients/${clientId}/threads`, {});
  return data;
}

export async function getMessages(
  customerId: string,
  clientId: string,
  threadId: string
): Promise<any> {
  const data = await API.get(
    'CustomersAPI',
    `/${customerId}/clients/${clientId}/threads/${threadId}/messages`,
    {}
  );

  return data;
}

export async function getForms(customerId: string, clientId: string): Promise<any> {
  const url = `/${customerId}/clients/${clientId}/forms`;
  const data = await API.get('CustomersAPI', url, {});
  return data;
}

export async function getFormById(customerId: string, formId: string): Promise<Form> {
  const url = `/${customerId}/forms/${formId}`;
  const data = await API.get('CustomersAPI', url, {});
  return data;
}

export async function deleteFormById(customerId: string, formId: string): Promise<any> {
  const url = `/${customerId}/forms/${formId}`;
  const data = await API.del('CustomersAPI', url, {});
  return data;
}

export async function patchForm({
  customerId,
  clientId,
  metadata,
  formId,
  status,
  sendNotification,
}: PatchFormPayload): Promise<Document | any> {
  const myInit = {
    body: { metadata, status, sendNotification },
  };

  const url = clientId
    ? `/${customerId}/clients/${clientId}/forms/${formId}`
    : `/${customerId}/forms/${formId}`;
  const data = await API.put('CustomersAPI', url, myInit);

  return data;
}

export async function getAllIntakeForms({
  customerId,
  page,
  size,
  sortColumn,
  sortOrder,
  search,
}: IntakeFormsPayload): Promise<IntakeFormsResponse | IntakeForm[]> {
  const data = await API.get(
    'CustomersAPI',
    `/${customerId}/forms${page ? `?page=${page}` : ''}${size ? `&limit=${size}` : ''}${
      sortColumn ? `&sort_by=${sortColumn}` : ''
    }${sortOrder ? `&sort_order=${sortOrder}` : ''}${search ? `&search=${search}` : ''}`,
    {}
  );
  return data;
}

export async function createForm(payload: FormPayload): Promise<any> {
  const { fileNumber, formType, customerId } = payload;
  const url = `/${customerId}/forms`;

  const requestParams = {
    body: {
      type: formType,
      metadata: { fileNumber },
      status: 'in progress',
    },
  };

  const data = await API.post('CustomersAPI', url, requestParams);
  return data;
}
