/* eslint-disable import/no-cycle */
/* eslint-disable no-var */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Amplify from 'aws-amplify';

import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { SemanticToastContainer } from 'react-semantic-toasts';
import App from './app/App';
import store from './app/store';
import * as serviceWorker from './serviceWorker';
import awsAmplifyConfig from './configs/aws';

import 'semantic-ui-less/semantic.less';
import './index.less';
import 'react-semantic-toasts/styles/react-semantic-alert.css';

const { compile, states } = require('country-states');
const countries = require('i18n-iso-countries');
const langLocale = require('i18n-iso-countries/langs/en.json');
const cpp = require('country-phone-prefix');

export var regionOptions: CountryProps[];
export var countryOptions: CountryProps[];
export var countryCodeOptions: CountryProps[];

export interface CountryProps {
  key: string;
  value: string;
  text: string;
  flag?: string;
}

countries.registerLocale(langLocale);
const listOfCountries = countries.getNames('en');
const convertedList: CountryProps[] = [];
const convertedPhoneList: CountryProps[] = [];
Object.keys(listOfCountries).forEach((c: string) => {
  const prefix = cpp[listOfCountries[c]] ? cpp[listOfCountries[c]].prefix : null;
  if (prefix && prefix !== '+') {
    convertedPhoneList.push({
      key: prefix + c,
      value: c,
      text: `${prefix}`,
      flag: c.toLowerCase(),
    });
  }

  convertedList.push({
    key: c,
    value: c,
    text: listOfCountries[c],
    flag:
      c !== 'XK' &&
      c !== 'SX' &&
      c !== 'SS' &&
      c !== 'MF' &&
      c !== 'BL' &&
      c !== 'JE' &&
      c !== 'IM' &&
      c !== 'GG' &&
      c !== 'CW' &&
      c !== 'BQ' &&
      c !== 'AQ'
        ? c.toLowerCase()
        : '',
  });
});
countryOptions = convertedList;
countryCodeOptions = convertedPhoneList;

compile(['CA', 'US']);
const regions = states('CA');
regionOptions = regions.map((reg: Record<string, string>) => {
  return { key: reg.iso, value: reg.iso, text: reg.name };
});

library.add(far);

Amplify.configure(awsAmplifyConfig);

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <Router>
        <SemanticToastContainer position="top-right" />
        <div className="App">
          <Routes>
            <Route path="*" element={<App />} />
          </Routes>
        </div>
      </Router>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
