import React, { useState } from 'react';

import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';

import { Button, Dimmer, FormGroup, Grid, Header, Loader, Modal } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-semantic-toasts';
import ReadyAdminSideNav from '../../components/ReadyAdminSideNav';
import ReadyAdminTopNav from '../../components/ReadyAdminTopNav';

// eslint-disable-next-line import/no-cycle
import { countryOptions, regionOptions } from '../../index';
import { selectLogin, setCurrentUser } from '../login/loginSlice';
import ChangePassword from './ChangePassword';
import { AppDispatch } from '../../app/store';

const formErrorMessage = (message: string) => {
  toast({
    type: 'error',
    icon: 'exclamation circle',
    title: 'Account Settings Update Failed',
    description: message,
    animation: 'drop',
    time: 10000,
  });
};

const AccountSettingsPage = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const { currentUser } = useSelector(selectLogin);

  const [formDisabled, setFormDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const initialValues = {
    username: currentUser?.attributes.email,
    password: '',
    firm: currentUser?.attributes['custom:firm'],
    address: currentUser?.attributes['custom:address'],
    suite: currentUser?.attributes['custom:suite'],
    city: currentUser?.attributes['custom:city'],
    postcode: currentUser?.attributes['custom:postcode'],
    region: currentUser?.attributes['custom:region'] || 'BC',
    country: currentUser?.attributes['custom:country'] || 'CA',
  };

  const validationSchema = Yup.object({
    firm: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    suite: Yup.string(),
    city: Yup.string().required('Required'),
    postcode: Yup.string().required('Required'),
    region: Yup.string()
      .oneOf(regionOptions.map((c: { value: string }) => c.value))
      .required('Required'),
    country: Yup.string()
      .oneOf(countryOptions.map((c: { value: string }) => c.value))
      .required('Required'),
  });

  const handleSubmit = async (values: typeof initialValues) => {
    setFormDisabled(true);

    const { firm, address, suite, city, region, country, postcode } = values;

    const user = await Auth.currentAuthenticatedUser();

    try {
      await Auth.updateUserAttributes(user, {
        // phone_number: formatPhoneNumber(phonenumber, true),
        'custom:firm': firm,
        'custom:address': address,
        ...(suite ? { 'custom:suite': suite } : { 'custom:suite': ' ' }),
        'custom:city': city,
        'custom:region': region,
        'custom:country': country,
        'custom:postcode': postcode,
      });

      const updatedUser = await Auth.currentUserInfo();
      dispatch(setCurrentUser(updatedUser));
      setModalOpen(true);
    } catch (e) {
      if (typeof e === 'string') {
        formErrorMessage(e.toUpperCase());
      } else if (e instanceof Error) {
        formErrorMessage(e.message);
      }
    }

    setFormDisabled(false);
  };

  return (
    <>
      <ReadyAdminTopNav />
      <Grid divided>
        <Grid.Column width={3}>
          <ReadyAdminSideNav />
        </Grid.Column>
        <Grid.Column width={13}>
          <div className="Page_mainContent ">
            <h1 style={{ margin: '0 0 2rem 3rem' }}>Account Settings</h1>
            {currentUser ? (
              <div id="Container">
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  validateOnChange={false}
                  validateOnBlur={false}
                  onSubmit={async (values: typeof initialValues) => handleSubmit(values)}
                >
                  <Form size="large" noValidate>
                    <section className="Card Card_Settings">
                      <h4>Firm Information</h4>
                      <div className="Form_input___halfWidth Form_input__wrapper">
                        <Input
                          name="firm"
                          label="Firm name *"
                          disabled={formDisabled}
                          errorPrompt
                        />
                      </div>
                      <Input
                        name="address"
                        label="Firm address *"
                        disabled={formDisabled}
                        errorPrompt
                      />
                      <Input
                        name="suite"
                        label="Apt/Suite number"
                        disabled={formDisabled}
                        errorPrompt
                      />
                      <FormGroup widths="equal">
                        <Input name="city" label="City *" disabled={formDisabled} errorPrompt />
                        <Select
                          name="region"
                          label="Province *"
                          selectOnBlur={false}
                          options={regionOptions}
                          disabled={formDisabled}
                          value={currentUser?.attributes['custom:region'] || 'BC'}
                        />
                      </FormGroup>

                      <FormGroup widths="equal">
                        <Select
                          name="country"
                          label="Country *"
                          selectOnBlur={false}
                          options={countryOptions}
                          disabled={formDisabled}
                          value={currentUser?.attributes['custom:country'] || 'CA'}
                        />
                        <Input
                          name="postcode"
                          label="Postal code/Zip Code *"
                          disabled={formDisabled}
                          errorPrompt
                        />
                      </FormGroup>
                      <SubmitButton primary>Save</SubmitButton>
                    </section>
                    <section className="Card Card_Settings">
                      <h4>User Information</h4>
                      <Input
                        name="username"
                        label="Username"
                        value={currentUser?.attributes.email}
                        disabled
                        errorPrompt
                        className="Form_input___halfWidth"
                      />
                      <Input
                        name="password"
                        label="Password"
                        value="*****"
                        disabled
                        errorPrompt
                        className="Form_input___halfWidth"
                      />
                    </section>
                  </Form>
                </Formik>

                <ChangePassword />
              </div>
            ) : (
              <Dimmer
                active
                inverted
                style={{
                  marginTop: '14px',
                }}
              >
                <Loader inverted>Loading</Loader>
              </Dimmer>
            )}
          </div>
        </Grid.Column>
      </Grid>

      <Modal
        closeIcon
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        className="Modal___centered"
      >
        <Header icon>
          <FontAwesomeIcon icon={['far', 'check-circle']} className="Header_graphic___center" />
          <br />
          Thank you!
        </Header>
        <Modal.Content>
          <Modal.Description>Your account information was successfully changed!</Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Continue"
            onClick={() => {
              setModalOpen(false);
            }}
            className="Form_button"
            primary
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default AccountSettingsPage;
