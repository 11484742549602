import { Button, Modal } from 'semantic-ui-react';

interface DeleteIntakeFormModalProps {
  loading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteIntakeFormModal = ({
  loading,
  isOpen,
  onClose,
  onConfirm,
}: DeleteIntakeFormModalProps) => {
  return (
    <Modal
      closeIcon
      onClose={onClose}
      open={isOpen}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      className="Modal___centered"
    >
      <Modal.Header as="h4">Are you sure you want to delete?</Modal.Header>
      <Modal.Content />
      <Modal.Actions>
        <Button content="Cancel" onClick={onClose} className="Form_button___half" />
        <Button
          content="Yes, I'm Sure"
          onClick={onConfirm}
          className="Form_button___half"
          primary
          loading={loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteIntakeFormModal;
