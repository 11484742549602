/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { Header, Button, FormGroup, Modal, Transition, Divider } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-semantic-toasts';
import { employeesSelectors, editEmployee, addEmployee } from './employeesSlice';
import { selectCustomers } from '../customers/customersSlice';
import { AppDispatch } from '../../app/store';

interface FormProps {
  employeeId?: string | null;
  closeModalCallback?: () => void;
  addEmployeesModalOpen?: boolean;
}

export default function AddEmployeesFormPage({
  employeeId,
  closeModalCallback,
  addEmployeesModalOpen,
}: FormProps): JSX.Element {
  const [formDisabled, setFormDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState<number>(1);
  const dispatch: AppDispatch = useDispatch();
  const employee = useSelector((state) =>
    employeesSelectors.selectById(state, employeeId as string)
  );
  const { currentCustomerId } = useSelector(selectCustomers);

  const initialValues = {
    firstName: employee ? employee.firstName : '',
    lastName: employee ? employee.lastName : '',
    email: employee ? employee.email : '',
    title: employee ? employee.title : '',
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    title: Yup.string().required('Required'),
  });

  const closeModal = () => {
    setFormDisabled(false);
    if (closeModalCallback) closeModalCallback();
  };

  function formErrorMessage(payload: string) {
    toast({
      type: 'error',
      icon: 'exclamation circle',
      title: employeeId ? 'Employee Edit Error' : 'Employee Add Error',
      description: payload,
      animation: 'drop',
      time: 10000,
    });
  }

  const handleSubmit = async (values: typeof initialValues) => {
    setFormDisabled(true);

    if (currentCustomerId) {
      if (employeeId) {
        const resultAction = await dispatch(
          editEmployee({ ...values, employeeId, customerId: currentCustomerId })
        );

        if (editEmployee.fulfilled.match(resultAction)) {
          setModalOpen(2);
        }
        if (editEmployee.rejected.match(resultAction)) {
          formErrorMessage(resultAction.payload as string);
        }
      } else {
        const resultAction = await dispatch(
          addEmployee({ ...values, customerId: currentCustomerId })
        );

        if (addEmployee.fulfilled.match(resultAction)) {
          setModalOpen(2);
        }
        if (addEmployee.rejected.match(resultAction)) {
          formErrorMessage(resultAction.payload as string);
        }
      }
    }

    setFormDisabled(false);
  };

  return (
    <>
      <Transition visible={modalOpen === 1} animation="scale" duration={400}>
        <Modal closeIcon open={addEmployeesModalOpen} onClose={() => closeModal()}>
          <Modal.Description>
            <h1 style={{ margin: '5px 0 20px 30px' }}>{employeeId ? 'Edit' : 'Add Employee'}</h1>
            <Divider />
            <div id="Form_container">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={handleSubmit}
              >
                <Form size="large" noValidate>
                  <FormGroup widths="equal">
                    <Input
                      name="firstName"
                      label="First Name *"
                      disabled={formDisabled}
                      errorPrompt
                    />
                    <Input
                      name="lastName"
                      label="Last Name *"
                      disabled={formDisabled}
                      errorPrompt
                    />
                  </FormGroup>

                  <FormGroup widths="equal">
                    <Input
                      name="email"
                      label={employeeId ? 'Email' : 'Email *'}
                      type="email"
                      disabled={employeeId ? true : formDisabled}
                      errorPrompt
                    />
                    <Input
                      name="title"
                      label="Role *"
                      type="text"
                      disabled={formDisabled}
                      errorPrompt
                    />
                  </FormGroup>
                  <SubmitButton
                    primary
                    className="Button__Add___centered"
                    style={{ margin: '20px 0 -10px' }}
                  >
                    Save
                  </SubmitButton>
                </Form>
              </Formik>
            </div>
          </Modal.Description>
        </Modal>
      </Transition>

      <Transition visible={modalOpen === 2} animation="scale" duration={400}>
        <Modal
          closeIcon
          open={modalOpen === 2}
          onClose={() => closeModal()}
          onOpen={() => setModalOpen(2)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          className="Modal___centered"
        >
          <Header icon>
            <FontAwesomeIcon icon={['far', 'check-circle']} className="Header_graphic___center" />
          </Header>
          <Modal.Content>
            <Modal.Description>
              <p style={{ textAlign: 'center', fontSize: 18 }}>
                {employeeId ? 'Employee was edited Successfully' : 'Employee is added Successfully'}
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <br />
            <Button
              content="Continue"
              onClick={() => {
                closeModal();
              }}
              className="Form_button"
              primary
            />
          </Modal.Actions>
        </Modal>
      </Transition>
    </>
  );
}

AddEmployeesFormPage.defaultProps = {
  employeeId: null,
  closeModalCallback: null,
  addEmployeesModalOpen: null,
};
