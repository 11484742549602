import { Grid } from 'semantic-ui-react';
import Moment from 'react-moment';

interface ReadyFormHeaderProps {
  formType: string;
  clientId: string | undefined;
  title?: string;
  completionDate?: string;
}

const ReadyFormHeader = ({ formType, clientId, title, completionDate }: ReadyFormHeaderProps) => {
  return (
    <>
      <Grid.Row style={{ paddingTop: 0 }}>
        <Grid.Column width={10}>
          <header className="pageTitle Form">
            <h4>{formType}</h4>
            <h5>{title}</h5>
          </header>
        </Grid.Column>
        {clientId && (
          <Grid.Column width={6} style={{ display: 'flex', justifyContent: 'end' }}>
            <div style={{ marginTop: '0.3em' }}>
              Completion Date: <Moment format="D MMM, YYYY">{completionDate}</Moment>
            </div>
          </Grid.Column>
        )}
      </Grid.Row>
    </>
  );
};

ReadyFormHeader.defaultProps = {
  title: '',
  completionDate: '',
};

export default ReadyFormHeader;
