import { Input, Radio, TextArea } from 'formik-semantic-ui-react';
import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ClientDeleteFilePayload,
  ClientFilesPayload,
  DateInput,
  EmailInput,
  FileInfo,
  FileUploadField,
  MoneyInput,
  PhoneInput,
  S3,
} from 'readywhen-ui-components';
import { Button, FormField, FormGroup } from 'semantic-ui-react';
import config from '../../../configs/config';
import { selectCustomers } from '../../customers/customersSlice';
import { deleteClientFiles, selectClients, uploadClientFiles } from '../clientsSlice';
import { FieldsInsuranceProps } from '../interfaces';

const { downloadFile } = S3;

export default function ClientInsuranceField({
  data,
  formDisabled,
  index,
  beneficiaries,
  setBeneficiaries,
}: FieldsInsuranceProps): JSX.Element {
  let timeout: NodeJS.Timeout;

  const { files } = useSelector(selectClients);
  const { currentCustomerId } = useSelector(selectCustomers);
  const dispatch = useDispatch();

  return (
    <>
      <Input name={`insuranceTitle-${index}`} label="Title *" disabled={formDisabled} errorPrompt />
      <FormGroup widths="equal">
        <Input
          name={`insuranceName-${index}`}
          label="Insurance Company*"
          disabled={formDisabled}
          errorPrompt
        />
        <Input
          name={`insurancePolicy-${index}`}
          label="Policy Number*"
          disabled={formDisabled}
          errorPrompt
        />
      </FormGroup>

      <FormGroup widths="equal">
        <MoneyInput
          name={`policyAmount-${index}`}
          label="Policy amount"
          disabled={formDisabled}
          errorPrompt
        />
        <DateInput
          name={`expirationDate-${index}`}
          label="Expiration Date"
          disabled={formDisabled}
          errorPrompt
        />
      </FormGroup>

      <FormGroup widths="equal">
        <MoneyInput
          name={`monthlyPayment-${index}`}
          label="Monthly payment"
          disabled={formDisabled}
          errorPrompt
        />
        <Input
          name={`paidFrom-${index}`}
          label="Paid from"
          placeholder="Account payment is made form"
          disabled={formDisabled}
          errorPrompt
        />
      </FormGroup>

      <FormField>
        {beneficiaries[`insurance-${index}`] &&
          beneficiaries[`insurance-${index}`].length &&
          beneficiaries[`insurance-${index}`].map((b: { name: string }, bIndex: number) => {
            return (
              <Input
                // eslint-disable-next-line react/no-array-index-key
                key={`insurance-beneficiary-${index}-${bIndex}`}
                name={`beneficiary-${index}-${bIndex}`}
                label="Name of beneficiary (if applicable)"
                placeholder="Spouse, children, etc"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.currentTarget;

                  if (timeout) clearTimeout(timeout);
                  timeout = setTimeout(() => {
                    const newArray = [...beneficiaries[`insurance-${index}`]];
                    newArray.splice(bIndex, 1, { name: value });

                    setBeneficiaries({
                      ...beneficiaries,
                      [`insurance-${index}`]: newArray,
                    });
                  }, 550);
                }}
                disabled={formDisabled}
                errorPrompt
              />
            );
          })}
        <Button
          basic
          type="button"
          content="Add additional beneficiary"
          onClick={() => {
            if (timeout) clearTimeout(timeout);

            setBeneficiaries({
              ...beneficiaries,
              [`insurance-${index}`]: [...beneficiaries[`insurance-${index}`], { name: '' }],
            });
          }}
        />
      </FormField>

      <FormField>
        <label htmlFor={`accountType-${index}`}>
          <strong>Policy Coverage*</strong>
        </label>
        <FormGroup inline>
          <Radio name={`accountType-${index}`} label="Personal Policy" value="personal" />
          <Radio name={`accountType-${index}`} label="Work Policy" value="work" />
          <Radio
            errorPrompt
            name={`accountType-${index}`}
            label="Mortgage insurance"
            value="mortgage"
          />
        </FormGroup>
      </FormField>

      <FileUploadField
        name={`lifeInsuranceFiles-${index}`}
        label="Upload documents"
        required
        multiple
        type="file"
        accept="application/pdf, image/*"
        uploadId={data?.id}
        userId={data?.userId}
        files={files}
        currentCustomerId={currentCustomerId as string}
        uploadClientFiles={(payload: ClientFilesPayload) => {
          dispatch(uploadClientFiles(payload));
        }}
        downloadFile={(file: FileInfo) => {
          downloadFile('', config.s3.BUCKET, file?.key as string, '', file?.fileName);
        }}
        deleteClientFiles={(payload: ClientDeleteFilePayload) => {
          dispatch(deleteClientFiles(payload));
        }}
      />

      <TextArea
        name={`lifeInsurancePhysicalLocation-${index}`}
        label="Physical location of files*"
        placeholder="You can find this document in the top drawer of the filing cabinet. "
        disabled={formDisabled}
        errorPrompt
      />

      <h4>Life insurance agency contact information</h4>
      <Input
        name={`agentName-${index}`}
        label="Name of life insurance agent"
        disabled={formDisabled}
        errorPrompt
      />

      <FormGroup widths="equal">
        <PhoneInput
          name={`agentPhone-${index}`}
          label="Life insurance agent’s phone number"
          disabled={formDisabled}
          errorPrompt
        />
        <EmailInput
          name={`agentEmail-${index}`}
          label="Life insurance agent’s email address"
          disabled={formDisabled}
          errorPrompt
        />
      </FormGroup>
    </>
  );
}
