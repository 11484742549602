import React, { Fragment, ReactNode, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { Divider, Grid, Label, Icon } from 'semantic-ui-react';
import includes from 'lodash/includes';
import startCase from 'lodash/startCase';
import { Document, Staff } from '../../../api/interfaces';
import { accountOptions } from '../../../constants/dropdownOptions';

interface CardPropertyOrWillProps {
  cardData: Document;
  editModalOpen: () => void;
  changeModalType: (type: string, id: string) => void;
}

export function CardPropertyOrWill({
  cardData,
  editModalOpen,
  changeModalType,
}: CardPropertyOrWillProps): JSX.Element {
  const navigate = useNavigate();
  const {
    createdAt,
    updatedAt,
    clientId,
    id,
    type,
    staff,
    metadata: { address, completionDate, name, isIntakeFormSent, formType },
  } = cardData;

  const formStatus =
    cardData.forms && cardData.forms.length ? startCase(cardData.forms[0].status) : '';

  const fileNumber = useMemo(() => cardData?.forms?.[0]?.metadata?.fileNumber, [cardData]);

  return clientId ? (
    <>
      <section
        key={id}
        className="Card Card_Settings"
        style={{ marginLeft: '-0.1em', maxHeight: 250 }}
      >
        <Grid columns={4} stackable>
          <Grid.Row style={{ padding: 0 }}>
            <Grid.Column
              style={{ fontWeight: 'bold', cursor: 'pointer' }}
              width={5}
              onClick={() => {
                if (isIntakeFormSent === 1) {
                  navigate(clientId && `/clients/${clientId}/${id}`);
                } else {
                  editModalOpen();
                  changeModalType(type, id);
                }
              }}
            >
              <h4
                className="propertyDetails"
                data-id={address || name}
                style={{ color: '#07a89a', marginBottom: '.6rem', fontSize: 16 }}
              >
                {type === 'property' ? (
                  <>
                    <FontAwesomeIcon icon={['far', 'home']} />{' '}
                    {startCase((address || name) as string)}
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={['far', 'feather']} /> {startCase(type)}
                  </>
                )}
              </h4>
              <p style={{ color: '#62636f', fontWeight: 'normal', marginBottom: '.5rem' }}>
                {type === 'property'
                  ? address !== undefined && startCase(name as string)
                  : isIntakeFormSent === 1 && 'Intake Form'}
              </p>
            </Grid.Column>
            <Grid.Column width={5} style={{ color: '#62636f' }}>
              {isIntakeFormSent === 1 && (
                <div style={{ marginBottom: '.6rem' }}>{startCase(formType as string) || ''}</div>
              )}
              {fileNumber && <div style={{ marginBottom: '.5rem' }}>#{fileNumber as string}</div>}
            </Grid.Column>
            <Grid.Column width={4} style={{ color: '#62636f' }}>
              <div>{isIntakeFormSent === 1 && formStatus}</div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {staff?.length ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {(staff as Staff[]).map((s) => (
              <Label key={s.id} image style={{ width: 'fit-content', margin: '14px 10px 0 0' }}>
                <Icon name="user" /> {s.firstName} {s.lastName}
              </Label>
            ))}
          </div>
        ) : null}

        {isIntakeFormSent === 1 && (
          <>
            <Divider />
            <Grid style={{ marginTop: 5 }}>
              <Grid.Row style={{ padding: 5 }}>
                <Grid.Column width={5}>Created</Grid.Column>
                <Grid.Column width={5}>Last Edited</Grid.Column>
                <Grid.Column width={5}>{type === 'property' && <>Completion</>}</Grid.Column>
                <Grid.Column width={5}>
                  <Moment style={{ fontWeight: 'bold' }} format="D MMM, YYYY">
                    {createdAt as Date}
                  </Moment>
                </Grid.Column>
                <Grid.Column width={5}>
                  <Moment style={{ fontWeight: 'bold' }} format="D MMM, YYYY">
                    {updatedAt as Date}
                  </Moment>
                </Grid.Column>
                <Grid.Column width={5}>
                  {type === 'property' && (
                    <Moment style={{ fontWeight: 'bold' }} format="D MMM, YYYY">
                      {completionDate as Date}
                    </Moment>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        )}
      </section>
    </>
  ) : (
    <></>
  );
}
interface CardNotPropertyOrWillProps {
  documents: Record<string, Document[]>;
  editModalOpen: () => void;
  changeModalType: (type: string, id: string) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function CardNotPropertyOrWill({
  documents,
  editModalOpen,
  changeModalType,
}: CardNotPropertyOrWillProps): JSX.Element {
  const icons = [
    { name: 'spouse-will', value: <FontAwesomeIcon icon={['far', 'feather']} /> },
    { name: 'power-of-attorney', value: <FontAwesomeIcon icon={['far', 'user-friends']} /> },
    { name: 'spouse-power-of-attorney', value: <FontAwesomeIcon icon={['far', 'user-friends']} /> },
    { name: 'health-directive', value: <FontAwesomeIcon icon={['far', 'stethoscope']} /> },
    { name: 'spouse-health-directive', value: <FontAwesomeIcon icon={['far', 'stethoscope']} /> },
    { name: 'insurance', value: <FontAwesomeIcon icon={['far', 'umbrella']} /> },
  ];

  const renameDocType = (docType: string): string => {
    let newDocType = '';
    if (docType === 'spouse-will') {
      newDocType = 'Spouse Will';
    } else if (docType === 'power-of-attorney') {
      newDocType = 'Power of Attorney';
    } else if (docType === 'spouse-power-of-attorney') {
      newDocType = 'Spouse Power of Attorney';
    } else if (docType === 'spouse-health-directive') {
      newDocType = 'Spouse Health';
    } else if (docType === 'health-directive') {
      newDocType = 'Health';
    } else newDocType = startCase(docType);

    return newDocType;
  };

  const getAccountName = (doc: Document) =>
    accountOptions.map((a) => a.value === doc.metadata.accountType && a.text);

  const { property, will, ...notPropertyOrWillDocs } = documents;
  const keys = Object.keys(notPropertyOrWillDocs);
  const elements: ReactNode[] = [];

  keys.forEach((oneKey) => {
    elements.push(
      documents[oneKey]
        .filter((d, index) => {
          if (!includes(['insurance'], oneKey)) {
            return index === 0;
          }
          return true;
        })
        .map((doc) => {
          return (
            <section key={doc.id} className="Card">
              <Grid>
                <Grid.Column
                  onClick={() => {
                    editModalOpen();
                    changeModalType(doc.type, doc.id);
                  }}
                  style={{ fontWeight: 'bold', cursor: 'pointer' }}
                  width={14}
                >
                  <h4 style={{ color: '#07a89a', marginBottom: '.6rem', fontSize: 16 }}>
                    {icons.map((i, index) => {
                      const iconKey = Object.values(i)[0];
                      const iconValue = Object.values(i)[1];
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={i.name + index}>
                          {iconKey === doc.type && iconValue}
                        </Fragment>
                      );
                    })}
                    {`  ${renameDocType(doc.type)}`}
                  </h4>
                  {doc.type === 'financial' && (
                    <p style={{ fontWeight: 'normal' }}>{getAccountName(doc)}</p>
                  )}
                  {doc.staff?.length ? (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {(doc.staff as Staff[]).map((s) => (
                        <Label
                          key={s.id}
                          image
                          style={{ width: 'fit-content', margin: '14px 10px 0 0' }}
                        >
                          <Icon name="user" /> {s.firstName} {s.lastName}
                        </Label>
                      ))}
                    </div>
                  ) : null}
                </Grid.Column>
              </Grid>
            </section>
          );
        })
    );
  });

  return <> {elements} </>;
}
