/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, SyntheticEvent, useReducer, useRef } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';

import { Radio, Form, Input, SubmitButton, Select } from 'formik-semantic-ui-react';
import {
  Button,
  DropdownProps,
  FormField,
  FormGroup,
  Grid,
  Header,
  Icon,
  Modal,
  Popup,
  Transition,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router';
import { EmailInput, HiddenInput } from 'readywhen-ui-components';
import { formErrorMessage, buildSchema, signUpReducer } from './helpers';
import { employeesSelectors } from '../../employees/employeesSlice';
// eslint-disable-next-line import/no-cycle
import { countryOptions, regionOptions } from '../../../index';
import { getClientByEmail } from '../../../api/ReadyWhenAdminAPI';
import { Client, ClientSignUpFormPayload, EmployeesAddFormPayload } from '../../../api/interfaces';
import { formatPhoneNumber } from '../../../utils/stringUtils';
import { initClientFiles, signUpClient } from '../clientsSlice';
import ReadyAdminTopNav from '../../../components/ReadyAdminTopNav';
import { selectCustomers } from '../../customers/customersSlice';
import { AppDispatch } from '../../../app/store';
import { SignUpInitialState, SignUpFormData, FormPayload } from './interfaces';

const initializer = (): SignUpInitialState => {
  return {
    formDisabled: false,
    modalOpen: 0,
    formData: {
      tempDir: uuidv4(),
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      country: 'CA',
      region: 'BC',
      assignEmployee: 0,
      employeeRole: '',
      uploadDocuments: 1,
      personalMessage: '',
      paymentMethod: 'invoice',
      planType: 'year',
    },
    employee: '',
    isEmployeeAssigned: false,
    areDocumentsUploaded: true,
  };
};

export default function SignUpFormPage(): JSX.Element {
  const [signUpState, dispatch] = useReducer(signUpReducer, null, initializer);
  const { formDisabled, modalOpen, formData, employee, isEmployeeAssigned, areDocumentsUploaded } =
    signUpState;
  const formikOneRef = useRef<FormikProps<SignUpFormData>>(null);
  const reduxDispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const employees = useSelector(employeesSelectors.selectAll);
  const newFormData = formData;
  const { customersById, currentCustomerId } = useSelector(selectCustomers);

  if (employees && employees.length === 1) {
    newFormData.employee = `${employees[0].id}`;
  }

  const initialValues = newFormData;

  const validationSchema = buildSchema();

  const handleSubmit = async (values: FormPayload, formikHelpers: FormikHelpers<FormPayload>) => {
    const {
      tempDir,
      phone,
      assignEmployee,
      paymentMethod,
      uploadDocuments,
      country,
      email,
      firstName,
      lastName,
      employeeRole,
      personalMessage,
      region,
      employee: employeeId,
      planType,
    } = values;

    let isError = false;

    const { setFieldError } = formikHelpers;

    dispatch({ type: 'SET_FORM_DISABLED', value: true });

    if (currentCustomerId) {
      const result = await getClientByEmail(currentCustomerId, email);

      if ((result as Client).id) {
        setFieldError('email', 'An account with this email already exists');
        isError = true;
      } else {
        dispatch({ type: 'SET_FORM_DATA', value: values });
      }
    }

    const formattedPhone = `+1${formatPhoneNumber(phone)}`;

    const formattedPayload: any = {
      tempDir,
      phone: formattedPhone,
      country,
      email,
      firstName,
      lastName,
      employeeRole,
      assignEmployee,
      paymentMethod,
      uploadDocuments,
      personalMessage,
      region,
      employeeId,
      planType,
      metadata: {
        areDocumentsUploaded,
      },
    };

    if (isError) {
      dispatch({ type: 'SET_FORM_DISABLED', value: false });
      return;
    }

    if (currentCustomerId) {
      formattedPayload.customerId = currentCustomerId;
      const resultAction = await reduxDispatch(
        signUpClient(formattedPayload as ClientSignUpFormPayload)
      );
      if (signUpClient.fulfilled.match(resultAction)) {
        const clientId = resultAction.payload.id;
        if (areDocumentsUploaded) {
          navigate(clientId && `/clients/${clientId}/`);
        } else {
          navigate('/clients/');
        }
      }
      if (signUpClient.rejected.match(resultAction)) {
        formErrorMessage(resultAction.payload as string);
      }
    }
    dispatch({ type: 'SET_FORM_DISABLED', value: false });
  };

  const cancel = () => {
    dispatch({ type: 'SET_MODAL_OPEN', value: 0 });
  };

  const employeeOptions = employees.map((e: EmployeesAddFormPayload) => {
    return {
      key: e.id,
      value: e.id,
      text: `${e.firstName} ${e.lastName}`,
    };
  });

  const rolesOptions = [
    {
      key: 'professional-legal',
      text: 'Legal Professional',
      value: 'professional-legal',
    },
    {
      key: 'professional-assets',
      text: 'Financial Planner',
      value: 'professional-assets',
    },
  ];

  useEffect(() => {
    reduxDispatch(initClientFiles());
  }, [reduxDispatch]);

  useEffect(() => {
    if (employees && employees.length === 1) {
      dispatch({
        type: 'SET_EMPLOYEE',
        value: employees[0].id,
      });
    }
  }, [employees]);

  return (
    <>
      <ReadyAdminTopNav
        closePath
        callback={() => {
          dispatch({ type: 'SET_MODAL_OPEN', value: 1 });
        }}
      />
      <Grid>
        <Grid.Column width={12} className="Page_background___grey">
          <div id="Form_container">
            <h2>Client Profile</h2>
            <p className="Form_intro">
              Fill out the form below to easily create a ReadyWhen account on behalf of your client.
            </p>
            <Formik
              innerRef={formikOneRef}
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
            >
              <Form size="large" noValidate>
                <FormGroup widths="equal">
                  <Input
                    name="firstName"
                    label="First Name *"
                    disabled={formDisabled}
                    errorPrompt
                  />
                  <Input name="lastName" label="Last Name *" disabled={formDisabled} errorPrompt />
                </FormGroup>
                <FormGroup widths="equal">
                  <FormField className="Form_notes">
                    <EmailInput
                      name="email"
                      label="Email Address *"
                      disabled={formDisabled}
                      style={{ marginBottom: 10 }}
                    />
                    <span className="Signup_notes">Please use client’s personal email address</span>
                  </FormField>
                </FormGroup>

                <FormGroup widths="equal">
                  <FormField className="Form_notes">
                    <Input
                      style={{ marginBottom: 10 }}
                      name="phone"
                      label="Mobile Phone Number * (Used for MFA)"
                      type="tel"
                      disabled={formDisabled}
                      placeholder="10-digit mobile number"
                      errorPrompt
                    />
                    <span className="Signup_notes">
                      Enter your clients mobile phone number; ensure they can receive text messages.
                      Once entered, they will receive a verification code.
                    </span>
                    <br />
                  </FormField>
                </FormGroup>

                <FormGroup widths="equal">
                  <Select
                    name="country"
                    label="Country"
                    selectOnBlur={false}
                    options={countryOptions}
                    disabled={formDisabled}
                    onChange={(event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
                      const { values } = formikOneRef.current as any;
                      const { value } = data;
                      if (value !== 'CA') {
                        dispatch({
                          type: 'SET_FORM_DATA',
                          value: { ...values, country: value, region: '' },
                        });
                      } else {
                        dispatch({
                          type: 'SET_FORM_DATA',
                          value: { ...values, country: 'CA', region: 'BC' },
                        });
                      }
                    }}
                    errorPrompt
                  />
                  {formData.country === 'CA' ? (
                    <Select
                      name="region"
                      label="Location"
                      selectOnBlur={false}
                      options={regionOptions}
                      disabled={formDisabled}
                      errorPrompt
                    />
                  ) : (
                    <Input name="region" label="Location" disabled={formDisabled} errorPrompt />
                  )}
                </FormGroup>

                {employees.length && employeeOptions ? (
                  <>
                    <label htmlFor="employee">Assign an Employee</label>
                    <FormGroup>
                      <Select
                        className="Form_input___halfWidth"
                        name="employee"
                        selectOnBlur={false}
                        options={employeeOptions || []}
                        onChange={(event, data) => {
                          const { value } = data;
                          if (value) dispatch({ type: 'SET_EMPLOYEE', value });
                        }}
                        disabled={formDisabled}
                      />
                    </FormGroup>
                    <FormField disabled={employee === ''}>
                      <label htmlFor="assignEmployee">
                        Would you like to assign this employee to the Client&apos;s ReadyWhen Team?
                      </label>
                      <FormGroup inline>
                        <Radio
                          name="assignEmployee"
                          value={1}
                          label="Yes"
                          onChange={() =>
                            dispatch({ type: 'SET_IS_EMPLOYEE_ASSIGNED', value: true })
                          }
                        />
                        <Radio
                          name="assignEmployee"
                          value={0}
                          label="No"
                          onChange={() =>
                            dispatch({ type: 'SET_IS_EMPLOYEE_ASSIGNED', value: false })
                          }
                          errorPrompt
                        />
                      </FormGroup>

                      {isEmployeeAssigned && (
                        <Select
                          name="employeeRole"
                          label="ReadyWhen Role"
                          selectOnBlur={false}
                          options={rolesOptions || []}
                          disabled={formDisabled}
                        />
                      )}
                    </FormField>
                  </>
                ) : (
                  <FormField>
                    <p>
                      Create an employee so they can be assigned to the Client&apos;s ReadyWhen
                      Team.
                    </p>
                  </FormField>
                )}

                <FormField>
                  <label htmlFor="uploadDocuments">
                    Would you like to upload documents? (Intake Forms, Estate Planning documents,
                    Insurance)
                  </label>
                  <FormGroup inline>
                    <Radio
                      name="uploadDocuments"
                      value={1}
                      label="Yes"
                      onChange={() => dispatch({ type: 'SET_ARE_DOCUMENTS_UPLOADED', value: true })}
                    />
                    <Radio
                      name="uploadDocuments"
                      value={0}
                      label="No"
                      errorPrompt
                      onChange={() =>
                        dispatch({ type: 'SET_ARE_DOCUMENTS_UPLOADED', value: false })
                      }
                    />
                  </FormGroup>
                </FormField>

                {currentCustomerId && customersById[currentCustomerId].hasPlanTypes && (
                  <FormField>
                    <label htmlFor="planType">
                      Select the plan type for your Client{' '}
                      <Popup
                        trigger={<Icon name="question" color="teal" size="small" circular />}
                        content={
                          <p>
                            An annual price plan will be renewed at the 1-year mark. If you select
                            lifetime your clients will not have to renew.
                          </p>
                        }
                      />
                    </label>
                    <FormGroup inline>
                      <Radio name="planType" value="year" label="Annual" />
                      <Radio name="planType" value="lifetime" label="Lifetime" errorPrompt />
                    </FormGroup>
                  </FormField>
                )}

                {currentCustomerId && customersById[currentCustomerId].hasLicenses && (
                  <FormField>
                    <label htmlFor="paymentMethod">Select your Payment Method</label>
                    <FormGroup inline>
                      <Radio name="paymentMethod" value="license" label="Use a License Key" />
                      <Radio
                        name="paymentMethod"
                        value="invoice"
                        label="Monthly Invoice"
                        errorPrompt
                      />
                    </FormGroup>
                  </FormField>
                )}
                <HiddenInput name="tempDir" />
                <SubmitButton primary className="FileUpload_submit___left">
                  {areDocumentsUploaded ? 'Next' : 'Send Invite to Client'}
                </SubmitButton>
              </Form>
            </Formik>
          </div>
        </Grid.Column>

        <Grid.Column
          width={4}
          className="Sidebar Signup"
          style={{ borderLeft: '1px solid #d2d2d2' }}
        >
          <h4>What to do next?</h4>
          <p>
            We have made ReadyWhen easy and fast.
            <br />
            <br />
            Add your client’s Legal documents such as their Will, Power of Attorney or Health
            Directive.
            <br />
            or
            <br />
            Add their property with or without the intake form.
            <br />
            or
            <br />
            Add their life insurance
          </p>
        </Grid.Column>
      </Grid>

      <Transition visible={modalOpen === 1} animation="scale" duration={400}>
        <Modal
          closeIcon
          size="tiny"
          open={modalOpen === 1}
          onClose={cancel}
          onOpen={() => dispatch({ type: 'SET_MODAL_OPEN', value: 1 })}
        >
          <Header icon>Are you sure you want to quit?</Header>
          <Modal.Content>
            <Modal.Description style={{ margin: '20px' }}>
              The data entered for this client won’t be saved.
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button name="cancel" onClick={cancel}>
              Cancel
            </Button>
            <Button primary name="yes" onClick={() => navigate(-1)}>
              Yes, I’m sure
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </>
  );
}
