/* eslint-disable import/no-cycle */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/rootReducer';

import { getEmployees, postEmployee, patchEmployee } from '../../api/ReadyWhenAdminAPI';
import { Employee } from '../../api/interfaces';
import { ErrorType } from '../../app/interfaces';

const employeesAdapter = createEntityAdapter<Employee>();

export const fetchEmployees = createAsyncThunk(
  'employees/fetchEmployees',
  async (customerId: string) => {
    const response = await getEmployees(customerId);
    return response;
  }
);

const employeesSlice = createSlice({
  name: 'employees',
  initialState: employeesAdapter.getInitialState({ loading: false, error: false }),
  reducers: {
    employeesStarted(state) {
      state.loading = true;
    },
    employeesFailure(state) {
      state.error = true;
      state.loading = false;
    },
    employeeAdded(state, action) {
      employeesAdapter.addOne(state, action.payload);
      state.loading = false;
    },
    employeeUpdateOne(state, { payload }) {
      employeesAdapter.upsertOne(state, payload);
      state.loading = false;
    },
    employeesSuccess(state, action) {
      employeesAdapter.setAll(state, action.payload);
      state.loading = false;
    },
  },
  extraReducers: {
    [fetchEmployees.pending as unknown as string]: (state) => {
      employeesSlice.caseReducers.employeesStarted(state);
    },
    [fetchEmployees.fulfilled as unknown as string]: (state, action) => {
      employeesSlice.caseReducers.employeesSuccess(state, action);
    },
    [fetchEmployees.rejected as unknown as string]: (state) => {
      employeesSlice.caseReducers.employeesFailure(state);
    },
  },
});

export const {
  employeesStarted,
  employeesFailure,
  employeeAdded,
  employeeUpdateOne,
  employeesSuccess,
} = employeesSlice.actions;

export const addEmployee = createAsyncThunk(
  'employees/addedOne',
  async (formData: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      dispatch(employeesStarted());
      const response = await postEmployee(formData);
      dispatch(employeeAdded(response));
      return response;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(employeesFailure());
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue(
          'To add an employee, they will need an active ReadyWhen account. Please have them sign up and re-add them.'
        );
      }
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const editEmployee = createAsyncThunk(
  'employees/editOne',
  async (formData: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { employeeId } = formData;
    try {
      dispatch(employeesStarted());

      const employee = await patchEmployee(employeeId, formData);
      dispatch(employeeUpdateOne(employee));
      return employee;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(employeesFailure());
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const employeesSelectors = employeesAdapter.getSelectors<RootState>(
  (state) => state.employees
);

export default employeesSlice.reducer;
