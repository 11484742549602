/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/store';
import { RootState } from '../../app/rootReducer';
import { getCustomers, postCustomer, processErrorMessage } from '../../api/ReadyWhenAdminAPI';
import { Customer } from '../../api/interfaces';
import { User } from '../login/loginSlice';
import { ErrorType } from '../../app/interfaces';

interface CustomersState {
  customersById: Record<string, Customer>;
  customers: string[];
  currentCustomerId: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: CustomersState = {
  customersById: {},
  customers: [],
  currentCustomerId: null,
  loading: false,
  error: null,
};

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    getCustomersSuccess(state, { payload }: PayloadAction<Customer[]>) {
      const customers = payload;
      state.loading = false;
      state.error = null;
      state.customersById = {};

      customers.forEach((customer) => {
        state.customersById[customer.id] = customer;
        state.customers.push(customer.id);
      });
    },
    setCurrentCustomerId(state, { payload }: PayloadAction<string>) {
      state.loading = false;
      state.error = null;
      if (payload) state.currentCustomerId = payload;
    },
    customerStart(state) {
      state.loading = true;
      state.error = null;
    },
    customerFailure(state: CustomersState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    addCustomer(state, { payload }) {
      const customer = payload;

      state.customersById[customer.id] = customer;
      state.customers.push(customer.id);
    },
  },
});

export const {
  getCustomersSuccess,
  setCurrentCustomerId,
  addCustomer,
  customerStart,
  customerFailure,
} = customersSlice.actions;

export const createCustomer = createAsyncThunk(
  'customers/create',
  async (data: Customer, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
      dispatch(customerStart());
      const customer = await postCustomer(data);
      dispatch(addCustomer(customer));
      return customer;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(customerFailure(error.response.data.message));
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchCustomers =
  (user: User): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(customerStart());
      let customers = await getCustomers();
      const defaultCustomer = {
        userId: user?.attributes.sub,
        email: user?.attributes.email,
        firstName: '',
        lastName: '',
        hasLicenses: true,
        hasPlanTypes: false,
        hasInvoices: true,
        hasForms: true,
        firmType: 'legal',
      };

      if (customers.length === 0) customers = [await postCustomer(defaultCustomer)];
      dispatch(getCustomersSuccess(customers));
    } catch (err) {
      const error = err as ErrorType;
      dispatch(customerFailure(processErrorMessage(error)));
    }
  };

export const selectCustomers = (state: RootState): CustomersState => state.customers;

export default customersSlice.reducer;
