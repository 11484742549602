/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useEffect, useState, useMemo, SyntheticEvent } from 'react';
import { ChatFeed, Message } from 'react-chat-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import compact from 'lodash/compact';
import { v4 as uuidv4 } from 'uuid';
import { Form, TextArea } from 'formik-semantic-ui-react';
import { Grid } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { ErrorLabel, S3 } from 'readywhen-ui-components';
import { Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik';
import { RootState } from '../../../app/rootReducer';
import { createMessage, fetchMessages, messagesSelectors } from './messagesSlice';
import { selectCustomers } from '../../customers/customersSlice';
import config from '../../../configs/config';
import { FileInfo, uploadOneClientFile } from '../clientsSlice';
import { fetchForms } from '../../intakeForms/forms/formsSlice';
import { AppDispatch } from '../../../app/store';
import { Form as FormInterface } from '../../../api/interfaces';
import { IntakeMessagesProps } from '../interfaces';

const { downloadFile } = S3;

function formSuccessMessage(payload: string) {
  toast({
    type: 'success',
    icon: 'check circle',
    title: 'Success',
    description: payload,
    animation: 'drop',
    time: 10000,
  });
}

const formErrorMessage = (message: string) => {
  toast({
    type: 'error',
    icon: 'exclamation circle',
    title: 'Upload Failed',
    description: message,
    animation: 'drop',
    time: 10000,
  });
};

const IntakeMessages = ({
  uploadId,
  userId,
  clientId,
  threadId,
  displayedEntry,
  client,
}: IntakeMessagesProps): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const messages = useSelector(messagesSelectors.selectAll);
  const { currentThreadId } = useSelector((state: RootState) => state.messages);
  const { currentCustomerId } = useSelector(selectCustomers);
  const { entities } = useSelector((state: RootState) => state.forms);
  const [docList, setDocList] = useState<FileInfo[]>([]);
  const { errors } = useFormikContext<Record<string, unknown>>() ?? {};
  const [height, setHeight] = useState<number>();
  const messageFileList: FileInfo[] = [];
  const formikRef = useRef<FormikProps<Record<string, unknown | { message: string }>>>(null);
  const validationSchema = Yup.object().shape({
    message: Yup.string().min(2, 'Too Short!').required('Required'),
  });

  useEffect(() => {
    if (currentCustomerId !== null) {
      dispatch(fetchForms(currentCustomerId, clientId));
    }
  }, [dispatch, currentCustomerId, clientId]);

  const formId = useMemo(
    () =>
      displayedEntry && displayedEntry.forms?.length
        ? (displayedEntry.forms[0]?.id as string)
        : { id: '' },
    [displayedEntry]
  );

  const form: FormInterface = useMemo(() => entities[formId as string], [entities, formId]);

  useEffect(() => {
    if (form && currentCustomerId) {
      dispatch(fetchMessages(currentCustomerId, clientId, threadId));
    }
  }, [clientId, currentCustomerId, currentThreadId, dispatch, form, threadId]);

  const processFile = async (file: File) => {
    const { name, type } = file;
    const fileData = {
      customerId: currentCustomerId as string,
      tempDir: uuidv4(),
      name,
      uploadId,
      userId,
      type,
      files: file,
      section: 'message',
    };

    const resultAction = await dispatch(uploadOneClientFile(fileData));
    if (uploadOneClientFile.fulfilled.match(resultAction)) {
      const {
        id = '',
        fileName = '',
        contentType = '',
        s3Key = '',
      } = resultAction.payload as {
        id: string;
        fileName: string;
        contentType: string;
        s3Key: string;
      };

      messageFileList.push({ id, fileName, contentType, key: s3Key });
      setDocList(messageFileList);
      formSuccessMessage('File Successfully Added');
    }
    if (uploadOneClientFile.rejected.match(resultAction)) {
      formErrorMessage(resultAction.payload as string);
    }
  };

  const handleFiles = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.accept = 'application/pdf, image/*';

    input.onchange = async (e: Event) => {
      const { files } = e.target as HTMLInputElement;
      const fileArray: File[] = files ? Array.from(files) : [];

      if (fileArray.length) {
        fileArray.map(async (file) => {
          await processFile(file);
        });
      }
    };
    input.click();
  };

  const buildMsgs = () => {
    const msgs: Message[] = [];
    if (messages.length) {
      messages.forEach((m) => {
        const { message, senderId, files, createdAt } = m;

        const messageFiles =
          files && files.length
            ? compact(
                files.map((file: any) => {
                  if (file.fileName) {
                    return {
                      fileName: file.fileName,
                      contentType: file.contentType,
                      key: file.s3Key,
                      section: file.section,
                    };
                  }
                  return null;
                })
              )
            : [];

        msgs.push(
          new Message({
            id: senderId === currentCustomerId ? 0 : 1,
            message,
            createdAt,
            ...(senderId === currentCustomerId && {
              senderName: client?.metadata?.professionalName,
            }),

            ...(files &&
              files.length && {
                files: messageFiles,
              }),
          })
        );
      });
    }

    return msgs;
  };

  const handleMessageSubmit = () => {
    if (formikRef && formikRef.current && formikRef.current.dirty) {
      formikRef.current.submitForm();
    }
  };

  const handleSubmit = async (
    model: Record<string, unknown | { message: string }>,
    formikHelpers: FormikHelpers<Record<string, unknown | { message: string }>>
  ) => {
    if (currentCustomerId && threadId) {
      const { message } = model;
      const resultAction = await dispatch(
        createMessage({
          customerId: currentCustomerId as string,
          clientId,
          threadId,
          senderId: currentCustomerId as string,
          recipientId: clientId,
          userId,
          message: message as string,
          files: compact(docList.map((file) => file.id)),
          formId: form.id as string,
        })
      );
      if (createMessage.fulfilled.match(resultAction)) {
        dispatch(fetchMessages(currentCustomerId, clientId, threadId));
      }
      formikHelpers.resetForm();
      setDocList([]);
    }
  };

  const downloadFileCallback = (file: FileInfo) => {
    downloadFile('', config.s3.BUCKET, file?.key as string, '', file?.fileName);
  };

  const handleBlur = (e: SyntheticEvent<HTMLElement, Event>) => {
    const target = e.target as HTMLTextAreaElement;
    setHeight(target.scrollHeight);
  };

  return (
    <>
      {messages.length ? (
        <ChatFeed
          messages={buildMsgs()}
          isTyping={false}
          hasInputField={false}
          showSenderName
          bubblesCentered={false}
          maxHeight="calc(100vh - 270px)"
          bubbleStyles={{
            text: {
              fontSize: 16,
            },
            chatBubble: {
              color: '#62636f',
              listStyle: 'none',
              borderRadius: 4,
              padding: 20,
              backgroundColor: '#eaeaed',
              border: 'none',
            },
            userBubble: {
              color: '#62636f',
              listStyle: 'none',
              borderRadius: 4,
              backgroundColor: '#fff',
              border: '1px solid rgb(151, 151, 151, 0.6)',
            },
          }}
          callback={downloadFileCallback}
        />
      ) : (
        <span className="Messages_empty">
          <FontAwesomeIcon icon={['far', 'envelope']} size="lg" />
          <p>No Messages</p>
        </span>
      )}

      <Formik<Record<string, unknown | { message: string }>>
        innerRef={formikRef}
        enableReinitialize
        initialValues={{ message: '' }}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ dirty, isValid }) => {
          return (
            <Form size="large" noValidate>
              <section
                className="Card"
                style={{
                  padding: '10px 20px',
                  position: 'absolute',
                  width: '100%',
                  bottom: '10px',
                }}
              >
                <Grid>
                  <Grid.Column width={1}>
                    <FontAwesomeIcon
                      icon={['far', 'paperclip']}
                      size="lg"
                      className="Message_attachBtn"
                      onKeyDown={handleFiles}
                      onClick={handleFiles}
                      role="button"
                      tabIndex={0}
                    />
                  </Grid.Column>
                  <Grid.Column width={14}>
                    <TextArea
                      style={height && { height }}
                      onBlur={handleBlur}
                      onKeyDown={handleBlur}
                      id="message"
                      name="message"
                      type="text"
                      placeholder={
                        `Message to ${client?.firstName} ${client?.lastName}` || 'Client'
                      }
                      errorPrompt
                    />
                    <ErrorLabel name="message" errors={errors} />
                    {docList.length ? (
                      <ul className="Message_fileList">
                        {docList.map((doc) => {
                          return (
                            <li key={doc.id}>
                              <a
                                href="#"
                                onClick={() => {
                                  // eslint-disable-next-line react-hooks/rules-of-hooks
                                  downloadFileCallback(doc);
                                }}
                              >
                                <FontAwesomeIcon icon={['far', 'file']} size="sm" /> {doc.fileName}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                  </Grid.Column>
                  <Grid.Column width={1}>
                    <span
                      className={
                        dirty && isValid
                          ? 'fa-layers fa-fw Message_submitBtn'
                          : 'fa-layers fa-fw Message_submitBtn___disabled'
                      }
                      onKeyDown={handleMessageSubmit}
                      onClick={handleMessageSubmit}
                      role="button"
                      tabIndex={0}
                    >
                      <FontAwesomeIcon icon={faCircle} className="Icon_color___teal" size="3x" />
                      <FontAwesomeIcon
                        icon={['far', 'paper-plane']}
                        inverse
                        transform="shrink-9 left-1"
                        size="3x"
                      />
                    </span>
                  </Grid.Column>
                </Grid>
              </section>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
export default IntakeMessages;
