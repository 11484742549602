import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { includes } from 'lodash';
import styles from './IntakeFormsPage.module.less';

interface IntakeFormColumnsProps {
  metadata: Record<string, string>;
  onDelete: (rowData: { id: string; type: string; status: string }) => void;
  onEdit: (id: string, type: string) => void;
}

const getIntakeFormColumns = ({ onDelete, onEdit, metadata }: IntakeFormColumnsProps) => [
  {
    field: 'id',
    headerName: 'File Number',
    type: 'form',
    sortBy: 'file_number',
    sorted: false,
  },
  {
    field: 'type',
    headerName: 'Type',
    type: 'type',
    sortBy: 'type',
    sorted: false,
  },
  {
    field: 'client',
    headerName: 'Client',
    type: 'clientInfo',
    sorted: false,
  },
  {
    field: 'createdAt',
    headerName: 'Date Added',
    type: 'date',
    sortBy: 'created_at',
    sorted: metadata?.sort_by === 'created_at' ? metadata?.sort_order : 'ascending',
  },
  {
    field: 'actions',
    headerName: '',
    type: 'actions',
    children: (rowData: { id: string; type: string; status: string; clientId: string }) => {
      const { id, status, type, clientId } = rowData;

      return (
        <Fragment key={`actions-${id}`}>
          {status && includes(['draft', 'in progress'], status) && (
            <>
              <FontAwesomeIcon
                data-id={id}
                className="Table_actionButtons"
                icon={['far', 'edit']}
                title="Re-send Invite"
                onClick={() => onEdit(id, type)}
              />
              {!clientId && (
                <FontAwesomeIcon
                  data-id={id}
                  className={`${styles.deleteIcon} Table_actionButtons`}
                  icon={['far', 'trash']}
                  title="Delete"
                  onClick={() => onDelete(rowData)}
                />
              )}
            </>
          )}
        </Fragment>
      );
    },
  },
];

export default getIntakeFormColumns;
