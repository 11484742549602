import { Button } from 'semantic-ui-react';

interface NewIntakeFormButtonProps {
  onClick: () => void;
}

const NewIntakeFormButton = ({ onClick }: NewIntakeFormButtonProps) => {
  return (
    <Button primary className="Button__Add___right" onClick={onClick}>
      New Intake Form
    </Button>
  );
};

export default NewIntakeFormButton;
