/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import { fetchUploads, fetchPropertyUploads } from '../../clients/clientsSlice';
import { fetchForms } from './formsSlice';
import { AppDispatch } from '../../../app/store';

interface FormDataProps {
  currentCustomerId: string | null;
  dispatch: AppDispatch;
  clientId: string | undefined;
  intakeFormId: string | undefined;
  formId: string;
  id: string | undefined;
}

export const useFormDataFetch = ({
  currentCustomerId,
  dispatch,
  clientId,
  intakeFormId,
  formId,
  id,
}: FormDataProps) => {
  useEffect(() => {
    if (currentCustomerId !== null && clientId) {
      dispatch(fetchForms(currentCustomerId, clientId));
      dispatch(fetchUploads({ customerId: currentCustomerId, clientId }));
    }
  }, [dispatch, currentCustomerId, clientId, id]);

  useEffect(() => {
    if (currentCustomerId && intakeFormId) {
      dispatch(fetchForms(currentCustomerId, '', intakeFormId));
    }
  }, [dispatch, currentCustomerId, intakeFormId]);

  useEffect(() => {
    if (currentCustomerId !== null && !formId && clientId && id) {
      dispatch(fetchPropertyUploads({ customerId: currentCustomerId, clientId, uploadId: id }));
    }
  }, [dispatch, currentCustomerId, clientId, id, formId]);
};
