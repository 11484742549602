import { Header, Icon, Modal, Popup } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { IntakeFormTypeDropdown } from 'readywhen-ui-components';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { createIntakeForm } from './intakeFormsSlice';
import { AppDispatch } from '../../app/store';
import { formFileTypes } from '../../constants/forms';
import { formSuccessMessage } from './formMessage';

interface NewIntakeFormModalProps {
  onCloseModal: () => void;
  isOpen: boolean;
  currentCustomerId: string | null;
  loading: boolean;
}

const initialValues = {
  fileType: '',
  fileNumber: '',
};

const validationSchema = Yup.object({
  fileType: Yup.string().required('Required'),
  fileNumber: Yup.string().required('Required'),
});

const CreateIntakeFormModal = ({
  onCloseModal,
  isOpen,
  currentCustomerId,
  loading,
}: NewIntakeFormModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleSubmit = async (
    values: { fileType: string; fileNumber: string },
    formikHelpers: FormikHelpers<{ fileType: string; fileNumber: string }>
  ) => {
    const { fileNumber, fileType } = values;
    const { setFieldError } = formikHelpers;

    if (currentCustomerId) {
      const response = await dispatch(
        createIntakeForm({ fileNumber, formType: fileType, customerId: currentCustomerId })
      );

      if (createIntakeForm.rejected.match(response)) {
        const payload = (response.payload as string) || 'Error has occurred';
        setFieldError('fileNumber', payload);
        return;
      }

      const { id, type } = response?.payload ?? {};

      if (id) {
        formSuccessMessage(`The ${formFileTypes[fileType]} Form was created`);
        navigate(`/forms/${id}/${type}-form`);
      }
    }
  };

  return (
    <Modal
      closeIcon
      onClose={onCloseModal}
      open={isOpen}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      className="Modal___centered"
    >
      <Header>New Intake Form</Header>
      <Modal.Content>
        <Modal.Description>
          <div id="Form_container">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form size="large" noValidate>
                <IntakeFormTypeDropdown label="File Type *" name="fileType" />
                <label htmlFor="fileNumber">
                  File Number *{' '}
                  <Popup
                    trigger={<Icon name="question" color="teal" size="small" circular />}
                    content={
                      <p>
                        Please ensure that file number matches the number you already created for
                        the intake form.
                      </p>
                    }
                  />
                </label>
                <Input name="fileNumber" errorPrompt />
                <SubmitButton
                  className="Form_button"
                  style={{ marginTop: '30px' }}
                  primary
                  loading={loading}
                >
                  Create Intake Form
                </SubmitButton>
              </Form>
            </Formik>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default CreateIntakeFormModal;
