/* eslint-disable import/no-cycle */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AppThunk } from '../../../app/store';
import { RootState } from '../../../app/rootReducer';
import { getMessages, processErrorMessage, postMessage } from '../../../api/ReadyWhenAdminAPI';
import { Message } from '../../../api/interfaces';
import { ErrorType } from '../../../app/interfaces';

const messagesAdapter = createEntityAdapter<Message>({
  sortComparer: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
});

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: messagesAdapter.getInitialState({
    loading: false,
    error: false,
    currentThreadId: '',
  }),
  reducers: {
    addOneMessage(state, { payload }) {
      messagesAdapter.addOne(state, payload);
    },
    updateOneMessage(state, { payload }) {
      messagesAdapter.upsertOne(state, payload);
    },
    getMessagesStart(state) {
      state.loading = true;
    },
    getMessagesSuccess(state, { payload }) {
      messagesAdapter.setAll(state, payload);
      state.loading = false;
    },
    getMessagesFailure(state, { payload }) {
      state.loading = false;
      state.error = payload;
    },
    setCurrentThreadId(state, { payload }: PayloadAction<string>) {
      const id = payload;
      state.loading = false;
      state.error = false;
      state.currentThreadId = id;
    },
  },
});

export const {
  addOneMessage,
  updateOneMessage,
  getMessagesStart,
  getMessagesSuccess,
  getMessagesFailure,
  setCurrentThreadId,
} = messagesSlice.actions;

export const fetchMessages =
  (customerId: string, clientId: string, threadId: string): AppThunk =>
  async (dispatch) => {
    dispatch(getMessagesStart());
    try {
      const messages = await getMessages(customerId, clientId, threadId);

      dispatch(getMessagesSuccess(messages));
    } catch (err) {
      const error = err as ErrorType;
      dispatch(getMessagesFailure(processErrorMessage(error)));
    }
  };

export const createMessage = createAsyncThunk(
  'messages/create',
  async (messageData: Message, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
      dispatch(getMessagesStart());
      const message = await postMessage(messageData);
      dispatch(addOneMessage(message));
      return message;
    } catch (err) {
      const error = err as ErrorType;
      dispatch(getMessagesFailure(error.response.data.message));
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const messagesSelectors = messagesAdapter.getSelectors((state: RootState) => state.messages);

export default messagesSlice.reducer;
