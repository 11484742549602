import { Dimmer, Loader, Table } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ReadyAdminEmptyPageProps {
  dataFetched?: boolean;
  button?: JSX.Element;
  content: string;
  icon?: IconProp;
}

export default function ReadyAdminEmptyPage({
  dataFetched,
  button,
  content,
  icon,
}: ReadyAdminEmptyPageProps): JSX.Element {
  return (
    <>
      {dataFetched ? (
        <Table basic="very" className="empty-entry" style={{ marginTop: '20px' }}>
          <Table.Body>
            <Table.Row>
              <Table.Cell textAlign="center">
                <FontAwesomeIcon icon={icon as IconProp} className="Icon_emptyContent" />
                <p className="Paragraph__EmptyPage">{content}</p>
                {button}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      ) : (
        <Dimmer
          active
          inverted
          style={{
            marginTop: '14px',
          }}
        >
          <Loader inverted>Loading</Loader>
        </Dimmer>
      )}
    </>
  );
}

ReadyAdminEmptyPage.defaultProps = {
  button: null,
  dataFetched: null,
  icon: null,
};
