const local = {
  STRIPE_KEY:
    'pk_test_51HTubzBshN3aFAPwmtHzE4qIeQ8lwFQry1d568W8eKvlKbOG3Oj1CS6qMqEYc1KhuIdSlui951utsDU4btCSgqA8002UROLOLm',
  AWS_REGION: 'ca-central-1',
  s3: {
    BUCKET: 'readywhen-tech-dev-admin-app-uploads',
  },
  cognito: {
    USER_POOL_ID: 'ca-central-1_46gfbmI1S',
    APP_CLIENT_ID: '28c3j2dtj0ejck85dqii6lo0r1',
    IDENTITY_POOL_ID: 'ca-central-1:744531b5-a7c4-4a20-96f1-52d9e6ecae92',
  },
  plans: {
    monthly: 'price_1Hq4wtBshN3aFAPwJfDUpDrW',
    yearly: 'price_1Hq4wuBshN3aFAPwSMTEwRn8',
    lifetime: 'price_1HthIlBshN3aFAPwz0P3be5k',
  },
  API_DOMAIN: 'http://localhost:4001',
  TAX_RATE: 'txr_1HtgNiBshN3aFAPwN2bpXz06',
};

const dev = {
  STRIPE_KEY:
    'pk_test_51HTubzBshN3aFAPwmtHzE4qIeQ8lwFQry1d568W8eKvlKbOG3Oj1CS6qMqEYc1KhuIdSlui951utsDU4btCSgqA8002UROLOLm',
  AWS_REGION: 'ca-central-1',
  s3: {
    BUCKET: 'readywhen-tech-dev-admin-app-uploads',
  },
  cognito: {
    USER_POOL_ID: 'ca-central-1_46gfbmI1S',
    APP_CLIENT_ID: '28c3j2dtj0ejck85dqii6lo0r1',
    IDENTITY_POOL_ID: 'ca-central-1:744531b5-a7c4-4a20-96f1-52d9e6ecae92',
  },
  plans: {
    monthly: 'price_1Hq4wtBshN3aFAPwJfDUpDrW',
    yearly: 'price_1Hq4wuBshN3aFAPwSMTEwRn8',
    lifetime: 'price_1HthIlBshN3aFAPwz0P3be5k',
  },
  API_DOMAIN: 'https://api.dev.readywhen.ca/admin',
  TAX_RATE: 'txr_1HtgNiBshN3aFAPwN2bpXz06',
};

const prod = {
  STRIPE_KEY:
    'pk_live_51HTubzBshN3aFAPw7DhgSlOnQbyYjsOcaYnh1AMs1g0rDaRx7xAoeZ3ObwhN5iPLAa1QW13qoF1I787jWExrcF5S00J2ew8Yr4',
  AWS_REGION: 'ca-central-1',
  s3: {
    BUCKET: 'readywhen-tech-prod-admin-app-uploads',
  },
  cognito: {
    USER_POOL_ID: 'ca-central-1_PmFBKAWX4',
    APP_CLIENT_ID: '2ebks34ae60hcoujl1s9d4null',
    IDENTITY_POOL_ID: 'ca-central-1:e043a579-c08a-4c67-b45e-4efe15bf93a8',
  },
  plans: {
    monthly: 'price_1HuXa3BshN3aFAPwKf9O7Jak',
    yearly: 'price_1HuXa3BshN3aFAPwxOd2yTMe',
    lifetime: 'price_1HuXa3BshN3aFAPwoZOa90Li',
  },
  API_DOMAIN: 'https://api.app.readywhen.ca/admin',
  TAX_RATE: 'txr_1HuXj8BshN3aFAPwdstOgUx3',
};

// Default to dev if not set
let config;

switch (process.env.REACT_APP_ENV) {
  case 'local':
    config = local;
    break;
  case 'production':
    config = prod;
    break;
  default:
    config = dev;
}

export default {
  ...config,
};
