/* eslint-disable import/prefer-default-export */
import { regionOptions } from 'readywhen-ui-components';
import * as Yup from 'yup';
import { toast } from 'react-semantic-toasts';
import { SignUpInitialState } from './interfaces';

export const buildSchema = () => {
  return Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    phone: Yup.string()
      .matches(/^[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/i, 'Invalid phone number')
      .min(8, 'Must be at least 8 digits')
      .max(15, 'Must be 15 digits or less')
      .required('Required'),
    region: Yup.string()
      .oneOf(regionOptions.map((c: { value: string }) => c.value))
      .required('Required'),
    assignEmployee: Yup.number().oneOf([1, 0]),
    uploadDocuments: Yup.number().oneOf([1, 0]),
    employeeRole: Yup.string(),
    paymentMethod: Yup.string().oneOf(['invoice', 'license']).required('Required'),
    planType: Yup.string().oneOf(['year', 'lifetime']).required('Required'),
  });
};

export function signUpReducer(
  state: SignUpInitialState,
  action: {
    type: string;
    value?: any;
  }
) {
  switch (action.type) {
    case 'SET_FORM_DISABLED':
      return { ...state, formDisabled: action.value };

    case 'SET_MODAL_OPEN':
      return { ...state, modalOpen: action.value };

    case 'SET_FORM_DATA':
      return { ...state, formData: action.value };

    case 'SET_EMPLOYEE':
      return { ...state, employee: action.value };

    case 'SET_IS_EMPLOYEE_ASSIGNED':
      return { ...state, isEmployeeAssigned: action.value };

    case 'SET_ARE_DOCUMENTS_UPLOADED':
      return { ...state, areDocumentsUploaded: action.value };

    default:
      return state;
  }
}

export function formErrorMessage(payload: string) {
  toast({
    type: 'error',
    icon: 'exclamation circle',
    title: 'Sign Up Failed',
    description: payload,
    animation: 'drop',
    time: 10000,
  });
}
