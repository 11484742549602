import includes from 'lodash/includes';

export const isNotification = (arr: string[], obj: Record<string, any>): boolean => {
  const res: string[] = [];

  Object.keys(obj).forEach((key) => {
    if (!includes(arr, key)) {
      res.push(key);
    }
  });
  return res.length > 0;
};

export const getDiff = (
  obj1: Record<string, any>,
  obj2: Record<string, any>
): Record<string, any> => {
  const diff = {};
  Object.keys(obj1).forEach((key) => {
    if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
      (diff as any)[key] = getDiff(obj1[key], obj2[key]);
    } else if (obj1[key] !== obj2[key]) {
      (diff as any)[key] = obj1[key];
    }
  });
  return diff;
};

export const removeEmptyAndIgnorePhone = (obj: Record<string, string>): Record<string, string> => {
  const finalObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      const nestedObj = removeEmptyAndIgnorePhone(obj[key] as unknown as Record<string, string>);

      if (Object.keys(nestedObj).length) {
        (finalObj as any)[key] = nestedObj;
        Object.keys(nestedObj).forEach((key1) => {
          if (key1.includes(`_ignore_`)) {
            delete nestedObj[key1];
          }
          if (
            key1 === 'phone' &&
            (nestedObj[key1] as any).country_code &&
            ((nestedObj[key1] as any).value === undefined || (nestedObj[key1] as any).value === '')
          ) {
            delete nestedObj[key1];
          }
        });
      }
    } else if (key.includes(`_ignore_`)) {
      delete obj[key];
    } else if (obj[key] !== '' && obj[key] !== undefined && obj[key] !== null) {
      (finalObj as any)[key] = obj[key];
    }
  });
  return finalObj;
};

export const makeNamesArr = (obj: Record<string, any>): string[] => {
  const arr: string[] = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      const childKeys = Object.keys(obj[key]);
      if (childKeys.includes('value')) {
        arr.push(key);
      } else {
        childKeys.forEach((childKey) => {
          Object.keys(obj[key][childKey]).forEach((key1) => {
            arr.push(`${key}[${childKey}].${key1}`);
          });
        });
      }
    } else {
      arr.push(key);
    }
  });
  return arr;
};
