import config from './config';

const awsAmplifyConfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: config.AWS_REGION,

    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: 'CustomersAPI',
        endpoint: `${config.API_DOMAIN}/customers`,
        region: config.AWS_REGION,
      },
    ],
  },
};
export default awsAmplifyConfig;
