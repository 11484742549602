import { useFormikContext } from 'formik';
import { Input, TextArea } from 'formik-semantic-ui-react';
import includes from 'lodash/includes';
import { useDispatch, useSelector } from 'react-redux';
import { ClientFilePayload, FileInfo, FileUploadField, S3 } from 'readywhen-ui-components';
import config from '../../../configs/config';
import { selectCustomers } from '../../customers/customersSlice';
import { replaceClientFiles, selectClients } from '../clientsSlice';
import { WillFieldProps } from '../interfaces';

const { downloadFile } = S3;

export default function ClientWillFields({
  data,
  formDisabled,
  isEditModalOpen,
  formType,
}: WillFieldProps): JSX.Element {
  const { values } = useFormikContext<Record<string, unknown>>() ?? {};
  const { files } = useSelector(selectClients);
  const { currentCustomerId } = useSelector(selectCustomers);
  const dispatch = useDispatch();

  return (
    <>
      {/* {!isEditModalOpen && <IntakeFormFields formDisabled={formDisabled} formType={formType} />} */}

      {includes([0, undefined], values.isIntakeFormSent) && (
        <FileUploadField
          name="willFile"
          label={<strong>Client&apos;s Will</strong>}
          required
          type="file"
          accept="application/pdf, image/*"
          uploadId={data?.id}
          userId={data?.userId}
          files={files}
          currentCustomerId={currentCustomerId as string}
          replaceClientFiles={(payload: ClientFilePayload) => {
            dispatch(replaceClientFiles(payload));
          }}
          downloadFile={(file: FileInfo) => {
            downloadFile('', config.s3.BUCKET, file?.key as string, '', file?.fileName);
          }}
        />
      )}

      {includes([0, undefined], values.isIntakeFormSent) && (
        <>
          <TextArea
            name="willPhysicalLocation"
            label="Physical location of Will*"
            placeholder="You can find this document in the top drawer of the filing cabinet."
            disabled={formDisabled}
            errorPrompt
          />
          <Input
            name="willRegistration"
            label="Will Registration Number"
            disabled={formDisabled}
            errorPrompt
          />
        </>
      )}
    </>
  );
}

ClientWillFields.defaultProps = {
  isEditModalOpen: null,
  formType: null,
};
