/* eslint-disable import/no-cycle */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/rootReducer';

import { getLicenses } from '../../api/ReadyWhenAdminAPI';
import { License } from '../../api/interfaces';

const licensesAdapter = createEntityAdapter<License>();

export const fetchLicenses = createAsyncThunk(
  'licenses/fetchLicenses',
  async (customerId: string) => {
    const response = await getLicenses(customerId);
    return response;
  }
);

const licensesSlice = createSlice({
  name: 'licenses',
  initialState: licensesAdapter.getInitialState({ loading: false, error: false }),
  reducers: {
    licensesStarted(state) {
      state.loading = true;
    },
    licensesFailure(state) {
      state.error = true;
      state.loading = false;
    },
    licenseAdded: licensesAdapter.addOne,
    licensesSuccess(state, action) {
      licensesAdapter.setAll(state, action.payload);
      state.loading = false;
    },
  },
  extraReducers: {
    [fetchLicenses.pending as unknown as string]: (state) => {
      licensesSlice.caseReducers.licensesStarted(state);
    },
    [fetchLicenses.fulfilled as unknown as string]: (state, action) => {
      licensesSlice.caseReducers.licensesSuccess(state, action);
    },
    [fetchLicenses.rejected as unknown as string]: (state) => {
      licensesSlice.caseReducers.licensesFailure(state);
    },
  },
});

export const { licenseAdded, licensesStarted, licensesSuccess, licensesFailure } =
  licensesSlice.actions;

export const licensesSelectors = licensesAdapter.getSelectors<RootState>((state) => state.licenses);

export default licensesSlice.reducer;
