import { CheckboxProps, Divider } from 'semantic-ui-react';
// eslint-disable-next-line import/no-cycle
import {
  CheckboxInput,
  MoneyInput,
  RadioGroupInput,
  TextAreaInput,
  TextInput,
} from 'readywhen-ui-components';

interface InternalNotesProps {
  formDisabled: boolean;
  formData: Record<string, unknown>;
  handleRadioCheckboxChange: (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => void;
}

export const InternalNotesRefinance = ({
  formDisabled,
  formData,
  handleRadioCheckboxChange,
}: InternalNotesProps): JSX.Element => {
  return (
    <div className="Form_internalNotes">
      <h3>Internal Notes</h3>
      <h4>Checklist</h4>
      <Divider />
      <CheckboxInput
        name="titleSearch"
        label="Title Search"
        checked={formData.titleSearch}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="taxSearch"
        label="Tax Search"
        checked={formData.taxSearch}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="payoutOne"
        label="Payout #1"
        checked={formData.payoutOne}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="payoutTwo"
        label="Payout #2"
        checked={formData.payoutTwo}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="fireInsurance"
        label="Fire Insurance"
        checked={formData.fireInsurance}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="strataForms"
        label="Strata Forms F/B"
        checked={formData.strataForms}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="titleInsurance"
        label="Title Insurance"
        checked={formData.titleInsurance}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="requestForFunds"
        label="Request of Funds"
        checked={formData.requestForFunds}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="finalReport"
        label="Final Report"
        checked={formData.finalReport}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="releaseParticulars"
        label="Release Particulars"
        checked={formData.releaseParticulars}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="stc"
        label="STC"
        checked={formData.stc}
        onChange={handleRadioCheckboxChange}
      />
      <Divider />
      <CheckboxInput
        name="other"
        label="Other"
        checked={formData.other}
        onChange={handleRadioCheckboxChange}
      />
      {formData.other && <TextInput name="otherText" label="" disabled={formDisabled} />}
      <Divider />
      <MoneyInput
        name="strataMaintenanceFee"
        label="Strata Monthly Maintenance Fee"
        disabled={formDisabled}
      />
      <MoneyInput name="outstandingFees" label="Outstanding Fees" disabled={formDisabled} />
      <MoneyInput name="moveInOutFees" label="Move-In/Out Fees" disabled={formDisabled} />
      <RadioGroupInput
        name="transactionUndertakings"
        label="Undertakings for Transaction"
        value={formData.transactionUndertakings}
        onChange={handleRadioCheckboxChange}
      />
      {formData.transactionUndertakings === '1' && (
        <TextAreaInput
          rows={2}
          name="transactionUndertakingsDetails"
          label=""
          disabled={formDisabled}
        />
      )}
      <RadioGroupInput
        name="holdbacks"
        label="Any Holdbacks?"
        value={formData.holdbacks}
        onChange={handleRadioCheckboxChange}
      />
      {formData.holdbacks === '1' && (
        <TextAreaInput rows={2} name="holdbacksDetails" label="" disabled={formDisabled} />
      )}
      <TextAreaInput
        rows={5}
        name="internalNotes"
        label="Additional Notes"
        disabled={formDisabled}
      />
    </div>
  );
};

export default InternalNotesRefinance;
