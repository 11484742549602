/* eslint-disable import/prefer-default-export */
// import dompurify from 'dompurify';

import camelCase from 'lodash/camelCase';
// export function sanitizer(rawTexts: string): string {
//   return dompurify.sanitize(rawTexts);
// }

/**
 * When a Document requires multiple forms, type is appended with a dash and number
 * Example: AAA-123 -> AAA
 * @param type string that needs to be formatted
 */
export function normalizeStr(type: string): string {
  return type.replace(/-\d*$/g, '');
}

/**
 * Format phone number for AWS cognito
 * Remove (), space, and -
 * @param phoneNumber
 * @param plusRequired (optional)
 */
export function formatPhoneNumber(phoneNumber: string, plusRequired?: boolean): string {
  let formatted = phoneNumber;
  if (plusRequired && phoneNumber && phoneNumber[0] !== '+') formatted = `+${phoneNumber}`;
  return formatted.replace(/[(]|[)]|-|\s/gm, '');
}

/**
 * Format string for analytics
 * Remove (), space, and -
 * Capitalize first letter in each word
 * @param str string that needs to be formatted
 */
export function sanitizeString(str: string): string {
  const text = normalizeStr(str).replace(/-/g, ' ');
  return text.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());
}

export function normalizeLocalUploadTypes(str: string): string {
  if (str === 'insurance' || str === 'life-insurance') {
    return 'lifeInsurance';
  }

  return str;
}

export function normalizeUploadTypes(str: string): string {
  if (str === 'poa') {
    return 'power-of-attorney';
  }
  if (str === 'spouse-poa') {
    return 'spouse-power-of-attorney';
  }
  if (str === 'health') {
    return 'health-directive';
  }
  if (str === 'spouse-health') {
    return 'spouse-health-directive';
  }
  return str;
}

export function normalizeFieldName(str: string): string {
  if (str === 'insuranceTitle') {
    return 'title';
  }
  if (str === 'insuranceName' || str === 'propertyTitle' || str === 'otherAssetsTitle') {
    return 'name';
  }
  if (str === 'insurancePolicy') {
    return 'policy';
  }
  if (
    str === 'lifeInsurancePhysicalLocation' ||
    str === 'willPhysicalLocation' ||
    str === 'spouseWillPhysicalLocation' ||
    str === 'poaPhysicalLocation' ||
    str === 'spousePoaPhysicalLocation' ||
    str === 'healthPhysicalLocation' ||
    str === 'spouseHealthPhysicalLocation' ||
    str === 'otherAssetsPhysicalLocation'
  ) {
    return 'physicalLocation';
  }
  if (str === 'propertyCountry') {
    return 'country';
  }
  if (str === 'propertyRegion') {
    return 'region';
  }
  if (str === 'spouseWillRegistration') {
    return 'willRegistration';
  }

  return str;
}

export function clientFilesByType(type: string, index = 0): string {
  if (type.includes('will') || type.includes('poa') || type.includes('health')) {
    return `${camelCase(type)}File`;
  }

  if (type === 'insurance') {
    return `lifeInsuranceFiles-${index}`;
  }

  if (type === 'financial') {
    return `financialAccountFiles-${index}`;
  }

  return `${camelCase(type)}Files-${index}`;
}

export function sectionByType(type: string): string {
  if (type.includes('will') || type.includes('poa') || type.includes('health')) {
    return 'legal';
  }

  if (type === 'health-insurance') {
    return 'health';
  }

  return 'assets';
}
