import { toast } from 'react-semantic-toasts';

export function formSuccessMessage(payload: string) {
  toast({
    type: 'success',
    icon: 'check circle',
    title: 'Success',
    description: payload,
    animation: 'drop',
    time: 10000,
  });
}

export function formErrorMessage(payload: string) {
  toast({
    type: 'error',
    icon: 'exclamation circle',
    title: 'Error',
    description: payload,
    animation: 'drop',
    time: 10000,
  });
}
